import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import mutations from './mutations'
import getters from './getters'
import fleetData from './module/fleet'
import vehicleData from './module/vehicle'
import driverData from './module/driver'
import deviceData from './module/device'
import websocket from "./module/websocket";
import actions from "./actions";
import gps from "./module/gps";
import screen from "./module/screen";
import timezone from "./module/timezone";
import i18n from "./module/i18n";
import firmware from "./module/firmware";

Vue.use(Vuex)

export default new Vuex.Store({
    state: state,
    mutations: mutations,
    getters:getters,
    actions: actions,
    modules: {
        fleetData,
        vehicleData,
        driverData,
        deviceData,
        websocket,
        gps,
        screen,
        timezone,
        i18n,
        firmware,
    }
})
