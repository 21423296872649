const state = {
    show: false,
    firmwareList: [],
    reopenWindowTimer: null,
    currentUpgradeFirmware: {
        id: '',
        mainModel: "",
        version: "",
        releaseNote: ""
    }
};

const getters = {
    showTips: (state) => {
        return state.show;
    },
    currentUpgradeFirmware: (state) => {
        return state.currentUpgradeFirmware;
    }
};

const mutations = {
    initUpgradeFirmware(state, data) {
        state.currentUpgradeFirmware = {
            id: data.firmwareList[0].Id,
            mainModel: data.firmwareList[0].LatestVersion.MainModel,
            version: data.firmwareList[0].LatestVersion.Version,
            releaseNote: data.firmwareList[0].LatestVersion.ReleaseNote
        };
        state.firmwareList = data.firmwareList.slice(1);
        state.show = true;
    },
    openUpgradeFirmwareWindow(state) {
        state.show = true;
    },
    closeUpgradeFirmwareWindow(state) {
        state.show = false;
    },
    updateFirmwareList(state, data) {
        state.firmwareList = data;
    },
    updateCurrentUpgradeFirmware(state, data) {
        state.currentUpgradeFirmware = {
            id: data.Id,
            mainModel: data.LatestVersion.MainModel,
            version: data.LatestVersion.Version,
            releaseNote: data.LatestVersion.ReleaseNote
        };
    },
    addReopenWindowTimer(state, timer) {
        if (state.reopenWindowTimer)
            clearTimeout(state.reopenWindowTimer);
        state.reopenWindowTimer = timer;
    }
};

const actions = {
    closeUpgradeFirmwareWindow({commit, state, dispatch}) {
        commit('closeUpgradeFirmwareWindow');
        if (state.firmwareList[0]) {
            commit('updateCurrentUpgradeFirmware', state.firmwareList[0]);
            commit('updateFirmwareList', state.firmwareList.slice(1));
            let timer = setTimeout(() => {
                commit('openUpgradeFirmwareWindow');
            }, 1000);
            commit('addReopenWindowTimer', timer);
        }
    }
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};