const state = {
    screenWidth: document.documentElement.clientWidth,
    screenHeight: document.documentElement.clientHeight
};

const getters = {
    screen: (state) => {
        return {
            screenWidth: state.screenWidth,
            screenHeight: state.screenHeight
        };
    },
    screenWidth: (state) => {
        return state.screenWidth;
    },
    screenHeight: (state) => {
        return state.screenHeight;
    }
};

const mutations = {
    updateScreenWidth: (state, value) => {
        state.screenWidth = value;
    },
    updateScreenHeight: (state, value) => {
        state.screenHeight = value;
    }
};

const actions = {};

export default {
    state,
    mutations,
    actions,
    getters
};