export default {
    currentUserData: '',
    touchSessionTimer: null,
    companyInfo: null,
    apiCompanyInfoStatus: false, // false表示通过api获取失败或者没有获取数据，true表示成功通过api获取到数据或者正在获取,
    axiosPendingRequests:[],
    LoadingTimeoutHandler:null,
    CurrentOnRoadTreeNodeId:null,
    menuCollapse:true,
    vehicleStatus:[],
    loading: false,
    axiosCount: 0
}
