/**
 * 修改本配置文件，请同步修改/doc/I18n_setting_map.xlsx文件
 */
const common = {
    singular: { // 单数
        hour: '@:units.hours',
        driver: '@:way2Call.driver',
        fleet: '@:way2Call.fleet',
        vehicle: '@:way2Call.vehicle',
        alert: '@:userAccounts.alert',
        collisionAlert: '@:dashboard.collisionAlert',
        driverAlert: '@:dashboard.driverAlert',
        parkingAlert: '@:dashboard.parkingAlert',
        unpermittedDriver: '@:dashboard.unpermittedDriver',
    },
    plural: { // 复数
        hours: '@:units.hours',
        drivers: '@:userAccounts.drivers',
        fleets: '@:userAccounts.fleets',
        vehicles: '@:userAccounts.vehicles',
        alerts: '@:alertList.alerts',
        collisionAlerts: '@:dashboard.collisionAlert',
        driverAlerts: '@:dashboard.driverAlert',
        parkingAlerts: '@:dashboard.parkingAlert',
        unpermittedDrivers: '@:dashboard.unpermittedDriver',
    },
    headerTitle: {
        //header左上角功能模块
        dashboard: '@:dashboard.dashboard',
        vehicleManagement: '@:fleet.vehicleManagement',
        addFleet: '@:fleet.addFleet',
        editFleet: '@:fleet.editFleet',
        addVehicle: '@:fleet.addVehicle',
        vehicle: '@:way2Call.vehicle',
        editVehicle: '@:vehicle.editVehicle',
        driverManagement: '@:driver.driverManagement',
        driver: '@:way2Call.driver',
        addDriver: '@:driver.addDriver',
        editDriver: '@:driver.editDriver',
        account: 'Account',
        role: 'Role',
        liveTracking: '@:liveTracking.liveTracking',
        tripHistory: '@:tripHistory.tripHistory',
        settings: '@:settings.settings'
    }
};
const units = {
    hrs: "小时",
    hours: "小时",
    km: "千米",
    kilometers: "千米",
    mi: "英里",
    miles: "英里",
    l: "升",
    liters: "升",
    gal: "加仑",
    gallons: "加仑",
    t: "吨",
    cID: "立方英寸",
    hrsPerTrip: "小时/行程",
    kmPerTrip: "千米/行程",
    miPerTrip: "英里/行程",
    kmPerL: "千米/升",
    mpg: "英里/加仑",
    kmPerHr: "千米/小时",
    mph: "英里/小时",
    in: "英寸",
    cm: "厘米",
    day:"天 | 天"
};
const globalText = {
    VIA_FLEET: "威盛车队云平台",
    noData: "无数据",
    unassigned: "未分配",
    unknown: "未知",
    oK: "确认",
    cancel: "取消",
    delete: "删除",
    save: "保存",
    apply: "应用",
    all: "全部",
    loading: "加载中……",
    uploading: "正在上传",
    fileNotfound: "未找到文件",
    edit: "编辑",
    select: "请选择",
    none: "空",
    aMaximumOf_X_CharactersCanBeUsed: "应小于{num}个字符",
    itShouldBeFormedBy_X: "应由{value}组成",
    theValueCanHaveOneDecimalPlaceOnly: "支持到一位小数",
    theValueShouldBeBetween_X_And_Y: "数值应在{min}到{max}之间",
    mustContainBetween_X_To_Y_Characters: "应在{min}到{max}个字符之间",
    savedSuccessfully: "保存成功。",
    failedToSave: "保存失败。",
    confirmDeletion: "确认删除。",
    deletedSuccessfully: "删除成功。",
    failedToDelete: "删除失败。",
    requestTimeout: "请求超时。",
    timeoutPleaseLoginAgain: "超时，请重新登录。",
    na:'不可用',
    networkError:'网络请求失败',
    newText:'新'
};
const popUpMsg = {
    login: {
        loginFailedPleaseTryAgain: "登录失败，请重试。"
    },
    resetPwd: {
        emailOrUserIdDoesntExist: "邮箱或用户名不存在。",
        wrongVerificationCode: "验证码错误。",
        yourPasswordHasBeenUpdatedPleaseLogin: "您的密码已更新。请登录。",
        failedToResetPassword: "重置密码失败。",
    },
    settings:{
        aiSettingChanged:'新的AI设定将应用到所有的VIA Mobile360 {deviceModel} 设备。'
    },
    account: {
        wrongPassword: "旧密码错误",
        failedToChangePassword: "修改密码失败。",
    },
    liveTracking: {
        vehicleName_PlateNumber_IsOffRoad: "{vehicleName}({plateNumber})已离线。",
        theTrailTimeRemainingFor_VehicleName_PlateNumber_Is_X_Days: "{vehicleName}({plateNumber})的剩余试用时间还有{day}天。",
        theKvsTimeRemainingFor_VehicleName_PlateNumber_Is_X_Seconds: "{vehicleName}({plateNumber})的剩余KVS时间还有{seconds}秒。",
        vehicleName_PlateNumber_IsOnRoad: "{vehicleName}已上线。",
        audioInputDeviceIsNotReady: "音频输入设备未就绪。",
        cannotReach_DriverName_NowPleaseTryLater: "现在无法连接到<strong>{driverName}</strong>，请稍后再试。",
        connectionTo_DriverName_IsLost: "与 <strong>{driverName}</strong> 的连接中断。",
        terminateTheCallTo_DriverNameOfTheCurrentCall_AndCall_DriverNameOfTheNewCall: "终止与 <strong>{currentDriverName}</strong> 的通话，并呼叫 <strong>{newDriverName}</strong> ？",
        driverName_IsBusyNow: "<strong>{DriverName}</strong> 忙线中。",
        terminateTheCallTo_DriverNameOfTheCurrentCall_AndLogout: "终止与{DriverName}的通话，并登出？",
    },
    fleet: {
        fleetName_AlreadyExists: "{fleetName}已经存在。"
    },
    vehicles: {
        vehicleName_AlreadyExists: "{vehicleName}已经存在。",
        thisPlateNumber_PlateNumber_AlreadyExists: "车牌号码{plateNumber}已经存在。",
        confirmUnregisterationNoMoreDataWillBeSentFromThisVehicle: "确认解除绑定。本车此后将不再上传数据。",
        unregisteredSuccessfully: "解除绑定成功。",
        failedToUnregister: "解除绑定失败。",
        seriesPlatform: "VIA Mobile360系列平台",
        selectVIAPlatform: "请选择将被安装到此车辆的VIA Mobile360平台型号。",
        completeADAS: "请先完成驾驶辅助系统校准。",
        completeDMS:  "请先完成驾驶监控系统校准。"
    },
    drivers: {
        driverName_AlreadyExists: "{driverName}已经存在。",
        thisLicenseNumber_LicenseNumber_AlreadyExists: "驾照号码{LicenseNumber}已经存在。",
        thisLoginId_LoginId_AlreadyExists: "登录名{loginID}已经存在。",
        failedToLoadImageFromCloud: "从云端加载图片失败。",
    },
    rtcPop: {
        terminateTheCallTo_DriverNameOfTheCurrentCall_AndCall_DriverNameOfTheNewCall: "@:popUpMsg.liveTracking.terminateTheCallTo_DriverNameOfTheCurrentCall_AndCall_DriverNameOfTheNewCall",
        connectionTo_DriverName_IsLost: "@:popUpMsg.liveTracking.connectionTo_DriverName_IsLost"
    }
};
const tips = {
    login: {
        pleaseInputYourEmailOrUserId: "请输入您的邮箱或用户名"
    },
    account: {
        pleaseInputAContactNumber: "请输入联系电话",
        pleaseInputAddress: "请输入地址",
    },
    resetPwd: {
        pleaseInputThePassword: "请输入密码",
        pleaseInputTheVeficationCode: "请输入验证码",
        pleaseInputTheNewPassword: "请输入新密码",
        theNewPasswordIsTheSameAsTheOldPassword: "新密码与旧密码相同",
        pleaseInputTheNewPasswordAgain: "请再次输入新密码",
        theTwoNewPasswordsAreNotTheSame: "您两次输入的密码不一致",
    },
    fleet: {
        pleaseInputAFleetName: "请填写车队名",
    },
    vehicles: {
        pleaseInputAVehicleName: "请填写车辆名称",
        pleaseInputAPlateNumber: "请填写车牌号码",
        select: "请选择",
        pleaseInputTheFuelTankCapacity: "请填写油箱容量",
        pleaseSelectVehicleWeight: "请选择车重",
        pleaseInputTheEngineDisplacement: "请填写排气量",
        theDistanceShouldBeBetween_X_Y: "距离应在{min}和{max}之间",
        pleaseInputTheVehicleHeight: "请填写车高",
        pleaseCorrectTheVehicleHeight: "请正确填写车高",
        pleaseInputTheVehicleWidth: "请填写车宽",
        pleaseInputTheHoodLength: "请填写车头长",
        TheValueShouldBeNoMoreThan300: "数值应在不大于300",
        theAboveFieldsAreRequiredToEnableAiFeatures: "需要填写以上信息才能使用AI功能",
        searchVehicleByVehicleNamePlateNumberOrVehicleModel: "输入车辆名称、车牌号码或车辆型号查找",
    },
    drivers: {
        pleaseInputAFirstName: "请填写名",
        thereIsASpaceInTheFirstNamePleaseDeleteIt: "请删除空格",
        pleaseInputALastName: "请填写姓",
        thereIsASpaceInTheLastNamePleaseDeleteIt: "请删除空格",
        pleaseInputAContactNumber: "请填写联系电话",
        pleaseInputTheLicenseNumber: "请填写驾照号码",
        pleaseInputALoginId: "请填写登录名",
        thereIsASpaceInTheLoginIdPleaseDeleteIt: "请删除空格",
        searchDriverByDriverNameContactNumberUserIDOrLicenseNumber: "输入司机名称、联系电话、登录名或驾照号码查找",
    },
    imageSizeTips:'文件应小于6兆字节。'
};
const login = {
    VIAMobile360: "VIA Mobile360",
    welcome: "欢迎使用威盛车队云平台",
    portalLanguage: "语言",//TODO 英文字段修改，简中和繁中未提供
    alertLanguage: "告警语言",
    english: "简体中文",
    loginName: "登录名",
    emailOrUserID: "邮箱或用户名",
    password: "密码",
    signIn: "登录",
    getItOnGooglePlay: "从Google Play下载",
    availableOnTheAppStore: "从App Store下载",
    copyright: "版权所有©{year} 威盛电子",
    allRightsReserved: "保留所有权利。",
    version: "版本{value}",
    timezone: "时区",
    forgotPassword: "忘记密码？",
    privacyPolicy:"隐私政策"
};
const way2Call = {
    way2Call: "双向通话",
    notifications: "呼叫通知",
    missedCalls: "未接来电",
    showMsgFor1hour: "此页仅显示最近1小时内的呼叫请求。",
    showMsgFor24hours: "此页仅显示最近24小时内的呼叫请求。",
    vehicle: "车辆",
    fleet: "车队",
    driver: "司机",
    time: "时间",
    location: "位置",
    log: "日志",
    logEvent: "日志事件",
    status: "状态",
    newTrip:'新行程',
    callRequest:'通话请求'
};
const alertList = {
    alerts: "告警",
    type: "类型",
    showMsgFor24hours: "此页仅显示最近24小时内的告警。",
    vehicle: "@:way2Call.vehicle",
    fleet: "@:way2Call.fleet",
    collisionAlert: '@:common.singular.collisionAlert',
    driverAlert: '@:common.singular.driverAlert',
    parkingAlert: '@:common.singular.parkingAlert',
    unpermittedDriver: '@:common.singular.unpermittedDriver',
    location: "@:way2Call.location",
    driver: "@:way2Call.driver",
    time: "@:way2Call.time",
};
const myAccount = {
    myAccount: "我的账户",
    company: "公司",
    loginName: "登录名",
    userID: "用户名",
    name: "姓名",
    email: "电子邮箱",
    keyContact: "关键联系人",
    contactNumber: "联系电话",
    address: "地址",
    editAccount: "编辑账户",
    changePassword: "修改密码",
    oldPassword: "旧密码",
    newPassword: "新密码",
    reEnterNewPassword: "再次输入新密码",
    jobTitle: "职务",
};
const settings = {
    settings: "设置",
    globalSettings: "全局设置",
    unitSettings: "单位设置",
    unitOfMeasure: "计量单位",
    metric: "公制",
    imperial: "英制",
    USCustomary: "美制",
    timeFormat: "时间格式",
    YYYY_MM_DD: "年-月-日",
    MM_DD_YYYY: "月-日-年",
    DD_MM_YYYY: "日-月-年",
    driverRegistrationMethod: "司机识别方式",
    driverLoginSettings: "司机登录设置",
    loginMethod: "登录方式",
    QRCode: "二维码",
    QRCodeOrFaceID: "二维码或人脸识别",
    loginScanTime: "登录扫码时间",
    loginPictureDelay: "抓拍延迟时间",
    secs: "秒",
    audioSettings: "声音设置",
    tripAudioRecording: "行程录音",
    way2CallVolume: "双向通话音量",
    min: "最小",
    max: "最大",
    way2CallRecording: "双向通话录音",
    alertVolume: "告警音量",
    videoAlertSettings: "录影告警设置",
    alertRecordingDuration: "告警录像时长",
    alertSensitivity: "告警灵敏度",
    low: "低",
    medium: "中",
    high: "高",
    deviceSettings: "设备设置",
    aISettings: "智能驾驶设置",
    ADASAudioAlerts: "ADAS告警声音",
    LDWAudioAlert: "车道偏移告警音",
    FCWAudioAlert: "前车碰撞预警音",
    FCWAlertSensitivity: "前车碰撞告警灵敏度",
    DMS: "驾驶行为告警",
    distractedDrivingAudioAlert: "分心驾驶告警音",
    distractedDrivingAlert: "分心驾驶告警",
    driverFatigueAudioAlert: "疲劳驾驶告警音",
    driverFatigueAlert: "疲劳驾驶告警",
    phoneUsageAudioAlert: "使用手机告警音",
    phoneUsageAlert: "使用手机告警",
    smokingAudioAlert: "吸烟告警音",
    smokingAlert: "吸烟告警",
    applySettings: "应用设置",
    selectWhichFleetsToApplyTheChangesTo: "选择需要更新设置的车队",
    selectToOverrideAllSettingsForTheChosenFleets: "覆盖已选车队的所有设置。",
    pressOKToApplyTheChanges: '点击“确认”应用这些更改。',
    firmwareUpgrade: "固件升级",
    currentFirmwareVersion: "当前固件版本",
    newVersionAvailable: "新版本已发布！",
    releaseNotes: "发行说明",
    upgrade: "升级",
    upgradeFirmware: "升级固件",
    pressOKToUpgradeYourMobile360D700Devices: "点击“确认”升级{device}设备。",
    newFirmWareAvailableFor: "{name}的新固件已发布。",
    firmwareVersion: "固件版本",
    upgradeNow: "现在升级",
    version: '@:login.version',
    LDW: "@:tripHistory.LDW ",
    FCW: "@:tripHistory.FCW ",
    driverFatigue: "@:tripHistory.driverFatigue ",
    smoking: "@:tripHistory.smoking ",
    distractedDriving: "@:tripHistory.distractedDriving ",
    phoneUsage: "@:tripHistory.phoneUsage",
    bsdAudioAlert:'盲点检测告警音',
    GSensorEventSensitivity:'G-Sensor事件灵敏度',
    GeneralSettings:'一般设置',
    RecordSettings:'录影设置',
    alertSettings:'告警设置',
    parkingAndCollisionSensitivity:'驻车和碰撞告警灵敏度',
    partner_program:'合作伙伴计划',
    partner_program_content1:'The Partner Feedback Loop enables VIA to receive system critical log files and event videos generated during a customer’s free VIA Fleet Portal trial period in order to sync up customer feedback and continually improve the customer trail run experience on VIA Mobile360 products. The data collected will only be used to fine tune performance of the system software and AI features which will be passed on to customers through future updates.',
    partner_program_content2:'On average data generated per trip is dependent on the hours and events triggered.  An estimated data transferring size is listed below for Mobile360 series product.  The data is passed through the mobile data plan used on the device. If you do not wish to participate in this program you can disable the options below.',
    log_files:'日志文件',
    event_videos:'事件视频'
};
const billing = {
    billing: "账单"
};
const userAccounts = {
    userAccounts: "用户账号",
    users: "用户",
    emailAddress: "邮箱地址",
    userRole: "用户角色",
    fleet: "车队",
    addUser: "新增用户",
    firstName: "名",
    lastName: "姓",
    userPermissions: "用户权限",
    mobile360AppSettings: "Mobile360应用程序设置",
    alert: "告警",
    deviceRegistration: "设备绑定",
    deviceCalibration: "设备校准",
    uploadFRPhotos: "上传人脸识别照片",
    management: "管理",
    fleets: "车队",
    vehicles: "车辆",
    drivers: "司机",
    edit: "编辑",
    add: "新增",
    roles: "角色",
    roleName: "角色名称",
    administrator: "管理员",
    fleetManager: "车队经理",
    purchaser: "采购",
};
const logout = {
    logout: "登出"
};
const liveTracking = {
    liveTracking: "实时跟踪",
    onRoad: "在线",
    offRoad: "离线",
    vehicleNameOrPlateNumber: "车辆名或车牌号",
    information: "信息",
    fileNotFound: "文件未找到",
    liveVideo: "实时视频",
    connecting: "正在连接",
    unableToConnect: "无法连接",
    G4ConnectionLost: "4G连接中断。",
    KVSTimeUsedUp: "KVS时间已用完。",
    GPS: "定位",
    zoomIn: "放大",
    zoomOut: "缩小",
    ft: "英尺",
    mi: "英里",
    m: "米",
    km: "千米",
    unassigned: '@:globalText.unassigned',
    unassignedVehicles: '@:fleet.unassignedVehicles',
    alerts: '@:common.plural.alerts',
    way2Call: '@:way2Call.way2Call',
    collisionAlerts: '@:dashboard.collisionAlerts',
    driverAlerts: '@:dashboard.driverAlert',
    parkingAlerts: '@:dashboard.parkingAlerts',
    unpermittedDrivers: '@:dashboard.unpermittedDriver',
    plateNumber: "@:vehicle.plateNumber",
    fleet: "@:userAccounts.fleet",
};
const dashboard = {
    dashboard: "数据统计",
    days7: "7天",
    days30: "30天",
    month: "12个月",
    january: "一月",
    february: "二月",
    march: "三月",
    april: "四月",
    may: "五月",
    june: "六月",
    july: "七月",
    august: "八月",
    september: "九月",
    october: "十月",
    november: "十一月",
    december: "十二月",
    mon: "周一",
    tue: "周二",
    wed: "周三",
    thu: "周四",
    fri: "周五",
    sat: "周六",
    sun: "周日",
    hoursDriven: "行驶时长",
    distanceDriven: "行驶里程",
    fuelConsumption: "油耗",
    total: "总计",
    avg: "均值",
    collisionAlerts: "碰撞告警",
    parkingAlerts: "驻车告警",
    driverScore: "司机得分",
    average: "均值",
    totalHoursDriven: "总行驶时长",
    totalDistanceDriven: "总行驶里程",
    totalFuelConsumption: "总油耗",
    collisionAlert: "碰撞告警",
    driverAlert: "司机告警",
    parkingAlert: "驻车告警",
    unpermittedDriver: "未许可司机",
    rankingsByVehicle: "车辆排行",
    rankingsByDriver: "司机排行",
    vehicleName: "车辆名",
    driverName: "司机名",
    time: "时长",
    distance: "距离",
    fuel: "油耗",
    unassigned: '@:globalText.unassigned',
    unassignedVehicles: '@:fleet.unassignedVehicles',
    hours: '@:common.plural.hours',
    jan:'一月',
    feb:'二月',
    mar:'三月',
    apr:'四月',
    jun:'六月',
    Jul:'七月',
    aug:'八月',
    sep:'九月',
    oct:'十月',
    nov:'十一月',
    dec:'十二月',
    newAlert: '新告警',
    newCollisionAlert: '新碰撞告警',
    newDriverAlert: '新司机告警',
    newParkingAlert: '新驻车告警'
};
const tripHistory = {
    tripHistory: "行程历史",
    totalTime: "总时长",
    totalIdleTime: "总怠速时长",
    distance: "距离",
    avgFuelConsumption: "平均油耗",
    totalIdleFuelConsumption: "总怠速油耗",
    totalFuelConsumption: "总油耗",
    avgDriverScore: "平均驾驶得分",
    times: "次",
    driver: "司机",
    headquarters: "总部",
    gSensorEvents: "G-sensor事件",
    hardRevving: "急加速",
    hardBraking: "急刹车",
    hardCornering: "急转弯",
    ADASEvents: "驾驶辅助系统警报",
    LDW: "车道偏移警报",
    FCW: "前车碰撞警报",
    DMSEvents: "驾驶监控警报",
    driverFatigue: "疲劳驾驶",
    distractedDriving: "分心驾驶",
    smoking: "抽烟",
    phoneUsage: "使用手机",
    trip: "行程",
    start: "起点",
    end: "终点",
    idleTime: "怠速时长",
    avgSpeed: "平均速度",
    maxSpeed: "最大速度",
    way2CallFromDriver: "司机通话请求",
    way2CallFromHeadquarter: "总部通话记录",
    idleFuelConsumption: "怠速油耗",
    searchType: {
        driver: '@:common.singular.driver',
        fleet: '@:common.singular.fleet',
        vehicle: '@:common.singular.vehicle',
        alert: '@:common.singular.alert',
    },
    alertList: {
        collisionAlert: '@:common.singular.collisionAlert',
        driverAlert: '@:common.singular.driverAlert',
        parkingAlert: '@:common.singular.parkingAlert',
        unpermittedDriver: '@:common.singular.unpermittedDriver',
        all: '@:globalText.all',
    },
    alert: '@:common.singular.alert',
    alerts: '@:common.plural.alerts',
    fleetName: '@:fleet.fleetName',
    driverName: '@:dashboard.driverName',
    collisionAlert: '@:common.singular.collisionAlert',
    collisionAlerts: '@:dashboard.collisionAlerts',
    driverAlert: '@:common.singular.driverAlert',
    driverAlerts: '@:dashboard.driverAlert',
    parkingAlerts: '@:dashboard.parkingAlerts',
    parkingAlert: '@:common.singular.parkingAlert',
    unpermittedDriver: '@:common.singular.unpermittedDriver',
    unpermittedDrivers: '@:dashboard.unpermittedDriver',
    driverScore: '@:dashboard.driverScore',
    way2Call: '@:way2Call.way2Call',
    vehicleNameOrPlateNumber: '@:liveTracking.vehicleNameOrPlateNumber',
};
const fleet = {
    vehicleManagement: "车辆管理",
    addFleet: "添加车队",
    addVehicle: "添加车辆",
    fleetName: "车队名称",
    totalVehicles: "车辆总数",
    totalDrivers: "司机总数",
    description: "说明",
    unassignedVehicles: "未分配的车辆",
    editFleet: "编辑车队",
    vehicleList: "车辆列表",
    fleetSettings: "车队设置",
    noUnassignedVehicle:'没有未分配车辆',
    noVehicleAssigned:'没有分配车辆'
};
const vehicle = {
    vehicleName: "车辆名称",
    plateNumber: "车牌号码",
    vehicleModel: "车辆型号",
    permittedDrivers: "允许的司机",
    registered: "已绑定",
    trialTimeDays: "试用时间",
    days:"天",
    KVSTimeHMS: "KVS时间",
    hms:'时：分：秒',
    deviceAndFirmware: '设备和固件',
    addVehicle: "添加车辆",
    editVehicle: "编辑车辆",
    vehicleInformation: "车辆信息",
    fuelTankCapacity: "油箱容量",
    fuelType: "燃料类型",
    vehicleWeight: "车重",
    tonRange: "1.8吨以下，1.8~3.4吨，3.4吨以上",
    displacement: "排气量",
    vehicleLicense: "车辆牌照",
    deviceInformation: "设备信息",
    deviceModel: "设备型号",
    unregister: "解除绑定",
    SIMCard: "SIM卡",
    AIFeatures: "AI功能",
    vehicleHeight: "车高",
    distanceFromCameraToRoof: "摄像头到车顶的距离",
    driverSeat: "驾驶位",
    left: "左侧",
    right: "右侧",
    vehicleWidth: "车宽",
    hoodLength: "车头长",
    ADASCalibration: "驾驶辅助系统校准",
    DMSCalibration: "驾驶监控系统校准",
    trialPeriodInformation: "试用时间",
    trialTimeRemaining: "剩余试用时间",
    KVSTimeRemaining: "剩余KVS时间",
    search: "搜索...",
    searchHover: '输入车辆名称、车牌号码或车辆型号查找',
    fleetName: "@:fleet.fleetName",
    totalVehicles: "@:fleet.totalVehicles",
    totalDrivers: "@:fleet.totalDrivers",
    description: "@:fleet.description",
    vehicleList: "@:fleet.vehicleList",
    fleet: "@:way2Call.fleet",
    LDW: "@:tripHistory.LDW",
    FCW: "@:tripHistory.FCW",
    DMS: "驾驶监控镜头",
    DVR:'DVR',
    camera_one:'摄像头 #1 ',
    camera_two:'摄像头 #2 ',
    camera_three:'摄像头 #3 ',
    camera_four:'摄像头 #4 ',
    camera_five:'摄像头 #5 ',
    camera_six:'摄像头 #6 ',
    camera_seven:'摄像头 #7 ',
    camera_eight:'摄像头 #8 ',
    camera_nine:'摄像头 #9 ',
    SVSCalibration:'环视系统校准',
    pedestrianDetection:'行人侦测',
    enabledRear:'尾部侦测',
    camerasAIFeatures:'摄像头和AI功能',
    disable:'禁用',
    under:"小于 ",
    over:"大于 ",
    t:" 吨",
    Front_LDW:'前置摄像头 - 车道偏移检测',
    Front_FCW:'前置摄像头 - 前车碰撞检测',
    Front_LDW_FCW:'前置摄像头 - 车道偏移和前车碰撞检测',
    Left_BSD:'左侧摄像头 - 盲点检测',
    Right_BSD:'右侧摄像头 - 盲点检测',
    Rear:'后置摄像头',
    Rear_DMOD:'后置摄像头 - 行人检测',
    Rear_PAS:'后置摄像头 - 停车辅助',
    Rear_PAS_DMOD:'后置摄像头 - 行人检测和停车辅助',
    Front_SVS:'前置环视摄像头',
    Front_SVS_DMOD:'前置环视摄像头 - 行人检测',
    Left_SVS:'左侧环视摄像头',
    Right_SVS:'右侧环视摄像头',
    Rear_SVS:'后置环视摄像头',
    Rear_SVS_PAS:'后置环视摄像头 - 停车辅助',
    Rear_SVS_DMOD:'后置环视摄像头 - 行人检测',
    Rear_SVS_PAS_DMOD:'后置环视摄像头 - 行人检测和停车辅助',
    Rear_SVS_DMOD_PAS:'后置环视摄像头 - 停车辅助和行人检测',
    camera:'',
    parkingAssist:'停车辅助'
};
const driver = {
    driverManagement: "司机管理",
    addDriver: "添加司机",
    driverName: "司机姓名",
    loginID: "登录名",
    contactNumber: "联系电话",
    licenseNumber: "驾照号码",
    permittedVehicles: "允许的车辆",
    emergContact: "紧急联络人",
    faceIDPhoto: "人脸识别照片",
    editDriver: "编辑司机",
    driverInformation: "司机信息",
    firstName: "名",
    lastName: "姓",
    dateJoined: "加入日期",
    emergContactNumber: "紧急联络人电话",
    driverLicense: "驾驶执照",
    driverPhoto: "司机照片",
    comment: "备注",
    useFaceIDPhoto: "使用人脸识别照片",
    mobile360APPDriverLoginInformation: "Mobile360应用程序 –司机登录信息",
    password: "@:login.password",
    search: "@:vehicle.search",
    searchHover: '输入司机名称、联系电话、登录名或驾照号码查找'
};
export {
    common,
    units,
    globalText,
    popUpMsg,
    tips,
    login,
    way2Call,
    alertList,
    myAccount,
    settings,
    billing,
    userAccounts,
    logout,
    liveTracking,
    dashboard,
    tripHistory,
    fleet,
    vehicle,
    driver
};
