<template>
    <div id="app">
        <router-view></router-view>
        <div class="privacy-box" v-if="isShowPrivacyDialog">
            <privacy-policy @onConfirmPrivacy="onConfirmPrivacy"></privacy-policy>
        </div>
    </div>
</template>

<script>
    import PrivacyPolicy from './components/common/PrivacyPolicyBar'

    export default {
        name: 'app',
        components: {
            PrivacyPolicy
        },
        data(){
            return {
                isShowPrivacyDialog: !localStorage.getItem('confirmPrivacy'),
            }
        },
        methods: {
            onConfirmPrivacy() {
                document.getElementsByClassName("privacy-box")[0].style.display = 'none';
            },
        },
        mounted() {
            window.onresize = () => {
                /**
                 * 因为window.onresize是全局的，所以只能赋值一次，如果多次赋值会覆盖之前的逻辑。
                 * 如果需要用到window.onresize的地方，可以通过监听下面的值
                 * this.$store.getters.screen
                 * this.$store.getters.screenWidth
                 * this.$store.getters.screenHeight
                 * 的变化来实现
                 */
                this.$store.commit('updateScreenWidth', document.documentElement.clientWidth);
                this.$store.commit('updateScreenHeight', document.documentElement.clientHeight);
            };
        },
        beforeDestroy() {
            window.onresize = null;
        },
    }
</script>

<style lang="stylus">
    @import "assets/css/main.styl";
    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
    }

    .privacy-box
        position: fixed
        top: 0
        width: 100%
        height: 80px


</style>
