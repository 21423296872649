import momentTimezone from "moment-timezone";
import DateFormat from '../assets/js/DateFormat'

export default {
    currentUserData: function (state) {
        return state.currentUserData;
    },
    touchSessionTimer: function (state) {
        return state.touchSessionTimer;
    },
    unit: function (state) {
        if (state.companyInfo && state.companyInfo.Unit) {
            return state.companyInfo.Unit;
        }
        return null;
    },
    dateFormat: function (state) {
        if (state.companyInfo && state.companyInfo.DateFormat) {
            return state.companyInfo.DateFormat;
        }
        return DateFormat.DATE_FORMAT;
    },
    timeFormat: function (state) {
        return DateFormat.TIME_FORMAT;
    },
    dateTimeFormat: function (state) {
        if (state.companyInfo && state.companyInfo.DateFormat) {
            return state.companyInfo.DateFormat + ' ' + DateFormat.TIME_FORMAT;
        }
        return DateFormat.DATE_TIME_FORMAT;
    },
    timezone: function (state) {
        if (state.currentUserData && state.currentUserData.TimeZone)
            return state.currentUserData.TimeZone;
        if (sessionStorage.getItem('timezone'))
            return sessionStorage.getItem('timezone');
        return momentTimezone.tz.guess();
    },
    companyInfo: function (state) {
        return state.companyInfo;
    },
    driverPassword: state => {
        if (state.companyInfo) {
            console.log(state.companyInfo.Id);
            var password = state.companyInfo.Id;
            if (password == "0via")
                return "VIA";
            return password;
        }
        return "";
    },
    CurrentOnRoadTreeNodeId: function (state) {
        return state.CurrentOnRoadTreeNodeId;
    },
    getMenuCollapse: function (state) {
        return state.menuCollapse;

    },
    getAxiosLoadingState(state){
        return state.loading;
    }
}
