import websocketConfig from '../../assets/js/websocketConfig';
import bus from "../../utils/bus";
import store from "../../store/index";
import VUE from 'vue'
import VueI18n from 'vue-i18n'

VUE.use(VueI18n);

const i18nAxios = new VueI18n({
    locale: localStorage.getItem('language') || "en-US",
    // 修改messages需要同步修改Main.js对应的messages.
    messages: {
        'en-US': require("../../assets/lang/en.js"),
        'zh-CN': require("../../assets/lang/zh-CN.js"),
        'zh-TW': require("../../assets/lang/zh-TW.js")
    }
});

const state = {
    socket: null,
    pingTimer: null,
    awsTimer: null,
    collisions: {
        hasShowListCount: 0, // 登录后台或者刷新页面以后是否看过顶部的Alerts列表的次数
        lastMaxTime: 0, // 上一次碰撞列表中最大的时间
        newsStatus: false, // 记录是否存在新的碰撞信息
        newsStatusTimer: null, // 24小时后执行关闭newStatus的timer
        list: null,
        current: null, // 记录顶部被选中的collision信息
        news: {
            time: null,
            data: {}
        }
    },
    requestCalled: {
        rtcConnectedDeviceId: null,//当前正在通话的DeviceId
        rtcConnectedVehicleId: null,//当前正在通话的VehicleId
        rtcConnectedDriverName: null,//当前正在通话的Driver名称
        isRunningRtc: false,//是否有正在进行的RTC通话
        connectionId: null,//Server端发送action = ConnectionId的data数据
        timer: null,//收到新的通话请求时设置一小时倒计时
        hasNotifications: false,//记录是否有rtc的Notification通知未处理
        rtcLocalStream: null,//rtc通话本地音频流
        rtcRemoteStream: null,//rtc通话device音频流
        hangUpIconStatus: true,//通话挂断按钮可用状态
        deviceIsEnterChannel: false,//标识device是否已成功进入channel
    },
    rtcTaskInfo: {
        type: null,//记录开启rtc通话的组件（ComInformation.vue/AlertRtcMessage.vue）
        deviceId: null,//要通话通话的deviceId
        driverName: null,//要通话的driverName
        vehicleId: null,//要通话的VehicleId(即plateNumber)
        rtcRecalledTask: null,
        timeFlag: null
    },
    rtcDeviceBusyState: {
        isDeviceBusy: false
    },
    rtcCallId: null
};

const getters = {
    getRtcCallId(state){
        return state.rtcCallId;
    },
    hasNewCollisionInfo(state) { // 是否存在新的碰撞信息
        let lastCollisionTime = null;
        let lastStorageTime = localStorage.getItem('newCollisionTime');
        lastStorageTime = lastStorageTime ? parseInt(lastStorageTime) : 0;

        if (state.collisions.list && state.collisions.list[0]) {
            lastCollisionTime = state.collisions.list[0].Timestamp * 1000;
        }

        if (state.collisions.news.time === null && lastCollisionTime && lastStorageTime && lastStorageTime >= lastCollisionTime) {
            return localStorage.getItem('newCollisionStatus') === 'open';
        }

        return state.collisions.newsStatus;
    },
    lastMaxCollisionTime(state) { // 上一次获取碰撞列表的最大时间
        let localHistoryTime = localStorage.getItem('lastMaxCollisionTime');
        // 没有看过Alerts列表，并且本地有记录以前看过的记录
        if (state.collisions.hasShowListCount <= 1 && localHistoryTime) return parseInt(localHistoryTime);
        return state.collisions.lastMaxTime;
    },
    getRequestCallInfo(state) {
        return state.requestCalled;
    },
    getIsRunningRtcState() {
        return state.requestCalled.isRunningRtc;
    },
    getHasRtcNotification() {
        let value = state.requestCalled.hasNotifications;
        console.log(`rtc notification value = ${value}`);
        return value;
    },
    getRtcTaskInfo() {
        let info = state.rtcTaskInfo;
        return {
            type: info.type, deviceId: info.deviceId, driverName: info.driverName, vehicleId: info.vehicleId,
            rtcRecalledTask: info.rtcRecalledTask, timeFlag: state.rtcTaskInfo.timeFlag
        };
    },
    getHangUpIconStatus() {
        return state.requestCalled.hangUpIconStatus;
    },
    getIsDeviceEnterChannel() {
        return state.requestCalled.deviceIsEnterChannel;
    },
    getDeviceBusyState() {
        return state.rtcDeviceBusyState.isDeviceBusy;
    }
};

const mutations = {
    recoverAllData(state) {
        state.collisions = {
            hasShowListCount: 0, // 登录后台或者刷新页面以后是否看过顶部的Alerts列表的次数
            lastMaxTime: 0, // 上一次碰撞列表中最大的时间
            newsStatus: false, // 记录是否存在新的碰撞信息
            list: null,
            current: null, // 记录顶部被选中的collision信息
            news: {
                time: null,
                data: {}
            }
        };
    },
    setSocket(state, value) {
        state.socket = value;
    },
    deleteSocket(state) {
        state.socket = null;
    },
    setPingTimer(state, timer) {
        state.pingTimer = timer;
    },
    deletePingTimer(state) {
        state.pingTimer = null;
    },
    setAwsTimer(state, timer) {
        state.awsTimer = timer;
    },
    deleteAwsTimer(state) {
        state.awsTimer = null;
    },
    updateCollisions(state, dataObj) {
        switch (dataObj.type) {
            case 'newsStatus':
                state.collisions.newsStatus = dataObj.data;
                if (!state.collisions.newsStatusTimer) {
                    store.commit('websocket/addNewCollisionTimer', localStorage.getItem('newCollisionTime'));
                }
                break;
            case 'news':
                // 发生新碰撞更新newsStatus状态
                if (
                    state.collisions.news.time === null
                    || !(
                        state.collisions.news.data.Timestamp === dataObj.data.Timestamp
                        && state.collisions.news.data.TripId === dataObj.data.TripId
                        && state.collisions.news.data.Event === dataObj.data.Event
                        && (dataObj.data.Event === 'Collision' || dataObj.data.Event === 'DriverAlert')
                    )
                ) {
                    store.commit('websocket/openNewCollisionStatus');
                    store.commit('websocket/addNewCollisionTimer');
                }
                state.collisions.news.data = dataObj.data;
                state.collisions.news.time = new Date().getTime();
                break;
            case 'list':
                if (dataObj.data === null) {
                    let oldData = state.collisions.list;
                    state.collisions.list = Object.assign([], oldData);
                } else {
                    dataObj.data.sort((a, b) => {
                        return b.Timestamp - a.Timestamp;
                    });
                    let timestamp = dataObj.data[0].Timestamp ? dataObj.data[0].Timestamp * 1000 : null;
                    store.commit('websocket/addNewCollisionTimer', timestamp);
                    state.collisions.list = dataObj.data;
                }
                break;
            case 'devicesCalled':
                console.log('收到更新requestCalled的ws消息，消息体：' + JSON.stringify(dataObj));
                setOneHourCountDown();
                break;
            case 'upDataConnectionId':
                state.requestCalled.connectionId = dataObj.data;
                break;
            default:
                break;
        }
    },
    addNewCollisionTimer(state, startTimestamp) {
        let lastTime = 24 * 3600 * 1000;
        if (startTimestamp) {
            let time = lastTime - (new Date().getTime() - startTimestamp);
            if (time > 0) lastTime = time;
        }
        // 设置24小时后关掉小红点的定时器
        if (state.collisions.newsStatusTimer) clearTimeout(state.collisions.newsStatusTimer);
        state.collisions.newsStatusTimer = setTimeout(() => {
            state.collisions.newsStatus = false;
            localStorage.setItem('newCollisionStatus', 'close');
        }, lastTime);
    },
    setCurrentCollisionInfo(state, dataObj) {
        state.collisions.current = dataObj;
    },
    deleteCurrentCollisionInfo(state) {
        state.collisions.current = null;
    },
    openNewCollisionStatus(state) {
        state.collisions.newsStatus = true;
        localStorage.setItem('newCollisionTime', new Date().getTime());
        localStorage.setItem('newCollisionStatus', 'open');
    },
    closeNewCollisionStatus(state) {
        if (state.collisions.hasShowListCount >= 1 && state.collisions.list && state.collisions.list[0] && state.collisions.list[0].Timestamp) {
            state.collisions.lastMaxTime = state.collisions.list[0].Timestamp;
            localStorage.setItem('lastMaxCollisionTime', state.collisions.list[0].Timestamp);
            if (state.collisions.hasShowListCount < 10) state.collisions.hasShowListCount++;
        } else {
            state.collisions.hasShowListCount++;
        }
        state.collisions.newsStatus = false;
        localStorage.setItem('newCollisionTime', new Date().getTime());
        localStorage.setItem('newCollisionStatus', 'close');
    },
    setCurrentRtcFlag(state, params) {
        console.log(`params = ${JSON.stringify(params)}`);
        if (params) {
            state.requestCalled.rtcConnectedDeviceId = params.DeviceId;
            state.requestCalled.rtcConnectedVehicleId = params.VehicleId;
            state.requestCalled.rtcConnectedDriverName = params.DriverName;
        } else {
            state.requestCalled.rtcConnectedDeviceId = null;
            state.requestCalled.rtcConnectedVehicleId = null;
            state.requestCalled.rtcConnectedDriverName = null;
        }
    },
    setRunningRtcFlag(state, flag) {
        state.requestCalled.isRunningRtc = flag;
    },
    setRtcLocalStream(state, stream) {
        state.requestCalled.rtcLocalStream = stream;
    },
    setRtcRemoteStream(state, remoteStream) {
        state.requestCalled.rtcRemoteStream = remoteStream;
    },
    setDeviceEnterChannelStatus(state, flag) {
        state.requestCalled.deviceIsEnterChannel = flag;
    },
    setRtcTask(state, info) {
        console.log(`rtc task info = ` + JSON.stringify(info));
        state.rtcTaskInfo.type = info ? info.type : null;
        if (info) {
            state.rtcTaskInfo.deviceId = info.deviceId ? info.deviceId : state.rtcTaskInfo.deviceId;
            state.rtcTaskInfo.driverName = info.driverName ? info.driverName : state.rtcTaskInfo.driverName;
            state.rtcTaskInfo.vehicleId = info.vehicleId ? info.vehicleId : state.rtcTaskInfo.vehicleId;
            state.rtcTaskInfo.rtcRecalledTask = info.otherRtcTask ? info.otherRtcTask : state.rtcTaskInfo.rtcRecalledTask;
            state.rtcTaskInfo.timeFlag = info.currentTime ? info.currentTime : state.rtcTaskInfo.timeFlag;
        } else {
            //清除可能存在的回拨任务
            state.rtcTaskInfo.rtcRecalledTask = null
        }

    },
    setHangUpStatus(state, flag) {
        state.requestCalled.hangUpIconStatus = flag;
    },
    setDeviceBusyState(state, flag) {
        state.rtcDeviceBusyState.isDeviceBusy = flag;
    },
    setRtcCallId(state,callId){
        state.rtcCallId = callId;
    }
};

const actions = {
    createSocket({commit, state, dispatch}) {
        if (!state.Socket) {
            let socket = new WebSocket(websocketConfig.url);
            commit('setSocket', socket);
            state.socket.onopen = () => {
                handleOnOpen({commit, state, dispatch});
            };
            state.socket.onmessage = (event) => {
                handleOnMessage({commit, state}, event);
            };
            state.socket.onerror = () => {
                console.log('global socket onerror', new Date().toString());
            };
            state.socket.onclose = () => {
                console.log('global onclose', new Date().toString());
            };
        } else {
            console.log('global socket has connected', new Date().toString());
        }
    },
    closeSocket({commit, state}) {
        if (state.socket) {
            state.socket.close();
            commit('deleteSocket');
        }
        if (state.pingTimer) {
            clearInterval(state.pingTimer);
            commit('deletePingTimer');
        }
        if (state.awsTimer) {
            clearInterval(this.state.awsTimer);
            commit('deleteAwsTimer')
        }
        commit('recoverAllData');
    }
};

function getUserToken() {
    return sessionStorage.getItem('token');
}

function setOneHourCountDown() {
    if (state.requestCalled.timer) {
        //清除原有倒计时
        clearTimeout(state.requestCalled.timer);
        state.requestCalled.timer = null;

    }
    console.log(`发生新的通话请求，显示rtc小红点信息`);
    state.requestCalled.hasNotifications = true;
    bus.$emit('onNewCall');
    state.requestCalled.timer = setTimeout(() => {
        //设置一小时倒计时后更新状态
        console.log(`新的通话请求一小时倒计时完成，rtc通话小红点状态置为false`);
        state.requestCalled.hasNotifications = false;
        clearInterval(state.requestCalled.timer);
        state.requestCalled.timer = null;
    }, 60 * 60 * 1000);

}

function handleOnOpen({commit, state, dispatch}) {
    if (!state.socket) return;
    // 通过ping来延迟websocket的连接时长
    let pingTimer = setInterval(() => {
        let msg = {"action": "ping"};
        msg = JSON.stringify(msg);
        if (state.socket !== null) {
            state.socket.send(msg);
            console.log('global ping:', new Date().toString(), '5 * 60 * 1000');
        }
    }, 5 * 60 * 1000);
    if (state.pingTimer) {
        clearInterval(state.pingTimer);
    }
    commit('setPingTimer', pingTimer);
    // 解决aws每隔2小时强制掉线的问题
    let awsTimer = setInterval(() => {
        if (state.socket) {
            dispatch('closeSocket').then(() => {
                dispatch('createSocket');
            });
            console.log('global socket reconnect aws:', new Date().toString(), '55 * 60 * 1000');
        }
    }, 55 * 60 * 1000);
    if (state.awsTimer) {
        clearInterval(state.awsTimer);
    }
    commit('setAwsTimer', awsTimer);
    if (getUserToken()) {
        let notificationMsg = {
            "action": "notification",
            token: getUserToken(),
        };
        notificationMsg = JSON.stringify(notificationMsg);
        state.socket.send(notificationMsg);
    }
}

function handleOnMessage({commit, state}, event) {
    let obj = JSON.parse(event.data);
    switch (obj.action) {
        case 'ping':
            console.log('global ping message for notification', obj)
            break;
        case 'newsStatus': // 連接ws成功後會受到該信息
            commit('updateCollisions', {type: 'newsStatus', data: obj.data});
            if (obj.data) { // 只根據api接口判斷鈴鐺是否存在數據來判定是否顯示小紅點
                bus.$serverApi.collision.list();
            }
            break;
        case 'news': // 有新的碰撞事件產生
            commit('updateCollisions', {type: 'news', data: obj.data});
            // bus.$serverApi.collision.list();
            break;
        case 'firmware-available':
            store.commit('firmware/initUpgradeFirmware', {firmwareList: obj.data});
            break;
        case 'DialRequest'://收到Device通话请求通知
            console.log(`收到Devices发起的主动通话请求,event = ${JSON.stringify(obj)}`);
            //收到devices发起的主动通话请求,全局记录发起通话的devicesID,向外发送onDevicesCalled事件并携带DevicesId参数
            commit('updateCollisions', {type: 'devicesCalled', data: obj.data});
            break;
        case 'ConnectionId'://Server端發送ConnectionId給Browser
            commit('updateCollisions', {type: 'upDataConnectionId', data: obj.data});
            break;
        case 'Dial':
            /**
             {
	                "action": "Dial",
	                "data": {
	            	    "DeviceId":"3E1224A03ACD4A58B253431BE982398B",
	            	    "VehicleId":"xxxxxxxxxxxxxxx",
	            	    "Status":"Busy" //目前狀態只有"Busy，忙線中
	                 }
                }
             */
            console.log(`将要进行通话的device忙线中，data = ${JSON.stringify(obj)}`);
            //通知Realtime组件结束推流，并弹窗告知用户
            checkCurrentRtcState(obj.data);


            break;
        case 'DialStatusTTL':
            console.log(`rtc红点倒计时：DialStatusTTL obj.data = ${JSON.stringify(obj.data)}`);
            if (obj.data > 0) {
                state.requestCalled.hasNotifications = true;
                if (state.requestCalled.timer) {
                    clearInterval(state.requestCalled.timer);
                    state.requestCalled.timer = setInterval(() => {
                        console.log(`rtc红点倒计时完成，状态置为false`);
                        state.requestCalled.hasNotifications = false;
                        clearInterval(state.requestCalled.timer);
                        state.requestCalled.timer = null;
                    }, parseInt(obj.data) * 1000);
                }
            } else {
                state.requestCalled.hasNotifications = false;
                if (state.requestCalled.timer) {
                    clearInterval(state.requestCalled.timer);
                    state.requestCalled.timer = null;
                }
            }
            break;
        default:
            break;
    }
}

function checkCurrentRtcState(data) {
    if (state.requestCalled.rtcConnectedDeviceId === data.DeviceId && state.requestCalled.rtcConnectedVehicleId === data.VehicleId) {
        //通知Realtime组件结束推流
        //bus.$emit('onClickAudio', null, null, null, null);
        store.commit('websocket/setDeviceBusyState', true);
        store.commit('websocket/setRtcTask', null);
        //当前device正忙
        VUE.prototype.$customAlert(i18nAxios.t('popUpMsg.liveTracking.driverName_IsBusyNow', {
            DriverName: state.requestCalled.rtcConnectedDriverName
        }),{dangerouslyUseHTMLString: true});
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};
