import {MessageBox} from 'element-ui'
import Vue from 'vue'
import './messageBox.styl'

export function confirm(message, options) {
    if (!options)
        options = {};
    options.customClass = "custom-message-box";

         options.customClass += ' no-header';
    options.cancelButtonClass= 'btn-custom-cancel';
    options.type = options.type ? options.type:"warning"
    return MessageBox.confirm(message, options)
}

export function alert(message, options) {
    if (!options)
        options = {};
    options.customClass = "custom-message-box";

        options.customClass += ' no-header';
    return MessageBox.alert(message, options)
}

export function prompt(message, options) {
    options.customClass = "custom-message-box";
        options.customClass += ' no-header';
    return MessageBox.prompt(message, title, options)
}

Vue.prototype.$customAlert = alert;
Vue.prototype.$customConfirm = confirm;
Vue.prototype.$customPrompt = prompt;

