import moment from 'moment';
import momentTimezone from "moment-timezone";
import store from '../index'

const state = {
    timer: null,
    time: null,
    offsetNum: null,
};

const getters = {
    time: (state) => {
        return state.time;
    },
    UTC: (state) => {
        if (state.offsetNum > 0) return `UTC+${state.offsetNum}`;
        if (state.offsetNum < 0) return `UTC${state.offsetNum}`;
        return 'UTC';
    }
};

const mutations = {
    startIntervalTimer: (state) => {
        if (state.timer === null) {
            state.timer = setInterval(() => {
                let current = moment(),
                    timezone = store.getters['timezone'];
                state.time = current.tz(timezone).format('HH:mm:ss');
                state.offsetNum = current.tz(timezone).utcOffset() / 60;
            }, 500);
        }
    },
};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};