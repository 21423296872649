import ServerApi from "../../assets/js/ServerAPI"
import store from "../../store"
import {concatUnique} from "../../assets/js/Util"
import Sort from "@/assets/js/Sort";

const state = {
    fleetList: null,
    currentFleetData: null,
    fleetTableData: [],
    fleetVehicleTreeData: {},
    managerList: null,
    unassignedVehicleList: null,
    fleetListCtx: {
        fleetSort: null,
        vehiclesSort: [],
    },
    vehicleSearchKey: null,
    keepAlivePage: []

};

let nameSort = (itemA, itemB) => {
    return Sort.NameSort(itemA.Name, itemB.Name)
};


const getters = {
    fleetTableData: state => {
        state.fleetTableData = [];
        if (state.fleetList != null) {
            var subs = state.fleetList.Sub;
            if (subs) {
                for (var i = 0; i < subs.length; i++) {
                    var item = {
                        Id: subs[i].Id,
                        Descript: subs[i].Descript,
                        Name: subs[i].Name,
                        Color: subs[i].Color,
                        CompanyName: subs[i].Company.Name,
                        VehiclesNumber: subs[i].Vehicles ? subs[i].Vehicles.length : 0,
                        FleetManagers: subs[i].FleetManagers ? subs[i].FleetManagers : [],
                        Vehicles: subs[i].Vehicles ? subs[i].Vehicles.sort(nameSort) : [],
                        DriversNumber: subs[i].Vehicles ? getDriversNumber(subs[i].Vehicles) : 0
                    };
                    state.fleetTableData.push(item);
                    state.fleetTableData.sort(nameSort);
                }
            }
        }
        if (state.unassignedVehicleList) {
            var unAssignedItem = {
                Id: "unassignedVehicle",
                Name: "Unassigned Vehicles",
                Color: "#909090",
                Vehicles: state.unassignedVehicleList
            };
            state.fleetTableData.push(unAssignedItem);
        }
        return state.fleetTableData;
    },
    getAllFleets: (state) => (sort = false) => {
        if (state.fleetList) {
            if (state.fleetList.Sub) {
                if (sort) {
                    return state.fleetList.Sub.sort(nameSort)
                } else {
                    return state.fleetList.Sub;
                }
            }
            return [];
        } else {
            store.dispatch('getFleetList');
        }
    },
    parentFleetId: (state) => {
        if (state.fleetList) {
            return state.fleetList.Id;
        } else {
            store.dispatch('getFleetList');
        }
    },
    unassignedVehicleList: (state) => (sort = false) => {
        if (state.unassignedVehicleList) {
            if (sort) {
                return state.unassignedVehicleList.sort(nameSort)
            } else {
                return state.unassignedVehicleList;
            }
        }
        return null;
    },
    getVehicleSearchKey: state => {
        return state.vehicleSearchKey;
    },
    getFleetSort: state => {
        return state.fleetListCtx ? state.fleetListCtx.fleetSort : null;
    },
    getVehiclesSort: state => id => {
        var sortData = state.fleetListCtx.vehiclesSort.filter((item) => {
            if (item.id === id)
                return true;
            return false
        });
        return sortData.length > 0 ? sortData[0].sort : null;
    },
    getKeepAlivePage:state => {
        return state.keepAlivePage;
    }

};

function getDriversNumber(vehicles) {
    var uniqueDriverIds = [];
    for (var i = 0; i < vehicles.length; i++) {
        if (vehicles[i].DriverIds)
            uniqueDriverIds = concatUnique(uniqueDriverIds, (vehicles[i].DriverIds));
    }
    return uniqueDriverIds.length;
}

const mutations = {
    updateFleetList: function (state, fleetList) {
        state.fleetList = fleetList;
    },
    updateUnassignedVehicleList: function (state, listData) {
        state.unassignedVehicleList = listData;
    },
    setManagerList: function (state, list) {
        state.managerList = list;
    },
    moveVehicleBetweenFleetTreeData(state, data) {
        var vehicles = data.vehicles;
        var fleets = state.fleetList.Sub;
        for (var i = 0; i < vehicles.length; i++) {
            if (vehicles[i].FleetId == state.fleetList.Id) {
                state.fleetList.Vehicles = state.fleetList.Vehicles.filter(item => item.Id !== vehicles[i].Id)
            } else {
                for (var j = 0; j < fleets.length; j++) {
                    if (fleets[j].Id == vehicles[i].FleetId) {
                        fleets[j].Vehicles = fleets[j].Vehicles.filter(item => item.Id !== vehicles[i].Id);
                    }
                }
            }

            if (data.destFleetId === state.fleetList.Id || data.destFleetId == "") {
                vehicles[i].FleetId = state.fleetList.Id;
                if (!state.fleetList.Vehicles) {
                    state.fleetList.Vehicles = [];
                }
                state.fleetList.Vehicles = state.fleetList.Vehicles.concat(data.vehicles);
            } else {
                for (var k = 0; k < fleets.length; k++) {
                    if (fleets[k].Id == data.destFleetId) {
                        vehicles[i].FleetId = fleets[k].Id;
                        fleets[k].Vehicles = fleets[k].Vehicles ? fleets[k].Vehicles.concat(data.vehicles) : [].concat(data.vehicles);
                    }
                }
            }
        }
    },
    setFleetListCtx: function (state, ctx) {
        state.fleetListCtx = ctx;
    },
    setFleetSort: function (state, sort) {
        state.fleetListCtx.fleetSort = sort;
    },
    setVehiclesSort: function (state, sortData) {
        let i = 0;
        for (i = 0; i < state.fleetListCtx.vehiclesSort.length; i++) {
            if (state.fleetListCtx.vehiclesSort[i].id == sortData.id) {
                state.fleetListCtx.vehiclesSort[i].sort = sortData.sort;
                break;
            }
        }
        if (i == state.fleetListCtx.vehiclesSort.length)
            state.fleetListCtx.vehiclesSort.push(sortData);

        console.log(state.fleetListCtx.vehiclesSort);

    },
    setVehicleSearchKey: function (state, key) {
        state.vehicleSearchKey = key;
    },
    setKeepAlivePage: function (state, page) {
        if (page) {
            if (!state.keepAlivePage.includes(page)) {
                state.keepAlivePage.push(page)
            }
        } else {
            state.keepAlivePage = [];
        }
    }

};


const actions = {
    getManagerList: function (context) {
        ServerApi.user.userList((data) => {
            context.commit("setManagerList", data)
        })
    },
    getFleetList: function (context) {
        return new Promise((resolve, reject) => {
            ServerApi.fleet.fleetList((data) => {
                context.commit("updateFleetList", data);
                resolve();
            })
        })
    },
    getUnassignedVehicleList: function (context) {
        ServerApi.vehicles.getUnassignedList((data) => {
            context.commit("updateUnassignedVehicleList", data)
        })
    }
};

export default {
    state,
    mutations,
    actions,
    getters
}
