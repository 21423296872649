
export default {
    setCurrentUser(state, user) {
        state.currentUserData = user;
    },
    touchSessionTimer(state, timer) {
        state.touchSessionTimer = timer;
    },
    setCompanyInfo(state, info) {
        state.companyInfo = info;
    },
    setAxiosCount(state,type){
        if (type === 'increase'){
            state.axiosCount++;
        }else if (type === 'decrease'){
            if (state.axiosCount > 0){
                state.axiosCount--;
            }else {
                state.axiosCount = 0;
            }
        }else if (type === 'reset'){
            state.axiosCount = 0;
        }
        state.loading = state.axiosCount > 0;
    },
    addLoading(state) {
        state.loading = true;
    },
    closeLoading(state) {
        state.loading = false;
    },
    clearAxiosRequest(state, callback) {
        let pending = state.axiosPendingRequests;
        for (let p  in pending) {
            console.log("cancel request:" + pending[p].url);
            pending[p].cancel("cancel");
        }
        state.axiosPendingRequests = [];
        if (state.axiosPendingRequests.length == 0) {
            if (callback)
                callback();
        }
        this.commit('closeLoading');
    },
    setCurrentOnRoadTreeNodeId(state,val){
        state.CurrentOnRoadTreeNodeId = val;
    },
    setMenuCollapse(state,val){
        state.menuCollapse = val;
    },
}
