import store from "../../store/index";
import moment from 'moment';

const DATE_FORMAT = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm:ss';
const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

class DateFormat {
    constructor(date) {
        this.moment = date ? new moment(date) : new moment();
    }
    setTimezone(timezone) {
        if (timezone)
            this.moment.tz(timezone);
        return this;
    }
    format(format) {
        if (format)
            return this.moment.format(format);
        return this.moment.format();
    }
}

const date = (timestamp = null, format = null, timezone = null) => {
    if (!format) format = DATE_FORMAT;
    if (!timezone) timezone = store.getters.timezone;
    return new DateFormat(timestamp * 1000)
        .setTimezone(timezone)
        .format(format);
};
const time = (timestamp = null, format = null, timezone = null) => {
    if (!format) format = TIME_FORMAT;
    if (!timezone) timezone = store.getters.timezone;
    return new DateFormat(timestamp * 1000)
        .setTimezone(timezone)
        .format(format);
};
const dateTime = (timestamp = null, format = null, timezone = null) => {
    if (!format) format = DATE_TIME_FORMAT;
    if (!timezone) timezone = store.getters.timezone;
    return new DateFormat(timestamp * 1000)
        .setTimezone(timezone)
        .format(format);
};

export default {
    date, time, dateTime, DATE_TIME_FORMAT, DATE_FORMAT, TIME_FORMAT
}
