'use strict'

const config = require('./config.js')

const getConfigValueFromCompany = (company, ...rest) => {
    if (!company || rest.length === 0 || !config[company]) {
        return undefined
    }
    let tmpValue = undefined
    rest.forEach((key, index) => {
        if (index === 0) {
            tmpValue = config[company][key]
        } else if (tmpValue !== undefined) {
            tmpValue = tmpValue[key]
        }
    })
    return tmpValue === '' || tmpValue === null ? undefined : tmpValue
}

const getConfigValue = (...rest) => {
    const company = process.env.VUE_APP_CUSTOMER_COMPANY
    return getConfigValueFromCompany(company, ...rest)
}

class CustomerConfig {
    /**
     * 获取站点的名称title
     */
    static getTitle (company) {
        return getConfigValueFromCompany(company, 'title')
    }

    /**
     * 获取站点的favicon.ico
     */
    static getFavicon (company) {
        return getConfigValueFromCompany(company, 'favicon')
    }

    /**
     * 获取登录页的Logo
     */
    static getLoginLogo (value) {
        const cfgValue = getConfigValue('loginPage', 'logo')
        return cfgValue ?? value
    }

    /**
     *  获取登录页的隐私策略的展示状态
     */
    static getLoginPrivacyPolicyShow (value) {
        const cfgValue = getConfigValue('loginPage', 'privacyPolicy', 'show')
        return cfgValue ?? value
    }

    /**
     * 获取登录页的Android展示状态
     */
    static getLoginAndroidShow (value) {
        const cfgValue = getConfigValue('loginPage', 'appStoreAndroid', 'show')
        return cfgValue ?? value
    }
        
    /**
     * 获取登录页的Android下载二维码
     */
    static getLoginAndroidQRCode (value) {
        const cfgValue = getConfigValue('loginPage', 'appStoreAndroid', 'qrcodePath')
        return cfgValue ?? value
    }

    /**
     * 获取登录页的Android展示状态
     */
     static getLoginIOSShow (value) {
        const cfgValue = getConfigValue('loginPage', 'appStoreIOS', 'show')
        return cfgValue ?? value
    }

    /**
     * 获取登录页的IOS下载二维码
     */
    static getLoginIOSQRCode (value) {
        const cfgValue = getConfigValue('loginPage', 'appStoreIOS', 'qrcodePath')
        return cfgValue ?? value
    }

    /**
     * 获取登录页的欢迎语
     */
    static getLoginWelcome (value, lang) {
        const cfgValue = getConfigValue('loginPage', 'welcome', lang)
        return cfgValue ?? value
    }

    /**
     * 获取登录页的版权
     */
    static getLoginCopyright (value, lang) {
        const cfgValue = getConfigValue('loginPage', 'copyright', lang)
        return cfgValue ?? value
    }

    /**
     * 获取管理页面顶部导航左侧的Logo
     */
    static getHeaderLogo (value) {
        const cfgValue = getConfigValue('header', 'logo')
        return cfgValue ?? value
    }

    /**
     * 获取管理页面顶部导航左侧的应用名称
     */
    static getHeaderAppName (value, lang) {
        const cfgValue = getConfigValue('header', 'appName', lang)
        return cfgValue ?? value
    }

    /**
     * 获取管理页面左侧导航底部的版权名称
     */
    static getLeftNavButtomCopyright (value, lang) {
        const cfgValue = getConfigValue('leftNav', 'copyright', lang)
        return cfgValue ?? value
    }

    /**
     * 获取管理页面左侧导航底部的logo
     */
    static getLeftNavButtomLogo (value) {
        const cfgValue = getConfigValue('leftNav', 'logo')
        return cfgValue ?? value
    }
}

class CustomerConfigViewVehicle {
    static isShowTrialPeriodInformation (defaultValue) {
        const cfgValue = getConfigValue('viewVehicle', 'trialPeriodInformation', 'show')
        return cfgValue ?? defaultValue
    }

    static isShowPeriodInformation (defaultValue) {
        const cfgValue = getConfigValue('viewVehicle', 'periodInformation', 'show')
        return cfgValue ?? defaultValue
    }

    static getPeriodInformationTitle (lang) {
        return getConfigValue('viewVehicle', 'periodInformation', 'title', lang)
    }

    static getPeriodInformationRenewTime (lang) {
        return getConfigValue('viewVehicle', 'periodInformation', 'renewTime', lang)
    }
}

class CustomerConfigVehicleList {
    static isShowKVSTime (defaultValue) {
        const cfgValue = getConfigValue('vehicleList', 'titleKVSTime', 'show')
        return cfgValue ?? defaultValue
    }

    static isShowTrialTime (defaultValue) {
        const cfgValue = getConfigValue('vehicleList', 'titleTrialTime', 'show')
        return cfgValue ?? defaultValue
    }

    static isShowRenewDate (defaultValue) {
        const cfgValue = getConfigValue('vehicleList', 'titleRenewDate', 'show')
        return cfgValue ?? defaultValue
    }

    static getRenewDateTitle (lang) {
        return getConfigValue('vehicleList', 'titleRenewDate', 'text', lang)
    }
}

class CustomerConfigAddVehiclePopWindow {
    static getTitleText (defaultValue, lang) {
        const open = getConfigValue('addVehiclePopWindow', 'title', 'open')
        if (!open) {
            return defaultValue
        }
        const text = getConfigValue('addVehiclePopWindow', 'title', 'text', lang)
        return text ?? ''
    }
        
    static getDescriptionText (defaultValue, lang) {
        const open = getConfigValue('addVehiclePopWindow', 'description', 'open')
        if (!open) {
            return defaultValue
        }
        const text = getConfigValue('addVehiclePopWindow', 'description', 'text', lang)
        return text ?? ''
    }

    static getDevicePrefixText (defaultValue, lang) {
        const open = getConfigValue('addVehiclePopWindow', 'devicePrefix', 'open')
        if (!open) {
            return defaultValue
        }
        const text = getConfigValue('addVehiclePopWindow', 'devicePrefix', 'text', lang)
        return text ?? ''
    }
}

module.exports.CustomerConfig = CustomerConfig
module.exports.CustomerConfigViewVehicle = CustomerConfigViewVehicle
module.exports.CustomerConfigVehicleList = CustomerConfigVehicleList
module.exports.CustomerConfigAddVehiclePopWindow = CustomerConfigAddVehiclePopWindow

module.exports.isCompanyIdValid = value => !!config[value]