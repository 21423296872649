export default {
    NameSort: (valueA, valueB) => {
        valueA = valueA.trim();
        valueB = valueB.trim();
        if (valueB === valueA) return 0;
        return sort(valueA, valueB);

        function sort(strA, strB) {
            if (strA === "") return -1;
            if (strB === "") return 1;
            let charA = strA[0],
                charB = strB[0],
                typeA = getCharTypeValue(charA),
                typeB = getCharTypeValue(charB);
            if (typeA > typeB) return 1;
            else if (typeA < typeB) return -1;
            else {
                switch (typeA) {
                    case 0: //空格
                        break;
                    case 1: //字母
                        let newCharA = charA.toLowerCase(),
                            newCharB = charB.toLowerCase();
                        if (newCharA > newCharB) return 1;
                        else if (newCharA < newCharB) return -1;
                        else if (charA > charB) return 1;
                        else if (charA < charB) return -1;
                        else { //charA == charB
                            break;
                        }
                    case 2://数字
                    case 4://其他字符
                    case 5:
                        if (charA > charB) return 1;
                        else if (charA < charB) return -1;
                        else { //charA == charB
                            break;
                        }
                    case 3://汉字
                        let compare = charA.localeCompare(charB, 'zh-CN');
                        if (compare !== 0)
                            return compare;
                        else
                            break;
                }
                return sort(strA.substring(1), strB.substring(1)); // sort 子串
            }
        }

    }
}


// 检测是否为中文，true表示是中文，false表示非中文
function isChinese(str) {
    // 中文万国码正则
    return /[\u4E00-\u9FCC\u3400-\u4DB5\uFA0E\uFA0F\uFA11\uFA13\uFA14\uFA1F\uFA21\uFA23\uFA24\uFA27-\uFA29]|[\ud840-\ud868][\udc00-\udfff]|\ud869[\udc00-\uded6\udf00-\udfff]|[\ud86a-\ud86c][\udc00-\udfff]|\ud86d[\udc00-\udf34\udf40-\udfff]|\ud86e[\udc00-\udc1d]/.test(str);
}

function getCharTypeValue(char) {
    if (/^[\s]$/.test(char))
        return 0;
    else if (/^[a-zA-Z]$/.test(char))
        return 1;
    else if (/^[0-9]$/.test(char))
        return 2;
    else if (isChinese(char))
        return 3;
    else if (/^[`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]$/i.test(char))
        return 4;
    else
        return 5;
}

