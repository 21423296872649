let mapKey = (latitude, longitude, language) => {
    return 'GPS:' + latitude + ',' + longitude + 'lan' + language;
};

const state = {
    keyAddressMap: new Map(),
    cnGpsMap: new Map() // 转换后的gps对应原来GPS的map
};

const getters = {
    getAddress: (state) => (gpsInfo) => {
        let key = mapKey(gpsInfo.latitude, gpsInfo.longitude, gpsInfo.language);
        return state.keyAddressMap.get(key) || localStorage.getItem(key);
    },
    getOriGps: (state) => ({latitude, longitude}) => {
        let key = latitude + '-' + longitude;
        return state.cnGpsMap.has(key)
            ? state.cnGpsMap.get(key)
            : {latitude, longitude};
    }
};

const mutations = {
    addAddress(state, gpsInfo) {
        let key = mapKey(gpsInfo.latitude, gpsInfo.longitude, gpsInfo.language);
        state.keyAddressMap.set(key, gpsInfo.address);
        localStorage.setItem(key, gpsInfo.address);
    },
    addCnGps(state, gpsInfo) {
        let {cnLatitude, cnLongitude, oriLatitude, oriLongitude} = gpsInfo;
        let key = cnLatitude + '-' + cnLongitude;
        if (cnLongitude !== oriLongitude || cnLatitude !== oriLatitude)
            state.cnGpsMap.set(key, {latitude: oriLatitude, longitude: oriLongitude});
    }
};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};