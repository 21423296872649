import ServerApi from "@/assets/js/ServerAPI";
import Sort from "../../assets/js/Sort";

const state = {
    driverList: null,
    driverCurrentPage: 1,
    driverListCtx: null,
    driverSearchKey: null
}

const getters = {
    driverList: state => {
        if (state.driverList)
            state.driverList.forEach((item) => {
                item.Name = item.FirstName + " " + item.LastName;
            })
        return state.driverList;
    },
    sortDriverList: state => {
        if (state.driverList) {
            state.driverList.forEach((item) => {
                item.Name = item.FirstName + " " + item.LastName;
            });
            state.driverList.sort((driverA, driverB) => {
                return Sort.NameSort(driverA.Name, driverB.Name);
            })
        }

        return state.driverList;
    },
    getDriverSearchKey: state => {
        return state.driverSearchKey;
    }

}


const mutations = {
    setCurrentVehicle: function (state, data) {
        state.currentVehicle = data;
    },
    updateDriverList: function (state, list) {
        state.driverList = list;
    },
    setCurrentDriverPage: function (state, pageIndex) {
        state.driverCurrentPage = pageIndex;
    },
    setDriverListCtx: function (state, ctx) {
        state.driverListCtx = ctx;
    },
    clearDriverListCtxScrollTop: function (state, ctx) {
        if (state.driverListCtx)
            state.driverListCtx.scrollTop = 0;
    },
    setDriverSearchKey: function (state, key) {
        state.driverSearchKey = key;
    }

}

const actions = {
    getDriverList: function (context) {
        state.driverList = null;
        return new Promise((resolve, reject) => {
            ServerApi.driver.driverList((data) => {
                context.commit("updateDriverList", data);
                resolve();
            })
        });
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
