import axios from 'axios'
import router from '../../router'
import store from '../../store'
import CoordinateCovernt from "@/assets/js/CoordinateConvertUtil";
import {parseVehicleModel} from "./Util";

let GPSDataArr = [];
function transformGPSCoordinate(data) {
    if (data) {
        data.forEach((item) => {
            if (item.Latitude !== undefined && item.Longitude !== undefined && item.Latitude && item.Longitude) {
                let newGps = CoordinateCovernt.gcj_encrypt(item.Latitude, item.Longitude);
                let oriLatitude = item.Latitude,
                    oriLongitude = item.Longitude;
                store.commit('gps/addCnGps', {
                    cnLatitude: newGps.Latitude,
                    cnLongitude: newGps.Longitude,
                    oriLatitude: oriLatitude,
                    oriLongitude: oriLongitude
                });
                item.Latitude = newGps.Latitude;
                item.Longitude = newGps.Longitude;
                item.oriLatitude = oriLatitude;
                item.oriLongitude = oriLongitude;
            }
            //为getRtcCalledList接口做的特殊处理
            if (item.Logs !== undefined && item.Logs.length > 0) {
                transformGPSCoordinate(item.Logs)
            }
        })
    }
    return data;
}

/**
 *
 * @param totalCount 需要发起请求组的次数
 * @param totalRequest 总请求数组
 * @param sliceCount 每一个请求组的个数
 * @param callback 请求组完成后的回调
 */
function getTripData(totalCount,totalRequest,sliceCount,callback) {
    let requestArr = [];
    GPSDataArr = [];//清空数据
    if (totalCount === 0) {
        let tempRequestArr = [];
        //请求数在限定单次最大请求数以内
        totalRequest.forEach(item => {
            tempRequestArr.push(new Promise(resolve => {
                axios(item, {method: 'get'}).then(data => resolve(data));
            }))
        });
        Promise.all(tempRequestArr).then(gpsList => {
            let allGPS = [],GPSArr;
            gpsList.filter(item => {
                try {
                    if (typeof item == "object") {
                        return true;
                    }
                } catch (e) {
                }
                return false;
            }).forEach(gps => allGPS = allGPS.concat(gps));
            GPSArr = transformGPSCoordinate(allGPS);
            GPSDataArr = GPSDataArr.concat(GPSArr);//接收数据
            callback(GPSDataArr)
        })
    }else {
        for (let i = 0; i < totalCount; i++) {
            requestArr.push(totalRequest.slice(sliceCount*i,sliceCount*(i+1)))
        }
        requestTripData(requestArr,0,()=>{
            callback(GPSDataArr)
        })
    }

}

function requestTripData(requestArr,index,callback) {
    if (requestArr[index]) {
        let tempRequestArr = [];
        requestArr[index].forEach(item => {
            tempRequestArr.push(new Promise(resolve => {
                axios(item, {method: 'get'}).then(data => resolve(data));
            }))
        });
        Promise.all(tempRequestArr).then(gpsList => {
            let allGPS = [];
            let GPSArr;
            gpsList.filter(item => {
                try {
                    if (typeof item == "object") return true;
                } catch (e) {
                }
                return false;
            }).forEach(gps => allGPS = allGPS.concat(gps));
            GPSArr = transformGPSCoordinate(allGPS);
            GPSDataArr = GPSDataArr.concat(GPSArr);//接收数据
            requestTripData(requestArr, ++index,callback);//递归调用
        })
    }else {
        if (callback)
            callback();
    }

}

export default {
    account: {
        password: function (oldPassword, newPassword, callback, errorCallback) {
            axios.post("/account/password", {
                OldPassword: oldPassword,
                Password: newPassword
            }).then((data) => {
                if (callback)
                    callback(data);
            }).catch(error => {
                if (errorCallback)
                    errorCallback(error);
            })
        }
    },

    auth: {
        login: function (name, password, callback, errorCallback) {
            axios.post("/auth/login", {
                LoginId: name, Password: password
            }).then((data) => {
                sessionStorage.setItem('token', data.token);
                if (data.user.TimeZone)
                    sessionStorage.setItem('timezone', data.user.TimeZone);
                store.commit('setCurrentUser', data.user);
                if (callback)
                    callback(data);
            }).catch(error => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        logout(callback, errorCallback) {
            axios({
                url: "/auth/logout",
                method: 'get'
            }).then((data) => {
                    sessionStorage.removeItem('token');
                    sessionStorage.removeItem('timezone');
                    store.commit('setCurrentUser', null);
                    store.commit('setCompanyInfo', null);
                    if (callback)
                        callback(data);
                    router.push("/login");
                    let sessionTouchTimer = store.getters.touchSessionTimer;
                    console.log(sessionTouchTimer);
                    if (sessionTouchTimer) {
                        clearInterval(sessionTouchTimer);
                    }
                }
            ).catch((error) => {
                if (errorCallback)
                    errorCallback(error)
            })
        },
        touch() {
            axios({
                url: "/auth/touch",
                method: 'GET'
            })
        }
    },
    user: {
        getUserInfo(callback, errorCallback) {
            axios({
                url: "/user",
                method: 'GET',
                data: {}
            }).then((data) => {
                store.commit('setCurrentUser', data);
                if (callback)
                    callback(data);

            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error)
            })
        },
        userList(callback, errorCallback) {
            axios({
                url: "/userList",
                method: 'POST',
            }).then((data) => {
                if (callback)
                    callback(data);

            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        editUser(id, editInfo, callback, errorCallback) {
            axios({
                url: "/user/" + id,
                method: 'PUT',
                data: editInfo
            }).then((data) => {
                if (callback)
                    callback(data);

            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        }

    },
    company: {
        getCompany: function (callback, errorCallback) {
            axios({
                url: "/company",
                method: 'GET',
            }).then((data) => {
                let companyLocation = {lng: data.Longitude, lat: data.Latitude};
                localStorage.setItem("companyLocation", JSON.stringify(companyLocation));
                store.commit("setCompanyInfo", data);
                if (callback)
                    callback(data);
            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        updateCompany: function (companyInfo, callback, errorCallback) {
            axios({
                url: "/company",
                method: 'PUT',
                data: companyInfo
            }).then((data) => {
                let companyInfo = store.getters.companyInfo;
                if (companyInfo) {
                    let newCompanyInfo = Object.assign({}, companyInfo);
                    Object.getOwnPropertyNames(data).forEach(key => {
                        newCompanyInfo[key] = data[key];
                    });
                    store.commit("setCompanyInfo", newCompanyInfo);
                }
                if (callback)
                    callback(data);

            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error)
            })
        },
        updateFirmware: function (data, callback, errorCallback) {
            let {mainModel, version} = data;
            let params = {MainModel: mainModel, Version: version};
            axios({
                url: "/company/firmware",
                method: 'PUT',
                data: params
            }).then((data) => {
                if (callback)
                    callback(data);
            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error)
            })
        },
    },
    fleet: {
        fleetList: function (callback, errorCallback) {
            axios({
                url: "/fleet/list",
                method: 'post',
                data: {
                    Id: ""
                }
            }).then((data) => {
                store.commit("updateFleetList", data);
                if (callback)
                    callback(data);

            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        getFleetData(fleetId, callback, errorCallback) {
            axios({
                url: "/fleet/list",
                method: 'post',
                data: {
                    Id: fleetId
                }
            }).then((data) => {
                if (callback)
                    callback(data);

            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        addFleet: function (fleetInfo, callback, errorCallback) {
            axios({
                url: "/fleet",
                method: 'POST',
                data: fleetInfo
            }).then((data) => {
                this.fleetList();
                if (callback)
                    callback(data);

            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        editFleet: function (fleetInfo, callback, errorCallback) {
            axios({
                url: "/fleet",
                method: 'PUT',
                data: fleetInfo
            }).then((data) => {
                this.fleetList();
                if (callback)
                    callback(data);

            }).catch((error) => {
                if (errorCallback)
                    errorCallback();
            })
        },
        deleteFleet: function (ids, callback, errorCallback) {
            axios({
                url: "/fleet",
                method: 'DELETE',
                data: {
                    Ids: ids
                }
            }).then((data) => {
                if (data.Result === 'ok') {
                    this.fleetList();
                    if (callback)
                        callback(data);
                }

            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        colorList: function (callback) {
            axios({
                url: "/fleet/colorList",
                method: 'Get'
            }).then((data) => {
                if (callback)
                    callback(data);

            }).catch(() => {

            })
        }

    },
    vehicles: {
        vehicleList: function (callback, errorCallback) {
            axios({
                url: "/vehicle/list",
                method: 'post'
            }).then((data) => {
                store.commit("updateVehicleList", data);
                if (callback)
                    callback(data);
            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        getUnassignedList: function (callback, errorCallback) {
            axios({
                url: "/vehicle/unassignedList",
                method: 'GET'
            }).then((data) => {
                if (callback)
                    callback(data);
            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        getVehicle: function (id, callback, errorCallback) {
            axios({
                url: "/vehicle?Id=" + id,
                method: 'Get'
            }).then((data) => {
                let vehicle = parseVehicleModel(data);
                if (callback)
                    callback(vehicle);
            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error)
            })
        },
        addVehicle: function (vehicleInfo, callback, errorCallback) {
            axios({
                url: "/vehicle",
                method: 'POST',
                data: vehicleInfo
            }).then((data) => {

                if (callback)
                    callback(data);

            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        editVehicle: function (vehicleInfo, callback, errorCallback) {
            axios({
                url: "/vehicle",
                method: 'PUT',
                data: vehicleInfo
            }).then((data) => {
                if (callback)
                    callback(data);
            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        deleteVehicle: function (id, callback, errorCallback) {
            axios({
                url: "/vehicle",
                method: 'DELETE',
                data: {
                    Ids: id
                }
            }).then((data) => {
                if (data.Result === 'ok') {
                    if (callback)
                        callback(data);
                }
            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        }
    },
    driver: {
        driverList: function (callback, errorCallback) {
            axios({
                url: "/driver/list",
                method: 'POST',
            }).then((data) => {
                store.commit("updateDriverList", data);
                if (callback)
                    callback(data);

            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        getDriveData(driverId, callback, errorCallback) {
            axios({
                url: "/driver?Id=" + driverId,
                method: 'GET',
            }).then((data) => {
                if (callback)
                    callback(data);

            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        addDriver(driverInfo, callback, errorCallback) {
            axios({
                url: "/driver",
                method: 'POST',
                data: {
                    FirstName: driverInfo.FirstName,
                    LastName: driverInfo.LastName,
                    License: driverInfo.License,
                    DateJoined: driverInfo.DateJoined,
                    CellPhone: driverInfo.CellPhone,
                    Contact: driverInfo.Contact ? driverInfo.Contact.trim().resetBlank() : null,
                    ContactCellPhone: driverInfo.ContactCellPhone,
                    Remark: driverInfo.Remark,
                    addVehicleIds: driverInfo.Vehicles ? driverInfo.Vehicles.map((item) => {
                        return item.Id
                    }) : []
                }
            }).then((data) => {
                if (callback)
                    callback(data);

            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        updateDriver(updateInfo, callback, errorCallback) {
            axios({
                url: "/driver",
                method: 'PUT',
                data: updateInfo
            }).then((data) => {
                if (callback)
                    callback(data);

            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        },
        deleteDriver(ids, callback, errorCallback) {
            axios({
                url: "/driver",
                method: 'DELETE',
                data: {
                    Ids: ids
                }
            }).then((data) => {
                if (callback)
                    callback(data);

            }).catch((error) => {
                if (errorCallback)
                    errorCallback(error);
            })
        }
    },
    trip: {
        summary: function (params, callback, errorCallback) {
            let paramsData = {
                "StartDate": params.startDate,
                "EndDate": params.endDate,
                "tz": store.getters['timezone'],
            };
            if (params.driverId || params.default === 0)
                paramsData.DriverId = params.driverId;
            if (params.vehicleId || params.vehicleId === 0)
                paramsData.VehicleId = params.vehicleId;
            if (params.fleetId || params.fleetId === 0)
                paramsData.FleetId = params.fleetId;
            axios({
                url: "/trip/summary",
                method: 'POST',
                data: paramsData
            }).then((data) => {
                if (callback)
                    callback(data);
            }).catch(() => {
                if (errorCallback)
                    errorCallback();
            })
        },
        driver: function (params, callback, errorCallback) {
            let type = 'All';
            if (params.type === 'Date') type = 'Date';
            axios({
                url: "/trip/driver",
                method: 'POST',
                data: {
                    "DriverId": params.driverId,
                    "StartDate": params.startDate,
                    "EndDate": params.endDate,
                    "Type": type,
                    "tz": store.getters['timezone'],
                }
            }).then((data) => {
                data.Data = transformGPSCoordinate(data.Data);
                if (callback)
                    callback(data);
            }).catch(() => {
                if (errorCallback)
                    errorCallback();
            })
        },
        fleet: function (params, callback, errorCallback) {
            let type = 'All';
            if (params.type === 'Date') type = 'Date';
            axios({
                url: "/trip/fleet",
                method: 'POST',
                data: {
                    "FleetId": params.fleetId,
                    "StartDate": params.startDate,
                    "EndDate": params.endDate,
                    "Type": type,
                    "tz": store.getters['timezone'],
                }
            }).then((data) => {
                data.Data = transformGPSCoordinate(data.Data);
                if (callback)
                    callback(data);
            }).catch(() => {
                if (errorCallback)
                    errorCallback();
            })
        },
        vehicle: function (params, callback, errorCallback) {
            let type = 'All';
            if (params.type === 'Date') type = 'Date';
            axios({
                url: "/trip/vehicle",
                method: 'POST',
                data: {
                    "VehicleId": params.vehicleId,
                    "StartDate": params.startDate,
                    "EndDate": params.endDate,
                    "Type": type,
                    "tz": store.getters['timezone'],
                }
            }).then((data) => {
                data.Data = transformGPSCoordinate(data.Data);
                if (callback)
                    callback(data);
            }).catch(() => {
                if (errorCallback)
                    errorCallback();
            })
        },
        detail: function (tripId, callback, errorCallback) {
            axios({
                url: "/trip/detail",
                method: 'POST',
                data: {
                    TripId: tripId
                }
            }).then((data) => {
                if (typeof data.GPS[0] === 'string') {
                    store.commit("addLoading");
                    let requestArr = [],requestCount,maxRequest = 20;
                    data.GPS.forEach(url => {
                        requestArr.push(url);
                    });
                    requestCount = Math.ceil(requestArr.length / maxRequest);
                    getTripData(requestCount, requestArr, maxRequest, gpsData => {
                        data.GPS = gpsData;
                        if (callback)
                            callback(data);
                        store.commit("closeLoading");
                    });
                } else {
                    data.GPS = transformGPSCoordinate(data.GPS);
                    if (callback)
                        callback(data);
                }
            }).catch((e) => {
                if (errorCallback)
                    errorCallback(e);
            })
        },
        event: function (params, callback, errorCallback) {
            let type = 'All', eventType = '';
            if (params.type === 'Date') type = 'Date';
            switch (params.event) {
                case 'Collision':
                    eventType = 'Collision';
                    break;
                case 'DriverAlert':
                    eventType = 'DriverAlert';
                    break;
                case 'ParkingCollision':
                    eventType = 'ParkingCollision';
                    break;
                case 'Unpermitted':
                    eventType = 'Unpermitted';
                    break;
                case 'All':
                    eventType = 'All';
                    break;
                default:
                    eventType = 'All';
                    break;
            }
            let reqParams = {
                Type: type,
                Event: eventType,
                StartDate: params.startDate,
                EndDate: params.endDate,
                tz: store.getters['timezone'],
            };
            if (params.vehicleId)
                reqParams.VehicleId = params.vehicleId;
            if (params.fleetId)
                reqParams.FleetId = params.fleetId;
            axios({
                url: "/trip/event",
                method: 'POST',
                data: reqParams
            }).then((data) => {
                data.Data = transformGPSCoordinate(data.Data);
                if (callback)
                    callback(data);
            }).catch(() => {
                if (errorCallback)
                    errorCallback();
            })
        },
        eventTripId: function (params, callback, errorCallback) {
            let type = 'All';
            if (params.type === 'Date') type = 'Date';
            axios({
                url: "/trip/event",
                method: 'POST',
                data: {
                    Event: 'All',
                    Type: type,
                    TripId: params.tripId
                }
            }).then((data) => {
                data.Data = transformGPSCoordinate(data.Data);
                if (callback)
                    callback(data);
            }).catch(() => {
                if (errorCallback)
                    errorCallback();
            })
        },
        hcall: (params, callback, errorCallback) => {
            let {tripId} = params;
            axios({
                url: "/trip/hcall",
                method: 'POST',
                data: {
                    TripId: tripId
                }
            }).then(data => {
                let gpsTransform = transformGPSCoordinate(data.Data);
                if (callback)
                    callback(gpsTransform);
            }).catch(error => {
                if (errorCallback)
                    errorCallback(error);
            })
        }
    },
    dashboard: {
        fleetHours: function (params, callback) {
            axios({
                url: "/dashboard/fleetHoursDetail",
                method: 'POST',
                data: {
                    StartDate: params.StartDate,
                    EndDate: params.EndDate
                }
            }).then((data) => {
                if (callback)
                    callback(data);
            }).catch(() => {
            })
        },
        fleetDistance: function (params, callback) {
            axios({
                url: "/dashboard/fleetDistanceDetail",
                method: 'POST',
                data: {
                    StartDate: params.StartDate,
                    EndDate: params.EndDate
                }
            }).then((data) => {
                if (callback)
                    callback(data);
            }).catch(() => {
            })
        },
        fleetFuelConsumption: function (params, callback) {
            axios({
                url: "/dashboard/fleetFuelConsumptionDetail",
                method: 'POST',
                data: {
                    StartDate: params.StartDate,
                    EndDate: params.EndDate
                }
            }).then((data) => {
                if (callback)
                    callback(data);
            }).catch(() => {
            })
        },
        fleetAlerts: function (params, callback) {
            axios({
                url: "/dashboard/fleetAlertsDetail",
                method: 'POST',
                data: {
                    StartDate: params.StartDate,
                    EndDate: params.EndDate
                }
            }).then((data) => {
                if (callback)
                    callback(data);
            }).catch(() => {
            })
        },
        parkingAlerts: function (params, callback) {
            axios({
                url: "/dashboard/fleetParkingAlertsDetail",
                method: "POST",
                data: {
                    StartDate: params.StartDate,
                    EndDate: params.EndDate
                }
            }).then((data) => {
                if (callback)
                    callback(data)
            }).catch(() => {
            })
        },
        fleetDriverScore: function (params, callback) {
            axios({
                url: "/dashboard/fleetDriverScoreDetail",
                method: 'POST',
                data: {
                    StartDate: params.StartDate,
                    EndDate: params.EndDate
                }
            }).then((data) => {
                if (callback)
                    callback(data);
            }).catch(() => {
            })
        },
        driverScoreByDriver: function (params, callback) {
            axios({
                url: "/dashboard/driverScoreByDriver",
                method: 'POST',
                data: {
                    StartDate: params.StartDate,
                    EndDate: params.EndDate
                }
            }).then((data) => {
                if (callback)
                    callback(data);
            }).catch(() => {
            })
        },
        driverScoreByVehicle: function (params, callback) {
            axios({
                url: "/dashboard/driverScoreByVehicle",
                method: 'POST',
                data: {
                    StartDate: params.StartDate,
                    EndDate: params.EndDate
                }
            }).then((data) => {
                if (callback)
                    callback(data);
            }).catch(() => {
            })
        },
    },
    device: {
        command: function (params, callback, errorCallback) {
            let reqParams = {
                DeviceId: params.deviceId,
                Type: params.type,
                Command: params.command,
            };
            if (params.cameras) {
                reqParams.Cameras = params.cameras;
            }
            if (params.cameraId !== undefined && !params.cameras) {
                reqParams.Cameras = [{Id: params.cameraId + ""}];
            }
            axios({
                url: "/device/command",
                method: 'POST',
                timeout: 30000,
                data: reqParams
            }).then((data) => {
                if (callback)
                    callback(data);
            }).catch((data) => {
                if (errorCallback)
                    errorCallback(data);
            })
        },
        kvsttl: function (params) {
            return axios({
                url: "/device/kvsttl",
                method: "post",
                data: {"DeviceId": params.deviceId}
            });
        }
    },
    collision: {
        list: function (callback, errorCallback) {
            axios({
                url: "/collision/list",
                method: 'POST',
                data: {}
            }).then((data) => {
                data = transformGPSCoordinate(data);
                store.commit('websocket/updateCollisions', {type: 'list', data});
                if (callback)
                    callback(data);
            }).catch((data) => {
                store.commit('websocket/updateCollisions', {type: 'list', data: null});
                if (errorCallback)
                    errorCallback(data);
            })
        },
        tripList: function (params, callback, errorCallback) {
            axios({
                url: "/collision/list",
                method: 'POST',
                data: {
                    TripId: params.tripId,
                }
            }).then((data) => {
                data = transformGPSCoordinate(data);
                if (callback)
                    callback(data);
            }).catch((data) => {
                if (errorCallback)
                    errorCallback(data);
            })
        },
        parkingAlertList: (params, callback, errorCallback) => {
            axios({
                url: "/collision/list",
                method: 'POST',
                data: {
                    VehicleId: params.vehicleId,
                    ForOffRoad: true
                }
            }).then((data) => {
                data = transformGPSCoordinate(data);
                if (callback)
                    callback(data);
            }).catch((data) => {
                if (errorCallback)
                    errorCallback(data);
            })
        }
    },
    Gps: {
        googleMap(params) {
            let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${params.lat},${params.lng}&key=${params.key}&language=${params.lang}`;
            return axios({
                timeout: 3000,
                url: url,
                method: 'GET'
            });
        }
    },
    webRtcApi: {
        sendChannelNameToServer: function (params, callback, errorCallback) {
            let callId = store.getters['websocket/getRtcCallId'];
            if (callId) {
                params.CallId = callId;
            }
            axios({
                url: '/device/command',
                method: 'POST',
                data: params
            }).then(data => {
                if (params.Type === 'Dial'){
                    store.commit('websocket/setRtcCallId', data.CallId);
                }
                if (callback) {
                    callback(data)
                }
            }).catch(data => {
                if (errorCallback) {
                    errorCallback(data);
                }
            })
        },
        getRtcCalledList: function (params, callback, errorCallback) {
            axios({
                url: '/vehicle/callEvent',
                method: 'POST',
                data: {
                    Type: params.Type
                }
            }).then(data => {
                let newData = transformGPSCoordinate(data);
                if (callback) {
                    callback(newData)
                }
            }).catch(errorData => {
                if (errorCallback) {
                    errorCallback(errorData);
                }
            })
        },
        sendDeviceConnectedMsgToServer: function (param, callback, errorCallback) {
            axios({
                url: '/vehicle/dialStarted',
                method: 'POST',
                data: param
            }).then(data => {
                if (callback) {
                    callback(data)
                }
            }).catch(errorData => {
                if (errorCallback) {
                    errorCallback(errorData);
                }
            })
        },
        uploadRecordToServer: function (params, callback, errorCallback) {
            axios({
                url: '/dial/record',
                method: 'POST',
                data: params
            }).then(data => {
                if (callback) callback(data)
            }).catch(errorData => {
                if (errorCallback) errorCallback(errorData);
            })
        }
    },
    notification: {
        read: (params, callback, errorCallback) => {
            let {id} = params;
            axios({
                url: '/notification/read',
                method: 'PUT',
                data: {
                    'Id': id
                }
            }).then(data => {
                if (callback)
                    callback(data)
            }).catch(errorData => {
                if (errorCallback)
                    errorCallback(errorData);
            })
        }
    },
    credentials: {
        credentials: (params, callback, errorCallback) => {
            axios({
                url: '/credentials',
                method: 'POST',
                data: params
            }).then(data => {
                if (callback)
                    callback(data)
            }).catch(errorData => {
                if (errorCallback)
                    errorCallback(errorData);
            })
        }
    }
}
