import store from '../../store/index'

const mixin = {
    computed: {
        mixin_dateFormat() {
            return store.getters.dateFormat;
        },
        mixin_timeFormat() {
            return store.getters.timeFormat;
        },
        mixin_dateTimeFormat() {
            return store.getters.dateTimeFormat;
        },
        mixin_timezone() {
            return store.getters.timezone;
        }
    }
};

export default mixin;