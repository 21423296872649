/**
 * 修改本配置文件，请同步修改/doc/I18n_setting_map.xlsx文件
 */
const common = {
    singular: { // 单数
        hour: 'Hour',
        driver: '@:way2Call.driver',
        fleet: '@:way2Call.fleet',
        vehicle: '@:way2Call.vehicle',
        alert: '@:userAccounts.alert',
        collisionAlert: 'Collision Alert',
        driverAlert: 'Driver Alert',
        parkingAlert: 'Parking Alert',
        unpermittedDriver: 'Unpermitted Driver',
    },
    plural: { // 复数
        hours: 'Hours',
        drivers: '@:userAccounts.drivers',
        fleets: '@:userAccounts.fleets',
        vehicles: '@:userAccounts.vehicles',
        alerts: '@:alertList.alerts',
        collisionAlerts: 'Collision Alerts',
        driverAlerts: 'Driver Alerts',
        parkingAlerts: 'Parking Alerts',
        unpermittedDrivers: 'Unpermitted Drivers',
    },
    headerTitle: {
        //header左上角功能模块
        dashboard: '@:dashboard.dashboard',
        vehicleManagement: '@:fleet.vehicleManagement',
        addFleet: '@:fleet.addFleet',
        editFleet: '@:fleet.editFleet',
        addVehicle: '@:fleet.addVehicle',
        vehicle: '@:way2Call.vehicle',
        editVehicle: '@:vehicle.editVehicle',
        driverManagement: '@:driver.driverManagement',
        driver: '@:way2Call.driver',
        addDriver: '@:driver.addDriver',
        editDriver: '@:driver.editDriver',
        account: 'Account',
        role: 'Role',
        liveTracking: '@:liveTracking.liveTracking',
        tripHistory: '@:tripHistory.tripHistory',
        settings: '@:settings.settings'
    }
};
const units = {
    hrs: "Hrs",
    hours: "Hour | Hours",
    km: "Km",
    kilometers: "Kilometer | Kilometers",
    mi: "Mi",
    miles: "Mile | Miles",
    l: "L",
    liters: "Liter | Liters",
    gal: "Gal",
    gallons: "Gallon | Gallons",
    t: "T",
    cID: "CID",
    hrsPerTrip: "Hrs / Trip",
    kmPerTrip: "Km / Trip",
    miPerTrip: "Mi / Trip",
    kmPerL: "Km / L",
    mpg: "Mpg",
    kmPerHr: "Km / Hr",
    mph: "Mph",
    in: "in",
    cm: "cm",
    day:"Day | Days"
};
const globalText = {
    VIA_FLEET: "VIA FLEET",
    noData: "No Data",
    unassigned:"Unassigned",
    unknown: "Unknown",
    oK: "OK",
    cancel: "Cancel",
    delete: "Delete",
    save: "Save",
    apply: "Apply",
    all: "All",
    loading: "Loading…",
    uploading: "Uploading",
    fileNotfound: "File not found",
    edit: "Edit",
    select: "Select",
    none: "None",
    aMaximumOf_X_CharactersCanBeUsed: "A maximum of {num} characters can be used ",
    itShouldBeFormedBy_X: "It should be formed by {value}",
    theValueCanHaveOneDecimalPlaceOnly: "The value can have one decimal place only",
    theValueShouldBeBetween_X_And_Y: "The value should be between {min} and {max}",
    mustContainBetween_X_To_Y_Characters: "Must contain between {min} to {max} characters",
    savedSuccessfully: "Saved successfully.",
    failedToSave: "Failed to save.",
    confirmDeletion: "Confirm deletion.",
    deletedSuccessfully: "Deleted successfully.",
    failedToDelete: "Failed to delete.",
    requestTimeout: "Request timeout.",
    timeoutPleaseLoginAgain: "Timeout. Please login again.",
    na:'N/A',
    networkError:'Network Error',
    newText:'New'
};
const popUpMsg = {
    login: {
        loginFailedPleaseTryAgain: "Login failed. Please try again.",
    },
    resetPwd: {
        emailOrUserIdDoesntExist: "Email or user ID doesn't exist. ",
        wrongVerificationCode: "Wrong verification code.",
        yourPasswordHasBeenUpdatedPleaseLogin: "Your password has been updated. Please login.",
        failedToResetPassword: "Failed to reset password.",
    },
    settings:{
        aiSettingChanged:'The new AI settings will be applied to all VIA Mobile360 {deviceModel} devices.'
    },
    account: {
        wrongPassword: "Wrong old password.",
        failedToChangePassword: "Failed to change password.",
    },
    liveTracking: {
        vehicleName_PlateNumber_IsOffRoad: "{vehicleName}({plateNumber}) is off-road.",
        theTrailTimeRemainingFor_VehicleName_PlateNumber_Is_X_Days: "The trail time remaining for {vehicleName} ({plateNumber})  is {day} day(s).",
        theKvsTimeRemainingFor_VehicleName_PlateNumber_Is_X_Seconds: "The KVS time remaining for {vehicleName} ({plateNumber}) is {seconds} seconds.",
        vehicleName_PlateNumber_IsOnRoad: "{vehicleName}({plateNumber}) is on-road.",
        audioInputDeviceIsNotReady: "Audio input device is not ready.",
        cannotReach_DriverName_NowPleaseTryLater: "Cannot reach <strong>{driverName}</strong> now, please try later.",
        connectionTo_DriverName_IsLost: "Connection to <strong>{driverName}</strong> is lost.",
        terminateTheCallTo_DriverNameOfTheCurrentCall_AndCall_DriverNameOfTheNewCall: "Terminate the call to <strong> {currentDriverName} </strong>and call <strong>{newDriverName}</strong> ?",
        driverName_IsBusyNow: "<strong>{DriverName}</strong> is busy now.",
        terminateTheCallTo_DriverNameOfTheCurrentCall_AndLogout: "Terminate the call to {DriverName} and logout？",
    },
    fleet: {
        fleetName_AlreadyExists: "{fleetName} already exists.",
    },
    vehicles: {
        vehicleName_AlreadyExists: "{vehicleName} already exists.",
        thisPlateNumber_PlateNumber_AlreadyExists: "This plate number {plateNumber} already exists.",
        confirmUnregisterationNoMoreDataWillBeSentFromThisVehicle: "Confirm unregisteration. </br>No more data will be sent from this vehicle.",
        unregisteredSuccessfully: "Unregistered successfully.",
        failedToUnregister: "Failed to unregister.",
        seriesPlatform: "VIA Mobile360 Series Platform",
        selectVIAPlatform: "Select which VIA Mobile360 Series Platform will be installed in the vehicle.",
        completeADAS: "Complete ADAS calibration to enable this feature.",
        completeDMS:  "Complete DMS calibration to enable this feature."
    },
    drivers: {
        driverName_AlreadyExists: "{driverName} already exists.",
        thisLicenseNumber_LicenseNumber_AlreadyExists: "This license number {LicenseNumber} already exists.",
        thisLoginId_LoginId_AlreadyExists: "This login ID {loginID} already exists.",
        failedToLoadImageFromCloud: "Failed to load image from cloud.",
    },
    rtcPop: {
        terminateTheCallTo_DriverNameOfTheCurrentCall_AndCall_DriverNameOfTheNewCall: "@:popUpMsg.liveTracking.terminateTheCallTo_DriverNameOfTheCurrentCall_AndCall_DriverNameOfTheNewCall",
        connectionTo_DriverName_IsLost: "@:popUpMsg.liveTracking.connectionTo_DriverName_IsLost"
    }
};
const tips = {
    login: {
        pleaseInputYourEmailOrUserId: "Please input your Email or User ID"
    },
    account:{
        pleaseInputAContactNumber:   "Please input the contact number",
        pleaseInputAddress:   "Please input the address",
    },
    resetPwd: {
        pleaseInputThePassword: "Please input the password",
        pleaseInputTheVeficationCode: "Please input the vefication code",
        pleaseInputTheNewPassword: "Please input the new password",
        theNewPasswordIsTheSameAsTheOldPassword:"The new password is the same as the old password.",
        pleaseInputTheNewPasswordAgain: "Please input the new password again",
        theTwoNewPasswordsAreNotTheSame: "The two new passwords are not the same",
    },
    fleet: {
        pleaseInputAFleetName: "Please input a fleet name"
    },
    vehicles: {
        pleaseInputAVehicleName: "Please input a vehicle name",
        pleaseInputAPlateNumber: "Please input a plate number",
        select: "Select",
        pleaseInputTheFuelTankCapacity: "Please input the fuel tank capacity",
        pleaseSelectVehicleWeight: "Please select vehicle weight",
        pleaseInputTheEngineDisplacement: "Please input the engine displacement",
        theDistanceShouldBeBetween_X_Y: "The distance should be between {min} to {max}",
        pleaseInputTheVehicleHeight: "Please input the vehicle height",
        pleaseCorrectTheVehicleHeight: "Please correct the vehicle height",
        pleaseInputTheVehicleWidth:"Please input the vehicle width",
        pleaseInputTheHoodLength:"Please input the hood length",
        TheValueShouldBeNoMoreThan300:"The value should be no more than 300",
        theAboveFieldsAreRequiredToEnableAiFeatures: "The above fields are required to enable AI features",
        searchVehicleByVehicleNamePlateNumberOrVehicleModel :"Search vehicle by vehicle name, plate number or vehicle model ",
    },
    drivers: {
        pleaseInputAFirstName: "Please input a first name",
        thereIsASpaceInTheFirstNamePleaseDeleteIt: "There is a space in the first name, please delete it",
        pleaseInputALastName: "Please input a last name",
        thereIsASpaceInTheLastNamePleaseDeleteIt: "There is a space in the last name, please delete it",
        pleaseInputAContactNumber: "Please input a contact number",
        pleaseInputTheLicenseNumber: "Please input the license number",
        pleaseInputALoginId: "Please input a login ID",
        thereIsASpaceInTheLoginIdPleaseDeleteIt: "There is a space in the login ID, please delete it",
        searchDriverByDriverNameContactNumberUserIDOrLicenseNumber :"Search driver by driver name, contact number, user ID or license number ",
    },
    imageSizeTips:'The file size should be less than 6MB.'
};
const login = {
    VIAMobile360: "VIA Mobile360",
    welcome: "Welcome to VIA Fleet",
    portalLanguage: "Portal Language",//TODO 英文字段修改，简中和繁中未提供
    alertLanguage: "Alert Language",
    english: "English",
    loginName: "Login Name",
    emailOrUserID: "Email or User ID",
    password: "Password",
    signIn: "Sign in",
    getItOnGooglePlay: "Get it on Google play",
    availableOnTheAppStore: "Available on the App Store",
    copyright: "Copyright © {year} VIA Technologies, Inc. ",
    allRightsReserved: "All Rights Reserved.",
    version: "Version {value}",
    timezone: "Timezone",
    forgotPassword:"Forgot Password?",
    privacyPolicy:"Privacy Policy"
};
const way2Call = {
    way2Call: "2-Way Call",
    notifications: "Notifications",
    missedCalls: "Missed Calls",
    showMsgFor1hour: "This page shows the call requests in the last 1 hour.",
    showMsgFor24hours: "This page shows the call requests in the last 24 hours.",
    vehicle: "Vehicle",
    fleet: "Fleet",
    driver: "Driver",
    time: "Time",
    location: "Location",
    log: "Log",
    logEvent: "Log Event",
    status: "Status",
    newTrip:'New Trip',
    callRequest:'Call Request'
};
const alertList = {
    alerts: "Alerts",
    type: "Type",
    showMsgFor24hours: "This page shows the alerts in the last 24 hours.",
    vehicle: "@:way2Call.vehicle",
    fleet: "@:way2Call.fleet",
    collisionAlert: '@:common.singular.collisionAlert',
    driverAlert: '@:common.singular.driverAlert',
    parkingAlert: '@:common.singular.parkingAlert',
    unpermittedDriver: '@:common.singular.unpermittedDriver',
    location: "@:way2Call.location",
    driver: "@:way2Call.driver",
    time: "@:way2Call.time",
};
const myAccount = {
    myAccount: "My Account",
    company: "Company",
    loginName: "Login Name",
    userID: "User ID",
    name: "Name",
    email: "Email",
    keyContact: "Key Contact",
    contactNumber: "Contact Number",
    address: "Address",
    editAccount: "Edit Account",
    changePassword: "Change Password",
    oldPassword: "Old Password",
    newPassword: "New Password",
    reEnterNewPassword: "Re-enter New Password",
    jobTitle: "Job Title",
};
const settings = {
    settings: "Settings",
    globalSettings: "Global Settings",
    unitSettings: "General Settings",
    unitOfMeasure: "Unit of Measure",
    metric: "Metric",
    imperial: "Imperial",
    USCustomary: "US Customary",
    timeFormat: "Time Format",
    YYYY_MM_DD: "YYYY-MM-DD",
    MM_DD_YYYY: "MM-DD-YYYY",
    DD_MM_YYYY: "DD-MM-YYYY",
    driverRegistrationMethod: "Driver Registration Method",
    driverLoginSettings: "Driver Login Settings",
    loginMethod: "Login Method",
    QRCode: "QR Code",
    QRCodeOrFaceID: "QR Code or Face ID",
    loginScanTime: "Login Scan Time",
    loginPictureDelay: "Login Picture Delay",
    secs: "Secs",
    audioSettings: "Audio Settings",
    tripAudioRecording: "Trip Audio Recording",
    way2CallVolume: "2-Way Call Volume",
    min: "Min",
    max: "Max",
    way2CallRecording: "2-Way Call Recording",
    alertVolume: "Alert Volume",
    videoAlertSettings: "Record Settings",
    alertRecordingDuration: "Alert Recording Duration",
    alertSensitivity: "Alert Sensitivity",
    low: "Low",
    medium: "Medium",
    high: "High",
    deviceSettings: "Device Settings",
    aISettings: "AI Settings",
    ADASAudioAlerts: "ADAS Audio Alerts",
    LDWAudioAlert: "LDW Audio Alert",
    FCWAudioAlert: "FCW Audio Alert",
    FCWAlertSensitivity: "FCW Alert Sensitivity",
    DMS: "DMS ",
    distractedDrivingAudioAlert: "Distracted Driving Audio Alert",
    distractedDrivingAlert: "Distracted Driving Alert",
    driverFatigueAudioAlert: "Driver Fatigue Audio Alert",
    driverFatigueAlert: "Driver Fatigue Alert",
    phoneUsageAudioAlert: "Phone Usage Audio Alert",
    phoneUsageAlert: "Phone Usage Alert",
    smokingAudioAlert: "Smoking Audio Alert",
    smokingAlert: "Smoking Alert",
    applySettings: "Apply Settings",
    selectWhichFleetsToApplyTheChangesTo: "Select which fleets to apply the changes to:",
    selectToOverrideAllSettingsForTheChosenFleets: "Select to override all settings for the chosen fleets.",
    pressOKToApplyTheChanges: 'Press "OK" to apply the changes.',
    firmwareUpgrade: "Firmware Upgrade",
    currentFirmwareVersion: "Current Firmware Version",
    newVersionAvailable: "New Version Available!",
    releaseNotes: "Release Notes",
    upgrade: "Upgrade",
    upgradeFirmware: "Upgrade Firmware",
    pressOKToUpgradeYourMobile360D700Devices: "Press \"OK\" to upgrade your {device} devices.",
    newFirmWareAvailableFor: "New firmware available for {name}.",
    firmwareVersion: "Firmware version",
    upgradeNow: "Upgrade Now",
    version: '@:login.version',
    LDW: "@:tripHistory.LDW ",
    FCW: "@:tripHistory.FCW ",
    driverFatigue: "@:tripHistory.driverFatigue ",
    smoking: "@:tripHistory.smoking ",
    distractedDriving: "@:tripHistory.distractedDriving ",
    phoneUsage: "@:tripHistory.phoneUsage",
    bsdAudioAlert:'BSD Audio Alert',
    GSensorEventSensitivity:'G-Sensor Event Sensitivity',
    GeneralSettings:'General Settings',
    RecordSettings:'Record Settings',
    alertSettings:'Alert Settings',
    parkingAndCollisionSensitivity:'Parking and Collision Alert Sensitivity',
    partner_program:'Partner Program',
    partner_program_content1:'The Partner Feedback Loop enables VIA to receive system critical log files and event videos generated during a customer’s free VIA Fleet Portal trial period in order to sync up customer feedback and continually improve the customer trail run experience on VIA Mobile360 products. The data collected will only be used to fine tune performance of the system software and AI features which will be passed on to customers through future updates.',
    partner_program_content2:'On average data generated per trip is dependent on the hours and events triggered.  An estimated data transferring size is listed below for Mobile360 series product.  The data is passed through the mobile data plan used on the device. If you do not wish to participate in this program you can disable the options below.',
    log_files:'Log Files',
    event_videos:'Event Videos'
};
const billing = {
    billing: "Billing",
};
const userAccounts = {
    userAccounts: "User Accounts",
    users: "Users",
    emailAddress: "Email Address",
    userRole: "User Role",
    fleet: "Fleet",
    addUser: "Add User",
    firstName: "First Name",
    lastName: "Last Name",
    userPermissions: "User Permissions",
    mobile360AppSettings: "Mobile360 App Settings",
    alert: "Alert",
    deviceRegistration: "Device Registration",
    deviceCalibration: "Device Calibration",
    uploadFRPhotos: "Upload FR Photos",
    management: "Management",
    fleets: "Fleets",
    vehicles: "Vehicles",
    drivers: "Drivers",
    edit: "Edit",
    add: "Add",
    roles: "Roles",
    roleName: "Role Name",
    administrator: "Administrator",
    fleetManager: "Fleet Manager",
    purchaser: "Purchaser",
};
const logout = {
    logout: "Logout"
};
const liveTracking = {
    liveTracking: "Live Tracking",
    onRoad: "On-road",
    offRoad: "Off-road",
    vehicleNameOrPlateNumber: "Vehicle name or plate number",
    information: "Information",
    fileNotFound: "File not found",
    liveVideo: "Live Video",
    connecting: "Connecting",
    unableToConnect: "Unable to Connect",
    G4ConnectionLost: "4G connection lost.",
    KVSTimeUsedUp: "KVS time used up.",
    GPS: "GPS ",
    zoomIn: "Zoom in",
    zoomOut: "Zoom out",
    ft: "ft",
    mi: "mi",
    m: "m",
    km: "km",
    unassigned: '@:globalText.unassigned',
    unassignedVehicles: '@:fleet.unassignedVehicles',
    alerts: '@:common.plural.alerts',
    way2Call: '@:way2Call.way2Call',
    collisionAlerts: '@:dashboard.collisionAlerts',
    driverAlerts: '@:dashboard.driverAlert',
    parkingAlerts: '@:dashboard.parkingAlerts',
    unpermittedDrivers: '@:dashboard.unpermittedDriver',
    plateNumber: "@:vehicle.plateNumber",
    fleet: "@:userAccounts.fleet",
};
const dashboard = {
    dashboard: "Dashboard",
    days7: "7 days",
    days30: "30 days",
    month: "12 months",
    january: "January",
    february: "February",
    march: "March",
    april: "April",
    may: "May",
    june: "June",
    july: "July",
    august: "August",
    september: "September",
    october: "October",
    november: "November",
    december: "December",
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",
    sun: "Sun",
    hoursDriven: "Hours Driven",
    distanceDriven: "Distance Driven",
    fuelConsumption: "Fuel Consumption",
    total: "Total",
    avg: "Avg",
    collisionAlerts: "Collision Alert | Collision Alerts",
    parkingAlerts: "Parking Alert | Parking Alerts",
    driverScore: "Driver Score",
    average: "Average",
    totalHoursDriven: "Total Hours Driven",
    totalDistanceDriven: "Total Distance Driven",
    totalFuelConsumption: "Total Fuel Consumption",
    collisionAlert: "Collision Alert",
    driverAlert: "Driver Alert | Driver Alerts",
    parkingAlert: "Parking Alert | Parking Alerts",
    unpermittedDriver: "Unpermitted Driver | Unpermitted Drivers",
    rankingsByVehicle: "Rankings by Vehicle",
    rankingsByDriver: "Rankings by Driver",
    vehicleName: "Vehicle Name",
    driverName: "Driver Name",
    time: "Time",
    distance: "Distance",
    fuel: "Fuel",
    unassigned: '@:globalText.unassigned',
    unassignedVehicles: '@:fleet.unassignedVehicles',
    hours: '@:common.plural.hours',
    jan:'Jan',
    feb:'Feb',
    mar:'Mar',
    apr:'Apr',
    jun:'Jun',
    Jul:'Jul',
    aug:'Aug',
    sep:'Sep',
    oct:'Oct',
    nov:'Nov',
    dec:'Dec',
    newAlert: 'New Alert',
    newCollisionAlert: 'New Collision Alert',
    newDriverAlert: 'New Driver Alert',
    newParkingAlert: 'New Parking Alert'
};
const tripHistory = {
    tripHistory: "Trip History",
    totalTime: "Total Time",
    totalIdleTime: "Total Idle Time",
    distance: "Distance",
    avgFuelConsumption: "Avg Fuel Consumption",
    totalIdleFuelConsumption: "Total Idle Fuel Consumption",
    totalFuelConsumption: "Total Fuel Consumption",
    avgDriverScore: "Avg Driver Score",
    times: "Time | Times",
    driver: "Driver",
    headquarters: "Headquarters",
    gSensorEvents: "G-sensor Events",
    hardRevving: "Hard Revving",
    hardBraking: "Hard Braking",
    hardCornering: "Hard Cornering",
    ADASEvents: "ADAS Events",
    LDW: "LDW",
    FCW: "FCW",
    DMSEvents: "DMS Events",
    driverFatigue: "Driver Fatigue",
    distractedDriving: "Distracted Driving",
    smoking: "Smoking",
    phoneUsage: "Phone Usage",
    trip: "Trip",
    start: "Start",
    end: "End",
    idleTime: "Idle Time",
    avgSpeed: "Avg Speed",
    maxSpeed: "Max Speed",
    way2CallFromDriver: "2-Way Call from Driver",
    way2CallFromHeadquarter: "2-Way Call from Headquarter",
    idleFuelConsumption: "Idle Fuel Consumption",
    searchType: {
        driver: '@:common.singular.driver',
        fleet: '@:common.singular.fleet',
        vehicle: '@:common.singular.vehicle',
        alert: '@:common.singular.alert',
    },
    alertList: {
        collisionAlert: '@:common.singular.collisionAlert',
        driverAlert: '@:common.singular.driverAlert',
        parkingAlert: '@:common.singular.parkingAlert',
        unpermittedDriver: '@:common.singular.unpermittedDriver',
        all: '@:globalText.all',
    },
    alert: '@:common.singular.alert',
    alerts: '@:common.plural.alerts',
    fleetName: '@:fleet.fleetName',
    driverName: '@:dashboard.driverName',
    collisionAlert: '@:common.singular.collisionAlert',
    collisionAlerts: '@:dashboard.collisionAlerts',
    driverAlert: '@:common.singular.driverAlert',
    driverAlerts: '@:dashboard.driverAlert',
    parkingAlerts: '@:dashboard.parkingAlerts',
    parkingAlert: '@:common.singular.parkingAlert',
    unpermittedDriver: '@:common.singular.unpermittedDriver',
    unpermittedDrivers: '@:dashboard.unpermittedDriver',
    driverScore: '@:dashboard.driverScore',
    way2Call: '@:way2Call.way2Call',
    vehicleNameOrPlateNumber: '@:liveTracking.vehicleNameOrPlateNumber',
};
const fleet = {
    vehicleManagement: "Vehicle Management",
    addFleet: "Add Fleet",
    addVehicle: "Add Vehicle",
    fleetName: "Fleet Name",
    totalVehicles: "Total Vehicles",
    totalDrivers: "Total Drivers",
    description: "Description",
    unassignedVehicles: "Unassigned Vehicles",
    editFleet: "Edit Fleet",
    vehicleList: "Vehicle List",
    fleetSettings: "Fleet Settings",
    noUnassignedVehicle:'No unassigned vehicle',
    noVehicleAssigned:'No vehicle assigned'
};
const vehicle = {
    vehicleName: "Vehicle Name",
    plateNumber: "Plate Number",
    vehicleModel: "Vehicle Model",
    permittedDrivers: "Permitted Drivers",
    registered: "Registered",
    trialTimeDays: "Trial Time",
    days:'Days',
    KVSTimeHMS: "KVS Time",
    hms:'H:M:S',
    deviceAndFirmware: 'Device & Firmware',
    addVehicle: "Add Vehicle",
    editVehicle: "Edit Vehicle",
    vehicleInformation: "Vehicle Information",
    fuelTankCapacity: "Fuel Tank Capacity",
    fuelType: "Fuel Type",
    vehicleWeight: "Vehicle Weight",
    tonRange: "Under 1.8T，1.8~3.4T，Over 3.4T",
    displacement: "Displacement",
    vehicleLicense: "Vehicle License",
    deviceInformation: "Device Information",
    deviceModel: "Device Model",
    unregister: "Unregister",
    SIMCard: "SIM Card",
    AIFeatures: "AI Features",
    vehicleHeight: "Vehicle Height",
    distanceFromCameraToRoof: "Distance from Camera to Roof",
    driverSeat: "Driver‘s Seat",
    left: "Left",
    right: "Right",
    vehicleWidth: "Vehicle Width",
    hoodLength: "Hood Length",
    ADASCalibration: "ADAS Calibration",
    DMSCalibration: "DMS Calibration",
    trialPeriodInformation: "Trial Period Information",
    trialTimeRemaining: "Trial Time Remaining",
    KVSTimeRemaining: "KVS Time Remaining",
    search: "Search...",
    searchHover:'Search vehicle by vehicle name, plate number or vehicle model',
    fleetName: "@:fleet.fleetName",
    totalVehicles: "@:fleet.totalVehicles",
    totalDrivers: "@:fleet.totalDrivers",
    description: "@:fleet.description",
    vehicleList: "@:fleet.vehicleList",
    fleet: "@:way2Call.fleet",
    LDW: "@:tripHistory.LDW",
    FCW: "@:tripHistory.FCW",
    DMS: "DMS",
    DVR:'DVR',
    camera_one:'Camera #1 ',
    camera_two:'Camera #2 ',
    camera_three:'Camera #3 ',
    camera_four:'Camera #4 ',
    camera_five:'Camera #5 ',
    camera_six:'Camera #6 ',
    camera_seven:'Camera #7 ',
    camera_eight:'Camera #8 ',
    camera_nine:'Camera #9 ',
    SVSCalibration:'SVS Calibration',
    pedestrianDetection:'Pedestrian Detection',
    enabledRear:'Enabled Rear',
    camerasAIFeatures:'Cameras & AI Features',
    disable:'Disable',
    under:"Under ",
    over:"Over ",
    t:" T",
    Front_LDW:'Front - LDW',
    Front_FCW:'Front - FCW',
    Front_LDW_FCW:'Front - LDW + FCW',
    Left_BSD:'Left - BSD',
    Right_BSD:'Right - BSD',
    Rear:'Rear',
    Rear_DMOD:'Rear -DMOD',
    Rear_PAS:'Rear - PAS',
    Rear_PAS_DMOD:'Rear - PAS + DMOD',
    Front_SVS:'Front - SVS',
    Front_SVS_DMOD:'Front - SVS + DMOD',
    Left_SVS:'Left - SVS',
    Right_SVS:'Right - SVS',
    Rear_SVS:'Rear - SVS',
    Rear_SVS_PAS:'Rear - SVS + PAS',
    Rear_SVS_DMOD:'Rear - SVS + DMOD',
    Rear_SVS_PAS_DMOD:'Rear - SVS + PAS + DMOD',
    Rear_SVS_DMOD_PAS:'Rear - SVS + DMOD + PAS',
    camera:'Camera',
    parkingAssist:'Parking Assist'
};
const driver = {
    driverManagement: "Driver Management",
    addDriver: "Add Driver",
    driverName: "Driver Name",
    loginID: "Login ID",
    contactNumber: "Contact Number",
    licenseNumber: "License Number",
    permittedVehicles: "Permitted Vehicles",
    emergContact: "Emerg. Contact",
    faceIDPhoto: "Face ID Photo",
    editDriver: "Edit Driver",
    driverInformation: "Driver Information",
    firstName: "First Name",
    lastName: "Last Name",
    dateJoined: "Date Joined",
    emergContactNumber: "Emerg. Contact Number",
    driverLicense: "Driver License",
    driverPhoto: "Driver Photo",
    comment: "Comment",
    useFaceIDPhoto: "Use Face ID Photo",
    mobile360APPDriverLoginInformation: "Mobile360 APP – Driver Login Information",
    password: "@:login.password",
    search: "@:vehicle.search",
    searchHover:'Search driver by driver name, contact number, user ID or license number'
};
export {
    common,
    units,
    globalText,
    popUpMsg,
    tips,
    login,
    way2Call,
    alertList,
    myAccount,
    settings,
    billing,
    userAccounts,
    logout,
    liveTracking,
    dashboard,
    tripHistory,
    fleet,
    vehicle,
    driver
};
