import ServerApi from "../../assets/js/ServerAPI"
const state = {
    currentVehicle: null,
    vehicleList:null

}

const getters = {

    getCurrentVehicle:state=>{
        return state.currentVehicle;
    }

}

const mutations = {
    setCurrentVehicle: function (state, data) {
        state.currentVehicle = data;
    },
    updateVehicleList:function (state,list) {
        state.vehicleList = list;
    }

}

const actions = {
    getVehicleList:function (context) {
        ServerApi.vehicles.vehicleList((data)=>{
            context.commit("updateVehicleList", data)
        })
    }
}

export default {
    state,
    mutations,
    actions,
    getters
}
