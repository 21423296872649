import axios from 'axios';
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import store from "@/store";
import router from '@/router'

Vue.use(VueI18n);
Vue.prototype.$axios = axios;

const i18nAxios = new VueI18n({
    locale: localStorage.getItem('language') || "en-US",
    // 修改messages需要同步修改Main.js对应的messages.
    messages: {
        'en-US': require("../../assets/lang/en.js"),
        'zh-CN': require("../../assets/lang/zh-CN.js"),
        'zh-TW': require("../../assets/lang/zh-TW.js")
    }
});
const i18nTc = msg => {
    i18nAxios.locale = store.getters['i18n/localLanguage'];
    return i18nAxios.tc(msg);
};

axios.defaults.timeout = 30000;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.headers = {
    'Content-Type': 'application/json'
};
axios.defaults.headerstransformRequest = [function (data) {
    data = JSON.stringify(data)
    return data
}];
axios.defaults.withCredentials = false;


let clearPendingRequest = (callback) => {
    store.commit("clearAxiosRequest", callback);
};


let isMapUrl = (url) => {
    return /^https:\/\/maps.googleapis.com/.test(url);
};

let pendingRequests = new Map()
let noLoadingUrl = ['/device/command','/notification/read','/dial/record','/auth/touch'];

// 拦截请求
axios.interceptors.request.use(function (config) {
    if (sessionStorage.getItem("token")) {
        if (!config.url.startsWith("https://s3-ap-northeast-1.amazonaws.com")){
            config.headers.Authorization = sessionStorage.getItem("token");
        }
    }
    if (noLoadingUrl.indexOf(config.url) !== -1 || isMapUrl(config.url) || /amazonaws\.com/.test(config.url)){
        return config;
    }
    store.commit("setAxiosCount", "increase");
    const requestKey = `${config.url}/${JSON.stringify(config.params)}/${JSON.stringify(config.data)}&request_type=${config.method}`;
    if (pendingRequests.has(requestKey)) {
        config.cancelToken = new axios.CancelToken(cancel => cancel(`重复的请求被主动拦截: ${requestKey}`))
    } else {
        pendingRequests.set(requestKey, config);
        config.requestKey = requestKey;
    }
    store.commit("addLoading");
    return config;
}, function (error) {
    if (noLoadingUrl.indexOf(error.config.url) !== -1 || isMapUrl(error.config.url) || /amazonaws\.com/.test(error.config.url)){
        return;
    }
    store.commit("setAxiosCount", "reset");
    pendingRequests.clear();
    store.commit("closeLoading");
    return Promise.reject(error);
})

let rejectNetCode = 6001;

// 拦截相应
axios.interceptors.response.use(function (response) {
    if (noLoadingUrl.indexOf(response.config.url) !== -1 || isMapUrl(response.config.url) || /amazonaws\.com/.test(response.config.url)){
        return response.data;
    }
    const requestKey = response.config.requestKey;
    pendingRequests.delete(requestKey);
    store.commit("setAxiosCount", "decrease");
    return response.data;
}, function (error) {
    if (noLoadingUrl.indexOf(error.config.url) !== -1 || isMapUrl(error.config.url) || /amazonaws\.com/.test(error.config.url)){
        return;
    }
    store.commit("setAxiosCount", "reset");
    if (axios.isCancel(error)){
        console.error(`error = ${error}`);
        return Promise.reject(error)
    }
    pendingRequests.clear();
    return handleError(error);

});

function handleError(error) {

    var returnError = {
        code: 0
    }
    if (error.config && error.config.url && error.config.url.indexOf("/auth/touch") > -1) {
        return;
    }

    //cancel 的请求不做错误处理。。
    if (error.message == 'cancel') {
        returnError.code = rejectNetCode;
        return Promise.reject(returnError);
    }
    // 接口timeout
    else if (error.message.includes('timeout')) {   // 判断请求异常信息中是否含有超时timeout字符串
        if (error.config.url.indexOf("/dial/record") > -1) {
            return;
        }
        clearPendingRequest(() => {
            Vue.prototype.$message({message: i18nTc('globalText.requestTimeout'), type: 'warning'});
        });
        returnError.code = rejectNetCode;
        return Promise.reject(returnError);

    }
    //token 未授权，或者token失效
    else if (error && error.response && error.response.status === 401 || (error && error.response && error.response.data.code === 101)) {
        clearPendingRequest(() => {
            let clearData = () => {
                clearPendingRequest();
                sessionStorage.removeItem('token');
                store.commit('setCurrentUser', null);
                store.commit('setCompanyInfo', null);
                router.push("/login");

            }
            if (error.config.url === '/auth/logout')
                clearData();
            else {
                let msg = i18nTc('globalText.timeoutPleaseLoginAgain');
                Vue.prototype.$customAlert(msg, { // `Timeout. Please login again.`
                    customClass: 'message-logout'
                }).then(() => {
                    clearData();
                })
            }

        });//登陆失败，取消所有请求
        returnError.code = rejectNetCode;
        return Promise.reject(returnError);
    }
    //接口返回错误
    else if (error && error.response && error.response.status === 488) {
        var message = "";
        switch (error.response.data.code) {
            case 100: //登入失敗
                message = i18nTc('popUpMsg.login.loginFailedPleaseTryAgain');//"Login failed. Please try again.";
                Vue.prototype.$customAlert(`${message}`);
                break;
            case 201: // get error
                message = "Failed to get.";
                Vue.prototype.$customAlert(`${message}`);
                break;
            case 202: // add error
                message = "Failed to add.";
                Vue.prototype.$customAlert(`${message}`);
                break;
            case 203: //update Error
                message = "Failed to update.";
                Vue.prototype.$customAlert(`${message}`);
                break;
            case 204: //delete Error
                message = i18nTc('globalText.failedToDelete'); //"Failed to delete.";
                Vue.prototype.$customAlert(`${message}`);
                break;

        }
        return Promise.reject(error.response.data);
    }
    //其他网络错误
    else {
        clearPendingRequest(null);
        Vue.prototype.$message({message: i18nTc('globalText.networkError'), type: 'warning'});

    }
    returnError.code = rejectNetCode;
    return Promise.reject(returnError);
}

export function cancelCurrentAxios(){
    for (let config of pendingRequests.values()) {
        config.cancelToken = new axios.CancelToken(cancel => {
            cancel("取消上一页请求");
        })
    }
    pendingRequests.clear();
    store.commit("setAxiosCount", "reset");
}


