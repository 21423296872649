import Vue from 'vue';
import Router from 'vue-router';
import store from "@/store";
Vue.use(Router);

const router =  new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            redirect: sessionStorage.getItem('token') ? '/liveTracking' : '/login'
        },
        // {
        //     path: '/test',
        //     component: () => import('../components/monitor/ComLeafletMap.vue'),
        // },
        // {
        //     path: '/test2',
        //     component: () => import('../components/monitor/Test.vue'),
        // },
        {
            path: '/',
            component: () => import('../pages/Home.vue'),
            children: [
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    component: () => import('../pages/dashboard/Dashboard.vue'),
                    meta: {title: 'Dashboard', icon: "via-dashboard",requireAuth: true},
                },
                /* management*/
                {
                    path: 'management/vehicle',
                    name: 'fleetList',
                    components: {
                        fleetList: () => import( '../pages/management/vehicle/FleeList.vue')
                    },
                    meta: {title: 'Vehicle Management', icon: "via-vehicles",sidebarIndex:"/management/vehicle",requireAuth: true},
                },
                {
                    path: 'management/vehicle/add-fleet',
                    name:"addFleet",
                    component: () => import( '../pages/management/vehicle/AddOrEditFleet.vue'),
                    meta: {title: 'Add Fleet', icon: "via-vehicles",sidebarIndex:"/management/vehicle",requireAuth: true},
                },
                {
                    path: 'management/vehicle/edit-fleet',
                    name:'editFleet',
                    component: () => import( '../pages/management/vehicle/AddOrEditFleet.vue'),
                    meta: {title: 'Edit Fleet', icon: "via-vehicles",sidebarIndex:"/management/vehicle",requireAuth: true},
                },

                {
                    path: 'management/vehicle/add-vehicle',
                    name:'addVehicle',
                    component: () => import( '../pages/management/vehicle/EditVehicle.vue'),
                    meta: {title: 'Add Vehicle', icon: "via-car",sidebarIndex:"/management/vehicle",requireAuth: true}
                },
                {
                    path: 'management/vehicle/add-new-vehicle',
                    name:'addNewVehicle',
                    component: () => import( '../pages/management/vehicle/AddVehicle.vue'),
                    meta: {title: 'Add Vehicle', icon: "via-car",sidebarIndex:"/management/vehicle",requireAuth: true}
                },
                {
                    path: 'management/vehicle/view-vehicle',
                    name:'viewVehicle',
                    component: () => import( '../pages/management/vehicle/ViewVehicle.vue'),
                    meta: {title: 'Vehicle', icon: "via-car",sidebarIndex:"/management/vehicle",requireAuth: true}
                },
                {
                    path: 'management/vehicle/edit-vehicle',
                    name:'editVehicle',
                    component: () => import( '../pages/management/vehicle/EditVehicle.vue'),
                    meta: {title: 'Edit Vehicle', icon: "via-car",sidebarIndex:"/management/vehicle",requireAuth: true}
                },
                {
                    path: 'management/driver',
                    name:'driverList',
                    component: () => import( '../pages/management/driver/DriverList.vue'),
                    meta: {title: 'Driver Management', icon: "via-driver",sidebarIndex:"/management/driver",requireAuth: true}

                },
                {
                    path: 'management/driver/view',
                    name:"viewDriver",
                    component: () => import( '../pages/management/driver/ViewDriver.vue'),
                    meta: {title: 'Driver', icon: "via-driver",sidebarIndex:"/management/driver",requireAuth: true}

                },
                {
                    path: 'management/driver/add',
                    name:"addDriver",
                    component: () => import( '../pages/management/driver/AddOrEditDriver.vue'),
                    meta: {title: 'Add Driver', icon: "via-driver",sidebarIndex:"/management/driver",requireAuth: true}

                },
                {
                    path: 'management/driver/edit',
                    name:"editDriver",
                    component: () => import( '../pages/management/driver/AddOrEditDriver.vue'),
                    meta: {title: 'Edit Driver', icon: "via-driver",sidebarIndex:"/management/driver",requireAuth: true}

                },
                /* account*/

                {
                    path: 'account/user',
                    name: 'userList',
                    component: () => import( '../pages/account/user/UserList.vue'),
                    meta: {title: 'Account', icon: "",sidebarIndex:"/account/user",requireAuth: true},
                },

                {
                    path: 'account/role',
                    name: 'role',
                    component: () => import( '../pages/account/role/Role.vue'),
                    meta: {title: 'Role', icon: "",sidebarIndex:"/account/role",requireAuth: true},
                },



                /* monitor*/
                {
                    path: '/liveTracking',
                    name:'realtime',
                    component: () => import('../pages/liveTracking/Realtime.vue'),
                    meta: {title: 'Live Tracking', icon: "via-live-tracking",requireAuth: true},
                },
                {
                    path: '/tripHistory',
                    name: 'tripHistory',
                    components: {
                        tripHistory: () => import('../pages/tripHistory/Index.vue')
                    },
                    meta: {title: 'Trip History', icon: "via-trip-history",requireAuth: true},
                },
                {
                    path: '/settings',
                    name: 'settings',
                    component: () => import('../pages/settings/Index'),
                    meta: {title: 'settings', icon: "via-settings", sidebarIndex: '/settings', requireAuth: true},
                },
                /* system*/
                // {
                //     path: '/system/account',
                //     component: () => import('../pages/system/Account.vue'),
                //     meta: [{title: 'Account'}]
                // },
                // {
                //     path: '/system/new-password',
                //     component: () => import('../pages/system/ChangePassword.vue'),
                //     meta: [{title: 'Role'}]
                // },
                // {
                //     path: '/system/setting',
                //     component: () => import('../pages/system/Setting.vue'),
                //     meta: [{title: 'Setting'}]
                // }

            ]
        },
        {
            path: '/login',
            component: () => import(/* webpackChunkName: "login" */ '../pages/login/Login.vue'),
            meta:{requireAuth: false}
        },
        {
            path:'/privacyPolicyDetail',
            name:'privacyPolicyDetail',
            component:() => import('../components/common/PrivacyPolicyDetail.vue'),
            meta:{requireAuth: false}
        },
        {
            path: '/verify',
            name:'verify',
            component: () => import('../pages/login/Verify.vue'),
            meta:{requireAuth: false}
        },
        {
            path: '/resetPwd',
            name:'resetPwd',
            component: () => import( '../pages/login/ResetPassword.vue'),
            meta:{requireAuth: false}
        },
        {
            path: '/binding/login',
            component: () => import('../pages/qrCode/Login.vue')
        },
        {
            path: '/binding/device',
            component: () => import('../pages/qrCode/GenerateQRCode.vue')
        },
        {
            path: '*',
            redirect: '/404'
        }
    ]

});


const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


router.beforeEach((to, from, next) => {
    store.commit('clearAxiosRequest'); // 路由跳转取消上一页请求
    if(!to.meta.requireAuth)
    {
        next();
    }else{
        if (sessionStorage.getItem('token')) {
            if (to.fullPath === '/login') {
                next('/liveTracking');
            }
            next();
        } else {
            if (to.fullPath !== '/login') {
                next('/login');
            }
            next();
        }
    }

});

export default router;
