import Vue from 'vue'

Vue.prototype.$getCurrentDate = function () {
    var d = new Date();
    var year = d.getFullYear();
    var month = change(d.getMonth() + 1);
    var day = change(d.getDate());
    var hour = change(d.getHours());
    var minute = change(d.getMinutes());
    var second = change(d.getSeconds());

    function change(t) {
        if (t < 10) {
            return "0" + t;
        } else {
            return t;
        }
    }
    return year + '-' + month + '-' + day + ' ' + hour + ':' + minute + ':' + second;
}

Date.prototype.format = function (fmt) {
    let o = {
        "M+": this.getMonth() + 1,
        "d+": this.getDate(),
        "h+": this.getHours(),
        "m+": this.getMinutes(),
        "s+": this.getSeconds(),
        "q+": Math.floor((this.getMonth() + 3) / 3),
        "S": this.getMilliseconds()
    };

    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }

    for (var k in o) {
        if (new RegExp("(" + k + ")").test(fmt)) {
            fmt = fmt.replace(
                RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }

    return fmt;
}

String.prototype.resetBlank=function(){
    var regEx = /\s+/g;
    return this.replace(regEx, ' ');
};


export function getArr1DifferenceFrommArr2(arr1, arr2) {
    if (!arr1 && !arr2) return null;
    if (!arr1) return null;
    if (!arr2) return arr1;
    return arr1.filter((item) => {
        let find = arr2.filter((sub) => {
            return sub.Id === item.Id;
        });
        return arr2.filter((sub) => {
            return sub.Id === item.Id;
        }).length === 0;
    });

}

export function concatUnique(arr1, arr2) {

    let arr = arr1.concat();
    for (let i = 0; i < arr2.length; i++) {
        arr.indexOf(arr2[i]) === -1 ? arr.push(arr2[i]) : 0;
    }
    return arr;

}

export function randomWord(value){
    let str = "",
        arr = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k',
            'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G',
            'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

    for(let i=0; i<value; i++){
        let pos = Math.round(Math.random() * (arr.length-1));
        str += arr[pos];
    }
    return str;
}

export function formatSeconds(seconds) {
    let secondTime = parseInt(seconds);
    let minuteTime = 0;
    let hourTime = 0;
    if (secondTime >=60) {
        minuteTime = parseInt(secondTime / 60);
        secondTime = parseInt(secondTime % 60);
        if (minuteTime >= 60) {
            hourTime = parseInt(minuteTime / 60);
            minuteTime = parseInt(minuteTime % 60);
        }
    }
    var result;
    if (secondTime){
        result = (secondTime >= 10 ? "" : "0") + parseInt(secondTime) ;
    }
    else
        result = "" + "00";
    if (minuteTime > 0) {
        result = (minuteTime >= 10 ? "" : "0") + parseInt(minuteTime) + ":" + result;
    } else {
        result = "" + "00:" + result
    }
    if (hourTime > 0) {
        result = (hourTime >= 10 ? "" : "0") + parseInt(hourTime) + ":" + result;
    } else {
        result = "" + "00:" + result
    }
    return result;
}

export function formatDays(seconds) {
    if (parseInt(seconds) === 0)
        return 0;
    return (seconds / (24 * 3600)).toFixed(1);
}

export function numRound(value, point) {
    if (isNaN(value)) return value;
    let numTmp = Math.pow(10, point);
    return (Math.round(value * numTmp) / numTmp).toFixed(point);
}
const funStrArr = ['Front_LDW', 'Front_FCW', 'Front_LDW_FCW', 'Left_BSD', 'Right_BSD', 'Rear', 'Rear_DMOD',
    'Rear_PAS', 'Rear_PAS_DMOD', 'Front_SVS', 'Front_SVS_DMOD', 'Left_SVS', 'Right_SVS', 'Rear_SVS',
    'Rear_SVS_PAS', 'Rear_SVS_DMOD', 'Rear_SVS_PAS_DMOD','Rear_SVS_DMOD_PAS', 'DMS', 'DVR'];
export function getCameraFunName(value) {
    if (!value) return {result: false, name: ''};
    let tempValue = value.replace(/\s*/g, '').replaceAll('-', '_').replaceAll('+', '_');
    if (funStrArr.indexOf(tempValue) === -1) {
        return {result:false, name: value};
    } else {
        return {result:true, name: tempValue};
    }
}
export function parseVehicleModel(data){
    if (!data) {
        data.isD700 = false;
        data.isD700S = false;
        data.isD710 = false;
        data.isM800 = false;
        data.isM810 = false;
        data.isM820 = false;
        return data;
    }
    let isD700, isD710,isD700S, isM800, isM810, isM820;
    if (data.Device && data.HasDevice) {
        let model = data.Device.Model || '';
        if (model.indexOf('_') === -1) {
            isD700 = model === 'D700';
            isD700S = model === 'D700S';
            isD710 = model === 'D710';
            isM800 = model === 'M800';
            isM810 = model === 'M810';
            isM820 = model === 'M820';
        } else {
            let platformType = model.split('_')[0];
            isD700 = platformType === 'D700';
            isD700S = platformType === 'D700S';
            isD710 = platformType === 'D710';
            isM800 = platformType === 'M800';
            isM810 = platformType === 'M810';
            isM820 = platformType === 'M820';
        }
    } else {
        let model = data.MainModel || '';
        if (model.indexOf('_') === -1) {
            let platformType = model;
            isD700 = platformType === 'D700';
            isD700S = platformType === 'D700S';
            isD710 = platformType === 'D710';
            isM800 = platformType === 'M800';
            isM810 = platformType === 'M810';
            isM820 = platformType === 'M820';
        } else {
            let platformType = model.split('_')[0];
            isD700 = platformType === 'D700';
            isD700S = platformType === 'D700S';
            isD710 = platformType === 'D710';
            isM800 = platformType === 'M800';
            isM810 = platformType === 'M810';
            isM820 = platformType === 'M820';
        }
    }
    data.isD700 = isD700;
    data.isD700S = isD700S;
    data.isD710 = isD710;
    data.isM800 = isM800;
    data.isM810 = isM810;
    data.isM820 = isM820;
    return data;
}

export function isArrayFn(value) {
    if (typeof Array.isArray === "function") {
        return Array.isArray(value);
    }else{
        return Object.prototype.toString.call(value) === "[object Array]";
    }
}

const modelArr = ["M800","M810","M820"];
export function collisionVideoImageKeyConversion(data){
    if (!data) return null;
    if (isArrayFn(data)){
        return data.map(item =>{
            if (modelArr.indexOf(item.MainModel) === -1) return item;
            return Object.assign({}, handleData(item));
        })
    }else {
        if (modelArr.indexOf(data.MainModel) === -1) return data;
        return handleData(data);
    }
}
function handleData(item) {
    if (item.hasOwnProperty("ImageCamAPath") || item.hasOwnProperty("VideoCamAPath")){
        //老字段
        let {
            ImageCamAPath,
            ImageCamBPath,
            ImageCamCPath,
            VideoCamAPath,
            VideoCamBPath,
            VideoCamCPath,
            ...params
        } = item;
        params.ImageFPath = ImageCamAPath;
        params.ImageRPath = ImageCamBPath;
        params.ImageCPath = ImageCamCPath;
        params.VideoFPath = VideoCamAPath;
        params.VideoRPath = VideoCamBPath;
        params.VideoCPath = VideoCamCPath;
        return Object.assign({}, params);
    }else {
        //新字段
        let {
            ImagesPath,
            VideosPath,
            ...params
        } = item;
        if (ImagesPath){
            //M800使用0,1,2 ; M810/M820:CamA,CamB,CamC
            Object.keys(ImagesPath).forEach(item =>{
                if (item === 'CamA' || item === '0'){
                    params.ImageFPath = ImagesPath[item];
                }else if (item === 'CamB' || item === '1'){
                    params.ImageRPath = ImagesPath[item];
                }else {
                    params.ImageCPath = ImagesPath[item];
                }
            })
        }

        if (VideosPath){
            //M800使用0,1,2 ; M810/M820:CamA,CamB,CamC
            Object.keys(VideosPath).forEach(item =>{
                if (item === 'CamA' || item === '0'){
                    params.VideoFPath = ImagesPath[item];
                }else if (item === 'CamB' || item === '1'){
                    params.VideoRPath = ImagesPath[item];
                }else {
                    params.VideoCPath = ImagesPath[item];
                }
            })
        }
        return params;
    }

}
