const state = {
    deviceTableHead:[
        {
            name:"Device SN",
            prop:"deviceSN"

        },
        {
            name:"Device Number",
            prop:"number"
        },
        {
            name:"Status",
            prop:"status"
        },
        {
            name:"Type",
            prop:"type"
        },
        {
            name:"Vehicle Name",
            prop:"vehicleName"
        },
        {
            name:"Fleet",
            prop:"fleet"
        },
    ],
    deviceData: [
        {
            id:10001,
            deviceSN: "6741823789",
            number: '001-01-0001',
            status:'Using',
            type: 'TypeA',
            vehicleName: "Truck 12",
            fleet:"Fleet A"
        },
        {
            id:10002,
            deviceSN: "6741823783",
            number: '001-01-0002',
            status:'Using',
            type: 'TypeA',
            vehicleName: "Truck 11",
            fleet:"Fleet B"
        },
        {
            id:10003,
            deviceSN: "6741823784",
            number: '001-01-0003',
            status:'Using',
            type: 'TypeA',
            vehicleName: "Truck 11",
            fleet:"Fleet A"
        },
        {
            id:10004,
            deviceSN: "6741823783",
            number: '001-01-0002',
            status:'Using',
            type: 'TypeA',
            vehicleName: "Truck 13",
            fleet:"Fleet B"
        },
        {
            id:10005,
            deviceSN: "6741823783",
            number: '001-01-0002',
            status:'Using',
            type: 'TypeA',
            vehicleName: "Truck 13",
            fleet:"Fleet A"
        },
    ],

}

const getters = {
    deviceTableHead: state=>{
        return state.deviceTableHead;
    },
    deviceData:state=>fleetId=>{
        var datas =[];
        if(fleetId){
            for(var i=0;i<state.deviceData.length;i++){i
                if(state.deviceData[i].fleetId == fleetId)
                    datas.push(state.deviceData[i])
            }
            return datas;
        }
        return state.deviceData;
    }
}

const mutations = {

}

const actions = {}

export default {
    state,
    mutations,
    actions,
    getters
}