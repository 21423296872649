import ServerAPI from "../assets/js/ServerAPI";
import store from "./index";

export default {
    updateCompanyInfo() {
        if(!store.getters.companyInfo){
            return new Promise((resolve, reject) => {
                ServerAPI.company.getCompany((data) => {
                    store.commit("setCompanyInfo", data);
                    resolve(data)
                }, () => {
                    reject(null)
                });
            })

        }

    }
}
