<template>
    <div class="privacy-container">
        <div style="flex-grow: 85;text-align: center;display: flex;align-items: center">
            <span class="top-span" >
                We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will
                assume that you are happy with it.
            </span>
            <el-button size="medium" class="button-class" @click="onClickOk">OK
            </el-button>
            <el-button v-if="isPrivacyPolicyShow()" size="medium" class="button-class" @click="onClickPrivacyDetail">
                PRIVACY POLICY
            </el-button>
        </div>
        <div style="flex-grow: 15">
            <i class="el-icon-close close-icon" @click="onClosePrivacy"></i>
        </div>
    </div>
</template>

<script>
    import {CustomerConfig} from '@/services/customer/CustomerConfig'

    export default {
        name: "PrivacyPolicy",
        methods:{
            isPrivacyPolicyShow () {
                return CustomerConfig.getLoginPrivacyPolicyShow(true)
            },
            onClosePrivacy(){
                this.$emit('onConfirmPrivacy')
            },
            onClickOk(){
                localStorage.setItem('confirmPrivacy', 'true');
                this.$emit('onConfirmPrivacy')
            },
            onClickPrivacyDetail(){
                // this.$router.push({name: "privacyPolicyDetail"}).catch();
                let routeData = this.$router.resolve({name: "privacyPolicyDetail"});
                this.$emit('onConfirmPrivacy')
                localStorage.setItem('confirmPrivacy', 'true');
                window.open(routeData.href, '_blank');
            }
        }
    }
</script>

<style scoped>
    .button-class {
        margin-left: 20px;
        background: #00a99d;
        border: none;
        color: white;
        flex-grow: 10;
        height: 40px;
    }
    .top-span {
        line-height: 80px;
        font-size: 16px;
        color: black;
        flex-grow: 80;
        white-space: nowrap;/*强制不换行*/
        overflow: hidden;/*超出部分隐藏*/
        text-overflow: ellipsis;/*超出部分使用...代替*/
    }
    .privacy-container {
        height: 80px;
        background: rgba(222,91,61,0.9);
        display: flex;
        align-items: center;
    }
    .close-icon {
        float: right;
        line-height: 80px;
        margin-right: 30px;
        font-size: 24px;
        color: white;
        cursor: pointer
    }
</style>
