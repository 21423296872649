/**
 * 关于配置的说明：
 * KAREnterprise：表示公司的编号，执行命令会用到这个参数。
 * loginPage: 表示登录页的配置
 * header: 表示用户登录系统后的顶部导航部分
 * leftNav: 表示用户登录系统后的左侧导航部分
 * vehicleList: 表示车辆列表页面（车队列表里面的table）
 * viewVehicle: 表示车辆的详情页
 * en-US / zh-CN / zh-TW 表示多语言的配置
 * 
 * 如果是填写的图片路径，只需要把图片放入 /assets/image/customer 就好了，配置参数写图片名称。
 * 图片的命名规则如下：
 * customer/[company name]/[image name].png
 * 
 * 配置的参数如果过空字符串则该属性不会生效
 */

const getDateRange = (startYear) => {
    const currentYear = new Date().getFullYear()
    return currentYear > startYear ? `${startYear} - ${currentYear}` : currentYear
}

const KAREnterprise = {
    title: 'KARE FLEET',
    favicon: 'customer/KAREnterprise/favicon.ico',
    loginPage: {
        logo: 'customer/KAREnterprise/login_logo_top.png',
        privacyPolicy: {
            show: false
        },
        appStoreAndroid: {
            show: false,
            qrcodePath: ''
        },
        appStoreIOS: {
            show: false,
            qrcodePath: ''
        },
        welcome: {
            'en-US': 'Welcome to KARE Fleet',
            'zh-CN': '欢迎使用 KARE 车队云平台',
            'zh-TW': '歡迎使用 KARE Fleet'
        },
        copyright: {
            'en-US': `Copyright © ${ getDateRange(2021) } KAREnterprise Inc. `,
            'zh-CN': `版权所有 © ${ getDateRange(2021) } KARE`,
            'zh-TW': `版權所有 © ${ getDateRange(2021) } KARE`
        }
    },
    header: {
        logo: 'customer/KAREnterprise/header_logo.png',
        appName: {
            'en-US': 'KARE FLEET',
            'zh-CN': 'KARE FLEET',
            'zh-TW': 'KARE FLEET'
        }
    },
    leftNav: {
        logo: 'customer/KAREnterprise/leftnav_buttom_logo.png',
        copyright: {
            'en-US': `Copyright © ${ getDateRange(2021) } KAREnterprise Inc. `,
            'zh-CN': `版权所有 © ${ getDateRange(2021) } KARE`,
            'zh-TW': `版權所有 © ${ getDateRange(2021) } KARE`
        }
    },
    vehicleList: {
        titleKVSTime: {
            show: false
        },
        titleTrialTime: {
            show: false
        },
        titleRenewDate: {
            show: true,
            text: {
                'en-US': 'Renew Date',
                'zh-CN': '到期日期',
                'zh-TW': '到期日期'
            }
        }
    },
    viewVehicle: {
        trialPeriodInformation: {
            show: false
        },
        periodInformation: {
            show: true,
            title: {
                'en-US': 'Period Information',
                'zh-CN': '时间',
                'zh-TW': '時間'
            },
            renewTime: {
                'en-US': 'Renew Date',
                'zh-CN': '到期日期',
                'zh-TW': '到期日期'
            }
        }
    },
    addVehiclePopWindow: {
        title: {
            open: true,
            text: {
                'en-US': 'KARE Series Platform',
                'zh-CN': 'KARE 系列平台',
                'zh-TW': 'KARE 系列平台'
            }
        },
        description: {
            open: true,
            text: {
                'en-US': 'Select which KARE Series Platform will be installed in the vehicle.',
                'zh-CN': '请选择将被安装到此车辆的 KARE 平台型号。',
                'zh-TW': '請選擇將被安裝到此車輛的 KARE 平台型號。'
            }
        },
        devicePrefix: {
            open: true,
            text: {
                'en-US': 'Mobile360',
                'zh-CN': 'Mobile360',
                'zh-TW': 'Mobile360'
            }
        }
    }
}

module.exports = {
    KAREnterprise
}