import ServerAPI from "./ServerAPI";
import MapConfig from "./MapConfig";
import axios from 'axios'
import store from '../../store'

let formatGoogleResultToEnglish = (gpsData) => {
    let location = '';
    if (gpsData.results && gpsData.results && gpsData.results.length > 0) {
        let addressComponents = gpsData.results;
        let firstAddress = null;
        let maxAddressLength = 0;
        let targetAddress = null;
        addressComponents.forEach(address => {
            let tempAddress = '';
            if (address.address_components && address.address_components.forEach) {
                address.address_components.forEach(location => {
                    if (location.types.findIndex(text => text === 'postal_code') === -1 && !/[\u4e00-\u9fa5]+/i.test(location.long_name)) {
                        tempAddress += location.long_name;
                        location.types.findIndex(text => text === 'street_number') === -1
                            ? tempAddress += ', '
                            : tempAddress += ' ';
                    }
                });
            }
            if (firstAddress === null) firstAddress = tempAddress;
            if (!/[\u4e00-\u9fa5]+/i.test(tempAddress) && tempAddress.length > maxAddressLength) {
                targetAddress = tempAddress;
                maxAddressLength = tempAddress.length;
            }
        });
        location = targetAddress === null ? firstAddress : targetAddress;
        location = location.substring(0, location.lastIndexOf(','));
    }
    return location;
};

let formatGoogleResultToChinese = (gpsData) => {
    let location = '';
    if (gpsData.results && gpsData.results && gpsData.results.length > 0) {
        let isChineseAddr = false, tempAddrssArr = [];
        let address = gpsData.results[0];
        if (address.address_components && address.address_components.forEach) {
            address.address_components.forEach(location => {
                if (location.types.findIndex(text => text === 'postal_code') === -1 && location.long_name !== 'Unnamed Road')
                    tempAddrssArr.push(location);
                if (
                    location.types.findIndex(text => text === 'postal_code') === -1
                    && location.types.findIndex(text => text === 'street_number') === -1
                    && /[\u4e00-\u9fa5]+/i.test(location.long_name)
                ) isChineseAddr = true;
            });
        }

        if (!isChineseAddr)
            return formatGoogleResultToEnglish(gpsData);

        let language = store.getters['i18n/googleLangParam'] || MapConfig.google.language;
        tempAddrssArr.forEach(locationInfo => {
            let addrPart = locationInfo.long_name;
            if (
                locationInfo.types.findIndex(text => text === 'street_number') !== -1
                && /[0-9]+$/i.test(addrPart)
            ) addrPart = addrPart + (language.toLowerCase() === 'zh-tw' ? '號' : '号');
            location = addrPart + location;
        });
    }
    return location;
};
let formatGoogleResultToDefault = (gpsData) => {
    let location = '';
    if (gpsData.results && gpsData.results[0] && gpsData.results[0].formatted_address) {
        location = gpsData.results[0].formatted_address;
    }
    return location;
};

export default {
    getLocation(latitude, longitude, isCached = true) {
        if (
            (latitude === 0 && longitude === 0)
            || (latitude !== 0 && !latitude)
            || (longitude !== 0 && !longitude)
        ) return new Promise(resolve => resolve('N/A'));
        let language = store.getters['i18n/googleLangParam'] || MapConfig.google.language;
        let oldLocation = store.getters['gps/getAddress']({
            latitude: latitude, longitude: longitude, language
        });
        if (oldLocation)
            return new Promise(resolve => resolve(oldLocation));

        let params = {
            lat: latitude,
            lng: longitude,
            key: MapConfig.google.key,
            lang: language
        };
        let oriGps = store.getters['gps/getOriGps']({
            latitude: latitude,
            longitude: longitude
        });
        let location = `GPS: ${oriGps.latitude}, ${oriGps.longitude}`;
        return new Promise((resove, reject) => {
            ServerAPI.Gps.googleMap(params).then(gpsData => {
                let location = '';
                if (language.toLowerCase() === 'en') {
                    location = formatGoogleResultToEnglish(gpsData);
                } else if (language.toLowerCase() === 'zh-cn') {
                    location = formatGoogleResultToChinese(gpsData);
                } else if (language.toLowerCase() === 'zh-tw') {
                    location = formatGoogleResultToChinese(gpsData);
                } else {
                    location = formatGoogleResultToDefault(gpsData);
                }
                if (location && isCached) {
                    store.commit('gps/addAddress', {
                        latitude: latitude,
                        longitude: longitude,
                        address: location,
                        language: language
                    });
                }
                resove(location);
            }).catch(() => {
                reject(location);
            });
        });
    },
    getAllLocation(gpsList) {
        let locationArr = [], apiPromiseArr = [];
        let language = store.getters['i18n/googleLangParam'] || MapConfig.google.language;
        gpsList.forEach((gps, index) => {
            let latitude = gps.latitude, longitude = gps.longitude;
            if (
                (latitude === 0 && longitude === 0)
                || (latitude !== 0 && !latitude)
                || (longitude !== 0 && !longitude)
            ) {
                locationArr[index] = 'N/A';
            } else {
                let oriGps = store.getters['gps/getOriGps']({
                    latitude: latitude,
                    longitude: longitude
                });
                locationArr[index] = `GPS: ${oriGps.latitude}, ${oriGps.longitude}`;
            }
            let params = {
                lat: latitude,
                lng: longitude,
                key: MapConfig.google.key + 1,
                lang: language,
            };
            apiPromiseArr[index] = this.getLocation(params.lat, params.lng);
        });
        return new Promise((resove, reject) => {
            axios.all(apiPromiseArr).then(axios.spread((...allResult) => {
                allResult.forEach((location, index) => {
                    locationArr[index] = location;
                });
                resove(locationArr);
            })).catch(() => {
                reject(locationArr);
            });
        });
    }
}