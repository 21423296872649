/**
 * 坐标转换程序
 *
 *  WGS84坐标系：即地球坐标系，国际上通用的坐标系。Earth

 GCJ02坐标系：即火星坐标系，WGS84坐标系经加密后的坐标系。Mars

 BD09坐标系：即百度坐标系，GCJ02坐标系经加密后的坐标系。  Bd09

 搜狗坐标系、图吧坐标系等，估计也是在GCJ02基础上加密而成的。
 *
 * 百度地图API        百度坐标
 腾讯搜搜地图API    火星坐标
 搜狐搜狗地图API    搜狗坐标*
 阿里云地图API       火星坐标
 图吧MapBar地图API    图吧坐标
 高德MapABC地图API    火星坐标
 灵图51ditu地图API    火星坐标
 *
 * @author fankun
 *
 */

export default {
    PI: 3.14159265358979324,
    x_pi: 3.14159265358979324 * 3000.0 / 180.0,
    delta: function (Latitude, Longitude) {
        let a = 6378245.0; //  a: 卫星椭球坐标投影到平面地图坐标系的投影因子。
        let ee = 0.00669342162296594323; //  ee: 椭球的偏心率。
        let dLat = this.transformLat(Longitude - 105.0, Latitude - 35.0);
        let dLon = this.transformLon(Longitude - 105.0, Latitude - 35.0);
        let radLat = Latitude / 180.0 * this.PI;
        let magic = Math.sin(radLat);
        magic = 1 - ee * magic * magic;
        let sqrtMagic = Math.sqrt(magic);
        dLat = (dLat * 180.0) / ((a * (1 - ee)) / (magic * sqrtMagic) * this.PI);
        dLon = (dLon * 180.0) / (a / sqrtMagic * Math.cos(radLat) * this.PI);
        return {'Latitude': dLat, 'Longitude': dLon};
    },

    //WGS-84 to GCJ-02
    gcj_encrypt: function (wgsLat, wgsLon) {
        if (this.IsInHongKong(wgsLat, wgsLon)) {
            return {'Latitude': wgsLat, 'Longitude': wgsLon};
        }
        if (!this.IsInsideChina(wgsLat, wgsLon))
            return {'Latitude': wgsLat, 'Longitude': wgsLon};

        var d = this.delta(wgsLat, wgsLon);
        return {'Latitude': wgsLat + d.Latitude, 'Longitude': wgsLon + d.Longitude};
    },
    //GCJ-02 to WGS-84
    gcj_decrypt: function (gcjLat, gcjLon) {
        if (!this.IsInsideChina(gcjLat, gcjLon))
            return {'Latitude': gcjLat, 'Longitude': gcjLon};

        var d = this.delta(gcjLat, gcjLon);
        return {'Latitude': gcjLat - d.Latitude, 'Longitude': gcjLon - d.Longitude};
    },
    //GCJ-02 to WGS-84 exactly
    gcj_decrypt_exact: function (gcjLat, gcjLon) {
        let initDelta = 0.01;
        let threshold = 0.000000001;
        let dLat = initDelta, dLon = initDelta;
        let mLat = gcjLat - dLat, mLon = gcjLon - dLon;
        let pLat = gcjLat + dLat, pLon = gcjLon + dLon;
        let wgsLat, wgsLon, i = 0;
        while (1) {
            wgsLat = (mLat + pLat) / 2;
            wgsLon = (mLon + pLon) / 2;
            var tmp = this.gcj_encrypt(wgsLat, wgsLon)
            dLat = tmp.Latitude - gcjLat;
            dLon = tmp.Longitude - gcjLon;
            if ((Math.abs(dLat) < threshold) && (Math.abs(dLon) < threshold))
                break;

            if (dLat > 0) pLat = wgsLat; else mLat = wgsLat;
            if (dLon > 0) pLon = wgsLon; else mLon = wgsLon;

            if (++i > 10000) break;
        }
        return {'Latitude': wgsLat, 'Longitude': wgsLon};
    },
    //GCJ-02 to BD-09
    bd_encrypt: function (gcjLat, gcjLon) {
        let x = gcjLon, y = gcjLat;
        let z = Math.sqrt(x * x + y * y) + 0.00002 * Math.sin(y * this.x_pi);
        let theta = Math.atan2(y, x) + 0.000003 * Math.cos(x * this.x_pi);
        let bdLon = z * Math.cos(theta) + 0.0065;
        let bdLat = z * Math.sin(theta) + 0.006;
        return {'Latitude': bdLat, 'Longitude': bdLon};
    },
    //BD-09 to GCJ-02
    bd_decrypt: function (bdLat, bdLon) {
        let x = bdLon - 0.0065, y = bdLat - 0.006;
        let z = Math.sqrt(x * x + y * y) - 0.00002 * Math.sin(y * this.x_pi);
        let theta = Math.atan2(y, x) - 0.000003 * Math.cos(x * this.x_pi);
        let gcjLon = z * Math.cos(theta);
        let gcjLat = z * Math.sin(theta);
        return {'Latitude': gcjLat, 'Longitude': gcjLon};
    },
    mercator_encrypt: function (wgsLat, wgsLon) {
        let x = wgsLon * 20037508.34 / 180.;
        let y = Math.log(Math.tan((90. + wgsLat) * this.PI / 360.)) / (this.PI / 180.);
        y = y * 20037508.34 / 180.;
        return {'Latitude': y, 'Longitude': x};
    },
    mercator_decrypt: function (mercatorLat, mercatorLon) {
        let x = mercatorLon / 20037508.34 * 180.;
        let y = mercatorLat / 20037508.34 * 180.;
        y = 180 / this.PI * (2 * Math.atan(Math.exp(y * this.PI / 180.)) - this.PI / 2);
        return {'Latitude': y, 'Longitude': x};
    },
    // two point's distance
    distance: function (LatitudeA, LongitudeA, LatitudeB, LongitudeB) {
        let earthR = 6371000.;
        let x = Math.cos(LatitudeA * this.PI / 180.) * Math.cos(LatitudeB * this.PI / 180.) * Math.cos((LongitudeA - LongitudeB) * this.PI / 180);
        let y = Math.sin(LatitudeA * this.PI / 180.) * Math.sin(LatitudeB * this.PI / 180.);
        let s = x + y;
        if (s > 1) s = 1;
        if (s < -1) s = -1;
        let alpha = Math.acos(s);
        return alpha * earthR;
    },
    outOfMainland: function (Latitude, Longitude) {
        if (Longitude < 72.004 || Longitude > 137.8347)
            return true;
        if (Latitude < 0.8293 || Latitude > 55.8271)
            return true;
        if (Longitude > 119.77 && Longitude < 122.33 && Latitude < 25.28 && Latitude > 21.62) {//TP
            return true;
        }
        if (Longitude > 139.10 && Longitude < 145.65 && Latitude < 45.50 && Latitude > 33.60) {//JP part1
            return true;
        }
        if (Longitude > 129.83 && Longitude <= 139.10 && Latitude < 38.80 && Latitude > 29.85) {//JP part2
            return true;
        }
        return Longitude > 125.97 && Longitude < 129.65 && Latitude < 38.22 && Latitude > 34.48;

    },
    transformLat: function (x, y) {
        var ret = -100.0 + 2.0 * x + 3.0 * y + 0.2 * y * y + 0.1 * x * y + 0.2 * Math.sqrt(Math.abs(x));
        ret += (20.0 * Math.sin(6.0 * x * this.PI) + 20.0 * Math.sin(2.0 * x * this.PI)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(y * this.PI) + 40.0 * Math.sin(y / 3.0 * this.PI)) * 2.0 / 3.0;
        ret += (160.0 * Math.sin(y / 12.0 * this.PI) + 320 * Math.sin(y * this.PI / 30.0)) * 2.0 / 3.0;
        return ret;
    },
    transformLon: function (x, y) {
        var ret = 300.0 + x + 2.0 * y + 0.1 * x * x + 0.1 * x * y + 0.1 * Math.sqrt(Math.abs(x));
        ret += (20.0 * Math.sin(6.0 * x * this.PI) + 20.0 * Math.sin(2.0 * x * this.PI)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(x * this.PI) + 40.0 * Math.sin(x / 3.0 * this.PI)) * 2.0 / 3.0;
        ret += (150.0 * Math.sin(x / 12.0 * this.PI) + 300.0 * Math.sin(x / 30.0 * this.PI)) * 2.0 / 3.0;
        return ret;
    },
    IsInHongKong(x, y) {
        let checkPoint = [x, y];
        var counter = 0;
        var i;
        var xinters;
        var p1, p2;
        var pointCount = HongKongPointList.length;
        p1 = HongKongPointList[0];

        for (i = 1; i <= pointCount; i++) {
            p2 = HongKongPointList[i % pointCount];
            if (
                checkPoint[0] > Math.min(p1[0], p2[0]) &&
                checkPoint[0] <= Math.max(p1[0], p2[0])
            ) {
                if (checkPoint[1] <= Math.max(p1[1], p2[1])) {
                    if (p1[0] !== p2[0]) {
                        xinters =
                            (checkPoint[0] - p1[0]) *
                            (p2[1] - p1[1]) /
                            (p2[0] - p1[0]) +
                            p1[1];
                        if (p1[1] === p2[1] || checkPoint[1] <= xinters) {
                            counter++;
                        }
                    }
                }
            }
            p1 = p2;
        }
        return counter % 2 !== 0;
    },
    IsInsideChina(x, y) {
        return mainLandPointList.some((poly) => {
            let index = 0;
            let inside = false;
            let prePoint = poly[0];
            for (index = 1; index < poly.length; index++) {
                let nextPoint = poly[index];
                if (y > Math.min(prePoint.Y, nextPoint.Y)
                    && y <= Math.max(prePoint.Y, nextPoint.Y)
                    && x <= Math.max(prePoint.X, nextPoint.X)
                    && prePoint.Y !== nextPoint.Y) {
                    let xinters = (y - prePoint.Y) * (nextPoint.X - prePoint.X) / (nextPoint.Y - prePoint.Y) + prePoint.X;
                    if (prePoint.X === nextPoint.X || x <= xinters)
                        inside ^= true;
                }
                prePoint = nextPoint;
            }
            return inside;
        })

    }
};

function Point(x, y) {
    this.X = x;
    this.Y = y;
}

var mainLandPointList = [];

mainLandPointList.push(
    [  //Mainland
        new Point(27.32083, 88.91693),
        new Point(28.00805, 88.83575),
        new Point(27.86605, 88.14279),
        new Point(27.82305, 87.19275),
        new Point(28.11166, 86.69527),
        new Point(27.88625, 86.0054),
        new Point(28.27916, 85.72137),
        new Point(28.54444, 84.84665),
        new Point(29.18902, 83.5479),
        new Point(30.06923, 82.17525),
        new Point(30.385, 81.42623),
        new Point(30.57552, 80.207),
        new Point(30.96583, 79.86304),
        new Point(31.30895, 78.76825),
        new Point(32.24304, 78.47594),
        new Point(32.63902, 78.74623),
        new Point(32.75666, 79.52874),
        new Point(33.42863, 78.93623),
        new Point(34.06833, 78.73581),
        new Point(35.28069, 78.02305),
        new Point(35.50133, 77.82393),
        new Point(35.90665, 76.55304),
        new Point(35.81458, 76.18061),
        new Point(36.07082, 75.92887),
        new Point(36.23751, 76.04166),
        new Point(36.99719, 75.14787),
        new Point(37.02782, 74.56543),
        new Point(37.40659, 75.18748),
        new Point(37.65243, 74.9036),
        new Point(38.47256, 74.85442),
        new Point(38.61271, 73.81401),
        new Point(39.23569, 73.62005),
        new Point(39.76896, 73.8429),
        new Point(40.04202, 73.99096),
        new Point(40.32792, 74.88089),
        new Point(40.45042, 75.23394),
        new Point(40.298, 75.70374),
        new Point(41.01258, 76.87067),
        new Point(41.04079, 78.08083),
        new Point(41.39286, 78.39554),
        new Point(42.03954, 80.24513),
        new Point(42.88545, 80.57226),
        new Point(43.02906, 80.38405),
        new Point(44.11378, 80.36887),
        new Point(44.73408, 80.51589),
        new Point(44.90282, 79.87106),
        new Point(45.3497, 81.67928),
        new Point(45.13303, 82.56638),
        new Point(45.5831, 82.32179),
        new Point(47.20061, 83.03443),
        new Point(46.97332, 83.93026),
        new Point(46.99361, 84.67804),
        new Point(47.0591, 85.52257),
        new Point(48.39333, 85.76596),
        new Point(48.54277, 86.59791),
        new Point(49.1102, 86.87602),
        new Point(49.09262, 87.34821),
        new Point(48.98304, 87.89291),
        new Point(48.73499, 88.05942),
        new Point(48.56541, 87.99194),
        new Point(48.40582, 88.51679),
        new Point(47.99374, 89.08514),
        new Point(47.88791, 90.07096),
        new Point(46.95221, 90.9136),
        new Point(46.57735, 91.07027),
        new Point(46.29694, 90.92151),
        new Point(46.01735, 91.02651),
        new Point(45.57972, 90.68193),
        new Point(45.07729, 91.56088),
        new Point(44.95721, 93.5547),
        new Point(44.35499, 94.71735),
        new Point(44.29416, 95.41061),
        new Point(43.99311, 95.53339),
        new Point(42.73499, 96.38206),
        new Point(42.79583, 97.1654),
        new Point(42.57194, 99.51012),
        new Point(42.67707, 100.8425),
        new Point(42.50972, 101.8147),
        new Point(42.23333, 102.0772),
        new Point(41.88721, 103.4164),
        new Point(41.87721, 104.5267),
        new Point(41.58666, 105.0065),
        new Point(42.46624, 107.4758),
        new Point(42.42999, 109.3107),
        new Point(42.64576, 110.1064),
        new Point(43.31694, 110.9897),
        new Point(43.69221, 111.9583),
        new Point(44.37527, 111.4214),
        new Point(45.04944, 111.873),
        new Point(45.08055, 112.4272),
        new Point(44.8461, 112.853),
        new Point(44.74527, 113.638),
        new Point(45.38943, 114.5453),
        new Point(45.4586, 115.7019),
        new Point(45.72193, 116.2104),
        new Point(46.29583, 116.5855),
        new Point(46.41888, 117.3755),
        new Point(46.57069, 117.425),
        new Point(46.73638, 118.3147),
        new Point(46.59895, 119.7068),
        new Point(47.66499, 119.125),
        new Point(47.99475, 118.5393),
        new Point(48.01125, 117.8046),
        new Point(47.65741, 117.3827),
        new Point(47.88805, 116.8747),
        new Point(47.69186, 115.9231),
        new Point(48.14353, 115.5491),
        new Point(49.83047, 116.7114),
        new Point(49.52058, 117.8747),
        new Point(49.92263, 118.5746),
        new Point(50.09631, 119.321),
        new Point(51.62083, 120.0641),
        new Point(52.115, 120.7767),
        new Point(52.76819, 120.0314),
        new Point(53.26374, 120.8307),
        new Point(53.54361, 123.6147),
        new Point(53.18832, 124.4933),
        new Point(53.05027, 125.62),
        new Point(52.8752, 125.6573),
        new Point(52.75722, 126.0968),
        new Point(52.5761, 125.9943),
        new Point(52.12694, 126.555),
        new Point(51.99437, 126.4412),
        new Point(51.38138, 126.9139),
        new Point(50.74138, 127.2919),
        new Point(49.80588, 127.515),
        new Point(49.58443, 128.7119),
        new Point(49.34676, 129.1118),
        new Point(48.86464, 130.2246),
        new Point(48.60576, 130.5236),
        new Point(47.68721, 130.9922),
        new Point(47.71027, 132.5211),
        new Point(48.09888, 133.0827),
        new Point(48.06888, 133.4843),
        new Point(48.39112, 134.4153),
        new Point(47.99207, 134.5576),
        new Point(46.64017, 133.9977),
        new Point(45.82347, 133.4761),
        new Point(45.05694, 133.0253),
        new Point(45.34582, 131.8684),
        new Point(44.97388, 131.4691),
        new Point(44.83649, 130.953),
        new Point(44.05193, 131.298),
        new Point(43.53624, 131.1912),
        new Point(42.91645, 131.1285),
        new Point(42.74485, 130.4327),
        new Point(43.00457, 129.9046),
        new Point(42.43582, 129.6955),
        new Point(42.02736, 128.9269),
        new Point(41.58284, 128.3002),
        new Point(41.47249, 127.2708),
        new Point(41.79222, 126.9047),
        new Point(40.89694, 126.0118),
        new Point(40.47037, 124.8851),
        new Point(39.82777, 124.128),
        new Point(39.8143, 123.2422),
        new Point(38.99638, 121.648),
        new Point(38.91221, 121.0887),
        new Point(39.09013, 121.6794),
        new Point(39.52847, 121.2283),
        new Point(39.81138, 121.4683),
        new Point(40.00305, 121.881),
        new Point(40.50562, 122.2987),
        new Point(40.92194, 121.1775),
        new Point(40.1961, 120.4468),
        new Point(39.87242, 119.5264),
        new Point(39.15693, 118.9715),
        new Point(39.19846, 117.889),
        new Point(38.67555, 117.5364),
        new Point(38.38666, 117.6722),
        new Point(38.16721, 118.0281),
        new Point(37.87832, 119.0355),
        new Point(37.30054, 118.9566),
        new Point(37.14361, 119.2328),
        new Point(37.83499, 120.7371),
        new Point(37.42458, 121.58),
        new Point(37.55256, 122.1282),
        new Point(37.39624, 122.5586),
        new Point(37.02583, 122.4005),
        new Point(36.89361, 122.5047),
        new Point(37.00027, 121.9566),
        new Point(36.75889, 121.5944),
        new Point(36.61666, 120.7764),
        new Point(36.37582, 120.8753),
        new Point(36.14075, 120.6956),
        new Point(36.26345, 120.3078),
        new Point(35.95943, 120.2378),
        new Point(35.57893, 119.6475),
        new Point(34.88499, 119.1761),
        new Point(34.31145, 120.2487),
        new Point(32.97499, 120.8858),
        new Point(32.42958, 121.3348),
        new Point(32.02166, 121.7066),
        new Point(31.86639, 120.9444),
        new Point(32.09361, 120.6019),
        new Point(31.94555, 120.099),
        new Point(32.30638, 119.8267),
        new Point(32.26277, 119.6317),
        new Point(31.90388, 120.1364),
        new Point(31.81944, 120.7196),
        new Point(31.30889, 121.6681),
        new Point(30.97986, 121.8828),
        new Point(30.56889, 120.9915),
        new Point(30.39298, 120.4586),
        new Point(30.31027, 120.5082),
        new Point(30.30458, 121.2808),
        new Point(29.96305, 121.6778),
        new Point(29.88211, 122.1196),
        new Point(29.51167, 121.4483),
        new Point(29.19527, 121.9336),
        new Point(29.19729, 121.7444),
        new Point(29.02194, 121.6914),
        new Point(28.9359, 121.4908),
        new Point(28.66993, 121.4844),
        new Point(28.38277, 121.1651),
        new Point(27.98222, 120.9353),
        new Point(28.07944, 120.5908),
        new Point(27.87229, 120.84),
        new Point(27.20777, 120.5075),
        new Point(26.89805, 120.0332),
        new Point(26.51778, 119.8603),
        new Point(26.78823, 120.0733),
        new Point(26.64888, 119.8668),
        new Point(26.44222, 119.8204),
        new Point(26.36777, 119.9489),
        new Point(25.99694, 119.4253),
        new Point(26.14041, 119.0975),
        new Point(25.93788, 119.354),
        new Point(25.35333, 119.6454),
        new Point(25.17208, 119.2726),
        new Point(25.2426, 118.8749),
        new Point(24.97194, 118.9866),
        new Point(24.52861, 118.5953),
        new Point(24.44024, 118.0199),
        new Point(24.46019, 117.7947),
        new Point(24.25875, 118.1237),
        new Point(23.62437, 117.1957),
        new Point(23.65919, 116.9179),
        new Point(23.21083, 116.5139),
        new Point(22.93902, 116.4817),
        new Point(22.73916, 115.7978),
        new Point(22.65889, 115.5367),
        new Point(22.70277, 114.8889),
        new Point(22.69972, 114.5208),
        new Point(22.26056, 114.2961),
        new Point(22.36736, 113.9056),
        new Point(22.50874, 114.0337),
        new Point(22.47444, 113.8608),
        new Point(23.05083, 113.4793),
        new Point(22.18701, 113.5527),
        new Point(22.20485, 113.0848),
        new Point(21.8693, 112.94),
        new Point(21.70139, 112.2819),
        new Point(21.91611, 111.8921),
        new Point(21.61264, 111.7832),
        new Point(21.52528, 111.0285),
        new Point(21.21138, 110.5328),
        new Point(20.84381, 110.1594),
        new Point(20.24611, 110.2789),
        new Point(20.2336, 109.9244),
        new Point(20.4318, 110.0069),
        new Point(20.92416, 109.6629),
        new Point(21.44694, 109.9411),
        new Point(21.49499, 109.5344),
        new Point(21.58305, 109.1375),
        new Point(21.61611, 108.911),
        new Point(21.93562, 108.4692),
        new Point(21.51444, 108.2447),
        new Point(21.54241, 107.99),
        new Point(21.60526, 107.3627),
        new Point(22.03083, 106.6933),
        new Point(22.86694, 106.7029),
        new Point(22.91253, 105.8771),
        new Point(23.32416, 105.3587),
        new Point(23.18027, 104.9075),
        new Point(22.81805, 104.7319),
        new Point(22.50387, 103.9687),
        new Point(22.43652, 103.0304),
        new Point(22.77187, 102.4744),
        new Point(22.49777, 101.7415),
        new Point(21.83444, 101.7653),
        new Point(21.17687, 101.2919),
        new Point(21.76903, 101.099),
        new Point(21.47694, 100.6397),
        new Point(21.72555, 99.97763),
        new Point(22.15592, 99.16785),
        new Point(23.08204, 99.5113),
        new Point(23.97076, 98.67991),
        new Point(24.16007, 98.89073),
        new Point(23.92999, 97.54762),
        new Point(24.26055, 97.7593),
        new Point(24.73992, 97.55255),
        new Point(25.61527, 98.19109),
        new Point(25.85597, 98.7104),
        new Point(26.12527, 98.56944),
        new Point(26.79166, 98.77777),
        new Point(27.52972, 98.69699),
        new Point(27.54014, 98.31992),
        new Point(28.14889, 98.14499),
        new Point(28.54652, 97.55887),
        new Point(28.46749, 96.65387),
        new Point(28.79569, 96.61373),
        new Point(29.05666, 96.47083),
        new Point(28.90138, 96.17532),
        new Point(29.05972, 96.14888),
        new Point(29.46444, 96.08315),
        new Point(29.03527, 95.38777),
        new Point(29.33346, 94.64751),
        new Point(28.6692, 93.96172),
        new Point(28.3193, 93.22205),
        new Point(28.1419, 92.71044),
        new Point(27.86194, 92.54498),
        new Point(27.76472, 91.65776),
        new Point(27.945, 91.66277),
        new Point(28.08111, 91.30138),
        new Point(27.96999, 91.08693),
        new Point(28.07958, 90.3765),
        new Point(28.32369, 89.99819),
        new Point(27.32083, 88.91693)
    ]);

mainLandPointList.push(
    [new Point(19.52888, 110.855),
        new Point(18.80083, 110.5255),
        new Point(18.39152, 109.7594),
        new Point(18.50562, 108.6871),
        new Point(19.28028, 108.6283),
        new Point(19.76, 109.2939),
        new Point(19.89972, 109.2572),
        new Point(19.99389, 109.6108),
        new Point(20.13361, 110.6655),
        new Point(19.97861, 110.9425),
        new Point(19.63829, 111.0215),
        new Point(19.52888, 110.855)
    ]);//Hainan

mainLandPointList.push([
    new Point(31.80054, 121.2039),
    new Point(31.49972, 121.8736),
    new Point(31.53111, 121.5464),
    new Point(31.80054, 121.2039)
]);
let HongKongPointList = [
    [22.505579256572318, 114.03396606445312],
    [22.488767903304346, 113.99757385253906],
    [22.406584955479804, 113.90144348144531],
    [22.22491229274342, 113.83243560791016],
    [22.196941696064897, 113.84239196777344],
    [22.1556113012395, 114.25369262695312],
    [22.368491419814717, 114.38827514648438],
    [22.415471945522366, 114.40338134765625],
    [22.436417600763114, 114.39857482910155],
    [22.504310546471817, 114.36149597167969],
    [22.55187920514417, 114.31205749511719],
    [22.554415740003208, 114.29008483886717],
    [22.543318055728953, 114.2251968383789],
    [22.548074315418155, 114.22622680664062],
    [22.553464544896258, 114.22107696533203],
    [22.55631811054147, 114.20631408691406],
    [22.554732803581434, 114.17781829833984],
    [22.561073922102242, 114.16786193847656],
    [22.561073922102242, 114.16065216064453],
    [22.54997677339611, 114.15035247802733],
    [22.541732599409258, 114.14623260498047],
    [22.542049692130014, 114.12872314453125],
    [22.52873117079854, 114.11087036132811],
    [22.536659016820167, 114.09198760986328],
    [22.529048293377087, 114.07928466796874],
    [22.524608511026262, 114.08409118652344],
    [22.514459901335098, 114.07722473144531],
    [22.513825588484632, 114.0597152709961],
    [22.50240745949775, 114.05319213867188],
    [22.505579256572318, 114.03396606445312]
];

