/**
 * 修改本配置文件，请同步修改/doc/I18n_setting_map.xlsx文件
 */
const common = {
    singular: { // 单数
        hour: '@:units.hours',
        driver: '@:way2Call.driver',
        fleet: '@:way2Call.fleet',
        vehicle: '@:way2Call.vehicle',
        alert: '@:userAccounts.alert',
        collisionAlert: '@:dashboard.collisionAlert',
        driverAlert: '@:dashboard.driverAlert',
        parkingAlert: '@:dashboard.parkingAlert',
        unpermittedDriver: '@:dashboard.unpermittedDriver',
    },
    plural: { // 复数
        hours: '@:units.hours',
        drivers: '@:userAccounts.drivers',
        fleets: '@:userAccounts.fleets',
        vehicles: '@:userAccounts.vehicles',
        alerts: '@:alertList.alerts',
        collisionAlerts: '@:dashboard.collisionAlert',
        driverAlerts: '@:dashboard.driverAlert',
        parkingAlerts: '@:dashboard.parkingAlert',
        unpermittedDrivers: '@:dashboard.unpermittedDriver',
    },
    headerTitle: {
        //header左上角功能模块
        dashboard: '@:dashboard.dashboard',
        vehicleManagement: '@:fleet.vehicleManagement',
        addFleet: '@:fleet.addFleet',
        editFleet: '@:fleet.editFleet',
        addVehicle: '@:fleet.addVehicle',
        vehicle: '@:way2Call.vehicle',
        editVehicle: '@:vehicle.editVehicle',
        driverManagement: '@:driver.driverManagement',
        driver: '@:way2Call.driver',
        addDriver: '@:driver.addDriver',
        editDriver: '@:driver.editDriver',
        account: 'Account',
        role: 'Role',
        liveTracking: '@:liveTracking.liveTracking',
        tripHistory: '@:tripHistory.tripHistory',
        settings: '@:settings.settings'
    }
};
const units = {
    hrs:"小時",
    hours:"小時",
    km:"公里",
    kilometers:"公里",
    mi:"英里",
    miles:"英里",
    l:"公升",
    liters:"公升",
    gal:"加侖",
    gallons:"加侖",
    t:"公噸",
    cID:"立方英寸",
    hrsPerTrip:"小時/旅程",
    kmPerTrip:"公里/旅程",
    miPerTrip:"英里/旅程",
    kmPerL:"公里/公升",
    mpg:"英里/加侖",
    kmPerHr:"公里/小時",
    mph:"英里/小時",
    in:"英吋",
    cm:"公分",
    day:"天 | 天"
};
const globalText = {
    VIA_FLEET:"VIA FLEET",//TODO
    noData:"無數據",
    unassigned:"未分配",
    unknown:"未知",
    oK:"確認",
    cancel:"取消",
    delete:"刪除",
    save:"保存",
    apply:"同意",
    select:"選擇",
    all:"全選",
    loading:"加載中.....",
    uploading:"上傳中",
    fileNotfound:"未找到文件",
    edit:"編輯",
    none:"無",
    aMaximumOf_X_CharactersCanBeUsed:"應少於{num}字元。",
    itShouldBeFormedBy_X:"應由{value}組成。",
    theValueCanHaveOneDecimalPlaceOnly:"數值只支援一位小數。",
    theValueShouldBeBetween_X_And_Y:"數值應在{min}～{max}之間。",
    mustContainBetween_X_To_Y_Characters:"字符應在{min}～{max}之間。",
    savedSuccessfully:"儲存成功。",
    failedToSave:"儲存失敗。",
    confirmDeletion:"確認刪除。",
    deletedSuccessfully:"删除成功。",
    failedToDelete:"删除失败。",
    requestTimeout:"請求逾時。",
    timeoutPleaseLoginAgain:"逾時，請重新登入。",
    na:'N/A',
    networkError:'網絡請求失敗',
    newText:'新'
};
const popUpMsg = {
    login: {
        loginFailedPleaseTryAgain:"登入失敗，請重試一次。"
    },
    resetPwd: {
        emailOrUserIdDoesntExist:"電子郵件或使用者名稱不存在。",
        wrongVerificationCode:"驗證碼錯誤。",
        yourPasswordHasBeenUpdatedPleaseLogin:"密碼已更新，請重新登入。",
        failedToResetPassword:"重置密碼失敗。",
    },
    settings:{
        aiSettingChanged:'新的AI設定將應用到所有的VIA Mobile360 {deviceModel} 設備。'
    },
    account: {
        wrongPassword:"密碼錯誤。",
        failedToChangePassword:"修改密碼失敗。",
    },
    liveTracking: {
        vehicleName_PlateNumber_IsOffRoad:"{vehicleName}({plateNumber})已離線。",
        theTrailTimeRemainingFor_VehicleName_PlateNumber_Is_X_Days:"{vehicleName}({plateNumber})試用時間剩餘{day}天。",
        theKvsTimeRemainingFor_VehicleName_PlateNumber_Is_X_Seconds:"{vehicleName}({plateNumber})KVS試用時間剩餘{seconds}秒。",
        vehicleName_PlateNumber_IsOnRoad:"{vehicleName}已上線。",
        audioInputDeviceIsNotReady:"音頻輸入裝置未就緒。",
        cannotReach_DriverName_NowPleaseTryLater:"無法連接<strong>{driverName}</strong>，請稍後再試。",
        connectionTo_DriverName_IsLost:"與 <strong>{driverName}</strong> 連接中斷。",
        terminateTheCallTo_DriverNameOfTheCurrentCall_AndCall_DriverNameOfTheNewCall:"終止與 <strong>{currentDriverName}</strong>的通話，並呼叫 <strong>{newDriverName}</strong>？",
        driverName_IsBusyNow:"<strong>{DriverName}</strong> 忙線中。",
        terminateTheCallTo_DriverNameOfTheCurrentCall_AndLogout:"終止與{DriverName}的通話，並登出？",
    },
    fleet: {
        fleetName_AlreadyExists:"{fleetName} 已經存在。",
    },
    vehicles: {
        vehicleName_AlreadyExists:"{vehicleName} 已經存在。",
        thisPlateNumber_PlateNumber_AlreadyExists:"車牌號碼{plateNumber}已經存在。",
        confirmUnregisterationNoMoreDataWillBeSentFromThisVehicle:"確認解除綁定。本車輛不再上傳數據。",
        unregisteredSuccessfully:"解除綁定成功。",
        failedToUnregister:"解除綁定失敗。",
        seriesPlatform: "VIA Mobile360系列平台",
        selectVIAPlatform: "請選擇將被安裝到此車輛的VIA Mobile360平台型號。",
        completeADAS: "請先完成駕駛輔助系统校準。",
        completeDMS:  "請先重駕駛監控系統校準。"
    },
    drivers: {
        driverName_AlreadyExists:"{driverName}已經存在。",
        thisLicenseNumber_LicenseNumber_AlreadyExists:"駕照號碼{LicenseNumber}已經存在。",
        thisLoginId_LoginId_AlreadyExists:"使用者名稱{loginID}已經存在。",
        failedToLoadImageFromCloud:"雲端下載圖片失敗。",
    },
    rtcPop: {
        terminateTheCallTo_DriverNameOfTheCurrentCall_AndCall_DriverNameOfTheNewCall: "@:popUpMsg.liveTracking.terminateTheCallTo_DriverNameOfTheCurrentCall_AndCall_DriverNameOfTheNewCall",
        connectionTo_DriverName_IsLost: "@:popUpMsg.liveTracking.connectionTo_DriverName_IsLost"
    }
};
const tips = {
    login: {
        pleaseInputYourEmailOrUserId:"請輸入電子郵件或使用者名稱。",
    },
    resetPwd: {
        pleaseInputThePassword:"請輸入密碼。",
        pleaseInputTheVeficationCode:"請輸入驗證碼。",
        pleaseInputTheNewPassword:"請輸入新密碼。",
        theNewPasswordIsTheSameAsTheOldPassword:"新密碼與舊密碼相同",
        pleaseInputTheNewPasswordAgain:"請再次輸入新密碼。",
        theTwoNewPasswordsAreNotTheSame:"兩次輸入密碼不一致。",
    },
    account: {
        pleaseInputAContactNumber:"請輸入聯絡電話",
        pleaseInputAddress:"請輸入地址",
    },
    fleet: {
        pleaseInputAFleetName:"請填寫車隊名稱。",
    },
    vehicles: {
        pleaseInputAVehicleName:"請填寫車輛名稱。",
        pleaseInputAPlateNumber:"請填寫車牌號碼。",
        select:"請選擇",
        pleaseInputTheFuelTankCapacity:"請填寫油箱容量。",
        pleaseSelectVehicleWeight:"請選擇車重。",
        pleaseInputTheEngineDisplacement:"請填寫排氣量。",
        theDistanceShouldBeBetween_X_Y:"高度應在{min}~{max}之間",
        pleaseInputTheVehicleHeight:"請填寫車高",
        pleaseCorrectTheVehicleHeight:"請正確填寫車高",
        pleaseInputTheVehicleWidth:"請填寫車寬",
        pleaseInputTheHoodLength:"請填寫車頭長度",
        TheValueShouldBeNoMoreThan300:"數值不能大於300",
        theAboveFieldsAreRequiredToEnableAiFeatures:"需要填寫以上訊息才能啟動AI功能",
        searchVehicleByVehicleNamePlateNumberOrVehicleModel :"輸入車輛名稱.車牌號碼或車輛型號以查找",
    },
    drivers: {
        pleaseInputAFirstName:"請填寫名稱",
        thereIsASpaceInTheFirstNamePleaseDeleteIt:"名稱不支援空格，請刪除",
        pleaseInputALastName:"請填寫姓氏",
        thereIsASpaceInTheLastNamePleaseDeleteIt:"姓氏不支援空格，請刪除",
        pleaseInputAContactNumber:"請填寫聯絡號碼",
        pleaseInputTheLicenseNumber:"請填寫駕照號碼",
        pleaseInputALoginId:"請填寫使用者名稱",
        thereIsASpaceInTheLoginIdPleaseDeleteIt:"使用者名稱不支援空格，請刪除",
        searchDriverByDriverNameContactNumberUserIDOrLicenseNumber :"輸入司機名稱、聯絡電話、使用者名稱或駕照號碼以查找",
    },
    imageSizeTips:'文件應小於6兆字節。'
};
const login = {
    VIAMobile360: "VIA Mobile360",
    welcome: "歡迎使用VIA Fleet",
    portalLanguage: "語言",//TODO 英文字段修改，简中和繁中未提供
    alertLanguage: " 警報語言",
    english: "繁體中文",
    loginName: "登錄帳號",
    emailOrUserID: "電子郵件或使用者名稱",
    password: "密碼",
    signIn: "登入",
    getItOnGooglePlay: "從Google Play下載",
    availableOnTheAppStore: "從App Store下載",
    copyright: "版權所有@{year}威盛電子",
    allRightsReserved: "保留所有權利",
    version: "版本{value}",
    timezone: "時區",
    forgotPassword:"忘記密碼?",
    privacyPolicy:"隱私政策"
};
const way2Call = {
    way2Call: "雙向通話",
    notifications: "通知",
    missedCalls: "未接來電",
    showMsgFor1hour: "此頁面僅顯示最近1小時內呼叫請求",
    showMsgFor24hours: "此頁面僅顯示最近24小時內呼叫請求",
    vehicle: "車輛",
    fleet: "車隊",
    driver: "司機",
    time: "時間",
    location: "位置",
    log: "日誌",
    logEvent: "事件日誌",
    status: "狀態",
    newTrip:'New Trip',//TODO
    callRequest:'通話請求'
};
const alertList = {
    alerts: "警報",
    type: "類型",
    showMsgFor24hours: "此頁面僅顯示最近24小時內警報",
    vehicle: "@:way2Call.vehicle",
    fleet: "@:way2Call.fleet",
    collisionAlert: '@:common.singular.collisionAlert',
    driverAlert: '@:common.singular.driverAlert',
    parkingAlert: '@:common.singular.parkingAlert',
    unpermittedDriver: '@:common.singular.unpermittedDriver',
    location: "@:way2Call.location",
    driver: "@:way2Call.driver",
    time: "@:way2Call.time",
};
const myAccount = {
    myAccount: "我的帳號",
    company: "公司",
    loginName: "登錄名稱",
    userID: "使用者名稱",
    name: "姓名",
    email: "電子郵件",
    keyContact: "主要聯絡人",
    contactNumber: "聯絡電話",
    address: "地址",
    editAccount: "編輯帳號",
    changePassword: "修改密碼",
    oldPassword: "舊密碼",
    newPassword: "新密碼",
    reEnterNewPassword: "再次輸入新密碼",
    jobTitle: "職稱",
};
const settings = {
    settings: "設定",
    globalSettings: "全域設定",
    unitSettings: "單位設定",
    unitOfMeasure: "單位量測",
    metric: "公制",
    imperial: "英制",
    USCustomary: "美制",
    timeFormat: "時間格式",
    YYYY_MM_DD: "年-月-日",
    MM_DD_YYYY: "月-日-年",
    DD_MM_YYYY: "日-月-年",
    driverRegistrationMethod: "司機識別方式",
    driverLoginSettings: "司機登錄位置",
    loginMethod: "登入方式",
    QRCode: "二維碼",
    QRCodeOrFaceID: "二維碼或人臉識別",
    loginScanTime: "登錄掃描時間",
    loginPictureDelay: "拍攝延遲時間",
    secs: "秒",
    audioSettings: "聲音設定",
    tripAudioRecording: "行程錄音",
    way2CallVolume: "雙向通話音量",
    min: "最小",
    max: "最大",
    way2CallRecording: "雙向通話錄音",
    alertVolume: "警報音量",
    videoAlertSettings: "錄影警報設置",
    alertRecordingDuration: "警報錄影時長",
    alertSensitivity: "警報靈敏度",
    low: "低",
    medium: "中",
    high: "高",
    deviceSettings: "設備設定",
    aISettings: "智能駕駛設定",
    ADASAudioAlerts: "駕駛輔助系統警報音",
    LDWAudioAlert: "車道偏移警報音",
    FCWAudioAlert: "前車碰撞警報音",
    FCWAlertSensitivity: "前車碰撞警報靈敏度",
    DMS: "駕駛行為",
    distractedDrivingAudioAlert: "分心駕駛警報音",
    distractedDrivingAlert: "分心駕駛警報",
    driverFatigueAudioAlert: "疲勞駕駛警報音",
    driverFatigueAlert: "疲勞駕駛警報",
    phoneUsageAudioAlert: "使用手機警報音",
    phoneUsageAlert: "使用手機警報",
    smokingAudioAlert: "抽煙警報音",
    smokingAlert: "抽煙警報",
    applySettings: "應用設定",
    selectWhichFleetsToApplyTheChangesTo: "選擇需要更新設定的車隊",
    selectToOverrideAllSettingsForTheChosenFleets: "覆蓋已選車隊的所有設定",
    pressOKToApplyTheChanges: "點擊“確認”應用這些變更",
    firmwareUpgrade: "韌體升級",
    currentFirmwareVersion: "當前韌體版本",
    newVersionAvailable: "新版本已發佈！",
    releaseNotes: "發佈說明",
    upgrade: "升級",
    upgradeFirmware: "升級韌體",
    newFirmWareAvailableFor: "{name}的新韌體已發佈。",
    pressOKToUpgradeYourMobile360D700Devices: "點擊“確認”升级{device}設備",
    firmwareVersion: "韌體版本",
    upgradeNow: "現在升級",
    version: '@:login.version',
    LDW: "@:tripHistory.LDW ",
    FCW: "@:tripHistory.FCW ",
    driverFatigue: "@:tripHistory.driverFatigue ",
    smoking: "@:tripHistory.smoking ",
    distractedDriving: "@:tripHistory.distractedDriving ",
    phoneUsage: "@:tripHistory.phoneUsage",
    bsdAudioAlert:'盲點偵測警報音',
    GSensorEventSensitivity:'G-Sensor事件靈敏度',
    GeneralSettings:'一般設定',
    RecordSettings:'Record Settings',//TODO 待提供
    alertSettings:'警報設置',
    parkingAndCollisionSensitivity:'停車和碰撞警報靈敏度',
    partner_program:'合作夥伴计画',
    partner_program_content1:'The Partner Feedback Loop enables VIA to receive system critical log files and event videos generated during a customer’s free VIA Fleet Portal trial period in order to sync up customer feedback and continually improve the customer trail run experience on VIA Mobile360 products. The data collected will only be used to fine tune performance of the system software and AI features which will be passed on to customers through future updates.',
    partner_program_content2:'On average data generated per trip is dependent on the hours and events triggered.  An estimated data transferring size is listed below for Mobile360 series product.  The data is passed through the mobile data plan used on the device. If you do not wish to participate in this program you can disable the options below.',
    log_files:'Log Files',
    event_videos:'Event Videos'
};
const billing = {
    billing: "帳單",
};
const userAccounts = {
    userAccounts: "使用者帳號",
    users: "使用者",
    emailAddress: "電子郵件信箱",
    userRole: "用戶角色",
    fleet: "車隊",
    addUser: "新增使用者",
    firstName: "名",
    lastName: "姓",
    userPermissions: "使用者權限",
    mobile360AppSettings: "Mobile360 APP設定",
    alert: "警報",
    deviceRegistration: "設備綁定",
    deviceCalibration: "設備校準",
    uploadFRPhotos: "上傳人臉識別照片",
    management: "管理",
    fleets: "車隊",
    vehicles: "車輛",
    drivers: "司機",
    edit: "編輯",
    add: "新增",
    roles: "角色",
    roleName: "角色名稱",
    administrator: "管理員",
    fleetManager: "車隊經理",
    purchaser: "採購",
};
const logout = {
    logout: "登出",
};
const liveTracking = {
    liveTracking: "實時追蹤",
    onRoad: "在線",
    offRoad: "離線",
    vehicleNameOrPlateNumber: "車輛名稱或車牌號碼",
    information: "信息",
    fileNotFound: "找不到文件",
    liveVideo: "實時影像",
    connecting: "正在連接",
    unableToConnect: "無法連接",
    G4ConnectionLost: "4G連接中斷",
    KVSTimeUsedUp: "KVS時間已用完",
    GPS: "全球定位系統",
    zoomIn: "放大",
    zoomOut: "縮小",
    ft: "英尺",
    mi: "英里",
    m: "公尺",
    km: "公里",
    unassigned: '@:globalText.unassigned',
    unassignedVehicles: '@:fleet.unassignedVehicles',
    alerts: '@:common.plural.alerts',
    way2Call: '@:way2Call.way2Call',
    collisionAlerts: '@:dashboard.collisionAlerts',
    driverAlerts: '@:dashboard.driverAlert',
    parkingAlerts: '@:dashboard.parkingAlerts',
    unpermittedDrivers: '@:dashboard.unpermittedDriver',
    plateNumber: "@:vehicle.plateNumber",
    fleet: "@:userAccounts.fleet",
};
const dashboard = {
    dashboard: "數據統計",
    days7: "7天",
    days30: "30天",
    month: "12個月",
    january: "一月",
    february: "二月",
    march: "三月",
    april: "四月",
    may: "五月",
    june: "六月",
    july: "七月",
    august: "八月",
    september: "九月",
    october: "十月",
    november: "十一月",
    december: "十二月",
    mon: "周一",
    tue: "周二",
    wed: "周三",
    thu: "周四",
    fri: "周五",
    sat: "周六",
    sun: "周日",
    hoursDriven: "行駛時長",
    distanceDriven: "行駛里程",
    fuelConsumption: "油耗",
    total: "總計",
    avg: "平均",
    collisionAlerts: "碰撞警報",
    parkingAlerts: "停車警報",
    driverScore: "駕駛分數",
    average: "平均",
    totalHoursDriven: "總行駛時長",
    totalDistanceDriven: "總行駛里程",
    totalFuelConsumption: "總油耗",
    collisionAlert: "碰撞警報",
    driverAlert: "司機警報",
    parkingAlert: "停車警報",
    unpermittedDriver: "未許可司機",
    rankingsByVehicle: "車輛排行",
    rankingsByDriver: "司機排行",
    vehicleName: "車輛名稱",
    driverName: "駕駛姓名",
    time: "時長",
    distance: "距離",
    fuel: "油耗",
    unassigned: '@:globalText.unassigned',
    unassignedVehicles: '@:fleet.unassignedVehicles',
    hours: '@:common.plural.hours',
    jan:'一月',
    feb:'二月',
    mar:'三月',
    apr:'四月',
    jun:'六月',
    Jul:'七月',
    aug:'八月',
    sep:'九月',
    oct:'十月',
    nov:'十一月',
    dec:'十二月',
    newAlert: '新警報',
    newCollisionAlert: '新碰撞警報',
    newDriverAlert: '新司機警報',
    newParkingAlert: '新停車警報'
};
const tripHistory = {
    tripHistory: "行程歷史",
    totalTime: "總時長",
    totalIdleTime: "總怠速時長",
    distance: "距離",
    avgFuelConsumption: "平均油耗",
    totalIdleFuelConsumption: "總怠速油耗",
    totalFuelConsumption: "總油耗",
    avgDriverScore: "平均駕駛分數",
    times: "次",
    driver: "司機",
    headquarters: "總部",
    gSensorEvents: "G-Sensor事件",
    hardRevving: "急加速",
    hardBraking: "急減速",
    hardCornering: "急轉彎",
    ADASEvents: "駕駛輔助系統警報",
    LDW: "車道偏移警報",
    FCW: "前車碰撞警報",
    DMSEvents: "駕駛監控警報",
    driverFatigue: "疲勞駕駛",
    distractedDriving: "分心駕駛",
    smoking: "抽煙",
    phoneUsage: "使用手機",
    trip: "行程",
    start: "起點",
    end: "終點",
    idleTime: "怠速時長",
    avgSpeed: "平均速度",
    maxSpeed: "最大速度",
    idleFuelConsumption: "怠速油耗",
    way2CallFromDriver: "司機通話請求",
    way2CallFromHeadquarter: "總部通話紀錄",
    searchType: {
        driver: '@:common.singular.driver',
        fleet: '@:common.singular.fleet',
        vehicle: '@:common.singular.vehicle',
        alert: '@:common.singular.alert',
    },
    alertList: {
        collisionAlert: '@:common.singular.collisionAlert',
        driverAlert: '@:common.singular.driverAlert',
        parkingAlert: '@:common.singular.parkingAlert',
        unpermittedDriver: '@:common.singular.unpermittedDriver',
        all: '@:globalText.all',
    },
    alert: '@:common.singular.alert',
    alerts: '@:common.plural.alerts',
    fleetName: '@:fleet.fleetName',
    driverName: '@:dashboard.driverName',
    collisionAlert: '@:common.singular.collisionAlert',
    collisionAlerts: '@:dashboard.collisionAlerts',
    driverAlert: '@:common.singular.driverAlert',
    driverAlerts: '@:dashboard.driverAlert',
    parkingAlerts: '@:dashboard.parkingAlerts',
    parkingAlert: '@:common.singular.parkingAlert',
    unpermittedDriver: '@:common.singular.unpermittedDriver',
    unpermittedDrivers: '@:dashboard.unpermittedDriver',
    driverScore: '@:dashboard.driverScore',
    way2Call: '@:way2Call.way2Call',
    vehicleNameOrPlateNumber: '@:liveTracking.vehicleNameOrPlateNumber',
};
const fleet = {
    vehicleManagement: "車輛管理",
    addFleet: "添加車隊",
    addVehicle: "添加車輛",
    fleetName: "車隊名稱",
    totalVehicles: "車輛總數",
    totalDrivers: "司機總數",
    description: "說明",
    unassignedVehicles: "未分配車輛",
    editFleet: "編輯車輛",
    vehicleList: "車輛列表",
    fleetSettings: "車隊設定",
    noUnassignedVehicle:'沒有未分配車輛',
    noVehicleAssigned:'沒有分配車輛'
};
const vehicle = {
    vehicleName: "車輛名稱",
    plateNumber: "車牌號碼",
    vehicleModel: "車輛型號",
    permittedDrivers: "許可司機",
    registered: "已綁定",
    trialTimeDays: "試用時間",
    day:"天",
    KVSTimeHMS: "KVS時間",
    hms:'時：分：秒',
    deviceAndFirmware: '設備和韌體',
    addVehicle: "增加車輛",
    editVehicle: "編輯車輛",
    vehicleInformation: "車輛訊息",
    fuelTankCapacity: "油箱容量",
    fuelType: "汽油種類",
    vehicleWeight: "車重",
    tonRange: "1.8噸以下，1.8~3.4噸，3.4噸以上",
    displacement: "排氣量",
    vehicleLicense: "車牌號碼",
    deviceInformation: "設備訊息",
    deviceModel: "設備型號",
    unregister: "解除綁定",
    SIMCard: "SIM卡",
    AIFeatures: "AI功能",
    vehicleHeight: "車高",
    distanceFromCameraToRoof: "攝影機到車頂的距離",
    driverSeat: "道路通行方向",
    left: "左駕",
    right: "右駕",
    vehicleWidth: "車寬",
    hoodLength: "車頭長",
    ADASCalibration: "駕駛輔助系統校準",
    DMSCalibration: "駕駛監控系統校準",
    trialPeriodInformation: "試用時間",
    trialTimeRemaining: "剩餘試用時間",
    KVSTimeRemaining: "剩餘KVS時間",
    search: "搜索...",
    searchHover: '輸入車輛名稱、車牌號碼或車輛型號以查找',
    fleetName: "@:fleet.fleetName",
    totalVehicles: "@:fleet.totalVehicles",
    totalDrivers: "@:fleet.totalDrivers",
    description: "@:fleet.description",
    vehicleList: "@:fleet.vehicleList",
    fleet: "@:way2Call.fleet",
    LDW: "@:tripHistory.LDW",
    FCW: "@:tripHistory.FCW",
    DMS: "駕駛行為輔助攝影機",
    DVR:'DVR',
    camera_one:'攝影機 #1 ',
    camera_two:'攝影機 #2 ',
    camera_three:'攝影機 #3 ',
    camera_four:'攝影機 #4 ',
    camera_five:'攝影機 #5 ',
    camera_six:'攝影機 #6 ',
    camera_seven:'攝影機 #7 ',
    camera_eight:'攝影機 #8 ',
    camera_nine:'攝影機 #9 ',
    SVSCalibration:'環景系統校準',
    pedestrianDetection:'行人偵測',
    enabledRear:'尾部偵測',
    camerasAIFeatures:'攝影機和AI功能',
    disable:'禁用',
    under:"小於 ",
    over:"大於 ",
    t:" 噸",
    Front_LDW:'前置攝影機-車道偏移檢測',
    Front_FCW:'前置攝影機-前車防碰撞檢測',
    Front_LDW_FCW:'前置攝影機-車道偏移與前車防碰撞檢測',
    Left_BSD:'左側攝影機-盲點監測',
    Right_BSD:'右側攝影機-盲點偵測',
    Rear:'後置攝影機',
    Rear_DMOD:'後置攝影機-行人檢測',
    Rear_PAS:'後置攝影機-停車輔助',
    Rear_PAS_DMOD:'後置攝影機-行人檢測和停車輔助',
    Front_SVS:'前置攝影機-環景系統',
    Front_SVS_DMOD:'前置攝影機-環景系統和行人檢測',
    Left_SVS:'左側攝影機-環景系統',
    Right_SVS:'右側攝影機-環景系統',
    Rear_SVS:'後置攝影機-環景系統',
    Rear_SVS_PAS:'後置攝影機-停車輔助',
    Rear_SVS_DMOD:'後置攝影機-環景系統和行人檢測',
    Rear_SVS_PAS_DMOD:'後置攝影機-環景系統，行人檢測與停車輔助',
    Rear_SVS_DMOD_PAS:'後置攝影機-環景系統，停車輔助與行人檢測',
    camera:'',
    parkingAssist: '停車輔助'
};
const driver = {
    driverManagement: "司機管理",
    addDriver: "增加司機",
    driverName: "司機姓名",
    loginID: "登錄名稱",
    contactNumber: "聯絡電話",
    licenseNumber: "駕照號碼",
    permittedVehicles: "允許車輛",
    emergContact: "緊急聯絡人",
    faceIDPhoto: "人臉識別照片",
    editDriver: "編輯司機",
    driverInformation: "司機訊息",
    firstName: "名",
    lastName: "姓",
    dateJoined: "加入日期",
    emergContactNumber: "緊急聯絡人電話",
    driverLicense: "駕駛執照",
    driverPhoto: "司機相片",
    comment: "備註",
    useFaceIDPhoto: "使用人臉識別相片",
    mobile360APPDriverLoginInformation: "Mobile360 APP – 司機登入訊息",
    password: "@:login.password",
    search: "@:vehicle.search",
    searchHover: '輸入司機名稱、聯絡電話、使用者名稱或駕照號碼以查找'
};
export {
    common,
    units,
    globalText,
    popUpMsg,
    tips,
    login,
    way2Call,
    alertList,
    myAccount,
    settings,
    billing,
    userAccounts,
    logout,
    liveTracking,
    dashboard,
    tripHistory,
    fleet,
    vehicle,
    driver
};
