import mapConfig from "../../assets/js/MapConfig";
let {google} = mapConfig;

const EN_US = 'en-US';
const ZH_CN = 'zh-CN';
const ZH_TW = 'zh-TW';
const GOOGLE_LANG_MAP = new Map([
    [EN_US, 'En'],
    [ZH_CN, 'zh-Cn'],
    [ZH_TW, 'zh-tw'],
]);
const KEY_LANGUAGE = 'language';

const state = {
    localLanguage: null,
    English_US: EN_US,
    Chinese_Simple: ZH_CN,
    Chinese_Taiwan: ZH_TW
};

const getters = {
    localLanguage(state) {
        return state.localLanguage || localStorage.getItem(KEY_LANGUAGE);
    },
    googleLangParam(state) {
        let language = state.localLanguage || localStorage.getItem(KEY_LANGUAGE);
        return GOOGLE_LANG_MAP.get(language) || google.language;
    },
    English_US(state) {
        return state.English_US;
    },
    Chinese_Simple(state) {
        return state.Chinese_Simple;
    },
    Chinese_Taiwan(state) {
        return state.Chinese_Taiwan;
    }
};

const mutations = {
    updateLocalLanguage: (state, value) => {
        state.localLanguage = value;
        localStorage.setItem(KEY_LANGUAGE, value);
    }
};

const actions = {};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
};