import Vue from 'vue'
import ElementUI from 'element-ui';
import './assets/css/elementUI/theme/index.css';
import App from './App.vue';
import router from './router';
import store from './store'
import "./assets/js/AxiosConfig.js"
import "./assets/js/Util.js"
import ServerAPI from "./assets/js/ServerAPI"
import "./assets/css/fonts.css";
import Unit from "./assets/js/Unit";
import ElInput from './components/common/elementUI/input';
import Gps from "./assets/js/Gps";
import './components/common/messageBox/messageBox';
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import twLocale from 'element-ui/lib/locale/lang/zh-TW'
import DateFormat from "./assets/js/DateFormat";
import GlobalMixin from "./assets/js/GlobalMixin";

Vue.mixin(GlobalMixin);
Vue.use(VueI18n);
Vue.use(ElementUI, {enLocale});

function InitLanguage() {
    let language = localStorage.getItem('language') || "en-US";
    store.commit('i18n/updateLocalLanguage', language);
    return language;
}

const i18n = new VueI18n({
    locale: InitLanguage(), //
    // 修改messages需要同步修改AxiosConfig.js对应的messages.
    messages: {
        'en-US': Object.assign(require("./assets/lang/en.js"), enLocale),
        'zh-CN': Object.assign(require("./assets/lang/zh-CN.js"), zhLocale),
        'zh-TW': Object.assign(require("./assets/lang/zh-TW.js"), twLocale)
    }
});
locale.i18n((key, value) => i18n.t(key, value));


Vue.config.productionTip = false;
Vue.prototype.$serverApi = ServerAPI;
Vue.prototype.$Gps = Gps;
Vue.prototype.$unit = Unit;
Vue.prototype.$dateFormat = DateFormat;
//for test
Vue.prototype.$baseURL = process.env.NODE_ENV === 'production' ? '/' : 'http://dev.mobile360.viatech.com/';

Vue.component("ElInput", ElInput);//全局自定义组件
new Vue({
    router,
    render: h => h(App),
    i18n,
    store
}).$mount('#app');



