import store from "@/store";

/**
 * 公制和英制的单位换算说明
 * Gallon目前采用的是美制的
 * 1 Kilometer = 0.6214 Mile
 * 1 Gallon    = 4.546092 Liters (英制）
 * 1 Gallon    = 3.785412 Liters (美制）
 * 1 Km/L      = 4.54609 * 0.6214 Mpg = 2.824940326 Mpg
 */

const UNIT_TYPE_METRIC = 'Metric';
const UNIT_TYPE_IMPERIAL = 'Imperial';
const UNIT_TYPE_US_CUSTOMARY = 'US Customary';

// format: const Metric Imperial
const KM = 'Km', MI = 'Mi';
const KM_TRIP = 'Km / Trip', MI_TRIP = 'Mi / Trip';
const L = 'L', GAL = 'Gal';
const CID = 'CID';
const KM_L = 'Km / L', MPG = 'Mpg';
const KM_HR = 'Km / Hr', MPH = 'Mph';
const KILOMETERS = 'Kilometers', MILES = 'Miles';
const KILOMETER = 'Kilometer', MILE = 'Mile';
const LITERS = 'Liters', GALLONS = 'Gallons';
const LITER = 'Liter', GALLON = 'Gallon';
const CM = 'cm', INCH = 'in';

// 公制和英制的单位换算比率
const RATE_KM_MI = 0.6214;
const RATE_GAL_L_IMPERIAL = 4.546092;
const RATE_GAL_L_US = 3.785412;
const RATE_CID_L = 0.01639;
const RATE_L_CID = 61.01;
const RATE_L_GAL_IMPERIAL = 1 / RATE_GAL_L_IMPERIAL;
const RATE_L_GAL_US = 1 / RATE_GAL_L_US;
const RATE_GAL_IMPERIAL_US = RATE_GAL_L_IMPERIAL / RATE_GAL_L_US;
const RATE_GAL_US_IMPERIAL = 1 / RATE_GAL_IMPERIAL_US;
const RATE_KML_MPG_IMPERIAL = RATE_KM_MI * RATE_GAL_L_IMPERIAL;
const RATE_KML_MPG_US = RATE_KM_MI * RATE_GAL_L_US;
const RATE_T_Kilograms_Metric = 1000;
const RATE_T_Kilograms_IMPERIAL = 1016.05;
const RATE_T_Kilograms_US = 907.2;
const RATE_INCH_CM = 2.54;
const RATE_CM_INCH = 1 / RATE_INCH_CM;

function toInter(floatValue) {
    if (Math.floor(floatValue) === floatValue) {
        return {num: floatValue, times: 1};
    }
    // 控制精度防止超出int的最大值
    let tmpNum = Math.pow(10, 5);
    floatValue = Math.round(floatValue * tmpNum) / tmpNum;

    let floatStr = floatValue + '';
    let dotIndex = floatStr.indexOf('.');
    let length = floatStr.substr(dotIndex + 1).length;
    let times = Math.pow(10, length);
    let intNum = parseInt(floatValue * times + 0.5, 10);
    return {num: intNum, times: times};
}

function isI18n(self) {
    return self && self.$t;
}

export default {
    isMetric() {
        return store.getters.unit === UNIT_TYPE_METRIC;
    },
    isIMPERIAL() {
        return store.getters.unit === UNIT_TYPE_IMPERIAL;
    },
    getKmUnit(self) {
        if (
            store.getters.unit === UNIT_TYPE_IMPERIAL ||
            store.getters.unit === UNIT_TYPE_US_CUSTOMARY
        ) {
            if (isI18n(self)) return self.$t('units.mi');
            return MI;
        }
        if (isI18n(self)) return self.$t('units.km');
        return KM;
    },
    getKmTripUnit(self) {
        if (
            store.getters.unit === UNIT_TYPE_IMPERIAL ||
            store.getters.unit === UNIT_TYPE_US_CUSTOMARY
        ) {
            if (isI18n(self)) return self.$t('units.miPerTrip');
            return MI_TRIP;
        }
        if (isI18n(self)) return self.$t('units.kmPerTrip');
        return KM_TRIP;
    },
    getLUnit(self) {
        if (
            store.getters.unit === UNIT_TYPE_IMPERIAL ||
            store.getters.unit === UNIT_TYPE_US_CUSTOMARY
        ) {
            if (isI18n(self)) return self.$t('units.gal');
            return GAL;
        }
        if (isI18n(self)) return self.$t('units.l');
        return L;
    },
    getLOrCIDUnit(self) {
        if (
            store.getters.unit === UNIT_TYPE_IMPERIAL ||
            store.getters.unit === UNIT_TYPE_US_CUSTOMARY
        ) {
            if (isI18n(self)) return self.$t('units.cID');
            return CID;
        }
        if (isI18n(self)) return self.$t('units.l');
        return L;
    },
    getKmLUnit(self) {
        if (
            store.getters.unit === UNIT_TYPE_IMPERIAL ||
            store.getters.unit === UNIT_TYPE_US_CUSTOMARY
        ) {
            if (isI18n(self)) return self.$t('units.mpg');
            return MPG;
        }
        if (isI18n(self)) return self.$t('units.kmPerL');
        return KM_L;
    },
    getKmHrUnit(self) {
        if (
            store.getters.unit === UNIT_TYPE_IMPERIAL ||
            store.getters.unit === UNIT_TYPE_US_CUSTOMARY
        ) {
            if (isI18n(self)) return self.$t('units.mph');
            return MPH;
        }
        if (isI18n(self)) return self.$t('units.kmPerHr');
        return KM_HR;
    },
    getKilometersUnit(self) {
        if (
            store.getters.unit === UNIT_TYPE_IMPERIAL ||
            store.getters.unit === UNIT_TYPE_US_CUSTOMARY
        ) {
            if (isI18n(self)) return self.$tc('units.miles', 2);
            return MILES;
        }
        if (isI18n(self)) return self.$tc('units.kilometers', 2);
        return KILOMETERS;
    },
    getKilometerUnit(self) {
        if (
            store.getters.unit === UNIT_TYPE_IMPERIAL ||
            store.getters.unit === UNIT_TYPE_US_CUSTOMARY
        ) {
            if (isI18n(self)) return self.$tc('units.miles', 1);
            return MILE;
        }
        if (isI18n(self)) return self.$tc('units.kilometers', 1);
        return KILOMETER;
    },
    getLitersUnit(self) {
        if (
            store.getters.unit === UNIT_TYPE_IMPERIAL ||
            store.getters.unit === UNIT_TYPE_US_CUSTOMARY
        ) {
            if (isI18n(self)) return self.$tc('units.gallons', 2);
            return GALLONS;
        }
        if (isI18n(self)) return self.$tc('units.liters', 2);
        return LITERS;
    },
    getLiterUnit(self) {
        if (
            store.getters.unit === UNIT_TYPE_IMPERIAL ||
            store.getters.unit === UNIT_TYPE_US_CUSTOMARY
        ) {
            if (isI18n(self)) return self.$tc('units.gallons', 1);
            return GALLON;
        }
        if (isI18n(self)) return self.$tc('units.liters', 1);
        return LITER;
    },
    getCmUnit(self) {
        if (
            store.getters.unit === UNIT_TYPE_IMPERIAL ||
            store.getters.unit === UNIT_TYPE_US_CUSTOMARY
        ) {
            if (isI18n(self)) return self.$t('units.in');
            return INCH;
        }
        if (isI18n(self)) return self.$t('units.cm');
        return CM;
    },
    convertKilometerUnit(kmNum) {
        if (typeof kmNum !== 'number' || isNaN(kmNum)) return kmNum;
        if (
            store.getters.unit === UNIT_TYPE_IMPERIAL ||
            store.getters.unit === UNIT_TYPE_US_CUSTOMARY
        ) {
            let kmNumObj = toInter(kmNum);
            let rateKmMiObj = toInter(RATE_KM_MI);
            return (kmNumObj.num * rateKmMiObj.num) / (kmNumObj.times * rateKmMiObj.times);
        }
        return kmNum;
    },
    convertLiterUnit(literNum, fromUnit, toUnit) {
        if (isNaN(literNum) || isNaN(parseFloat(literNum))) return literNum;
        if (fromUnit == undefined) fromUnit = UNIT_TYPE_METRIC;
        if (toUnit == undefined)
            toUnit = store.getters.unit;
        if (fromUnit === UNIT_TYPE_METRIC) {
            if (toUnit === UNIT_TYPE_IMPERIAL) {
                let literNumObj = toInter(literNum);
                let rateLiterObj = toInter(RATE_L_GAL_IMPERIAL);
                return (literNumObj.num * rateLiterObj.num) / (literNumObj.times * rateLiterObj.times);
            }
            if (toUnit === UNIT_TYPE_US_CUSTOMARY) {
                let literNumObj = toInter(literNum);
                let rateLiterObj = toInter(RATE_L_GAL_US);
                return (literNumObj.num * rateLiterObj.num) / (literNumObj.times * rateLiterObj.times);
            }
            return literNum;
        } else if (fromUnit == UNIT_TYPE_IMPERIAL) {
            if (toUnit === UNIT_TYPE_METRIC) {
                let literNumObj = toInter(literNum);
                let rateLiterObj = toInter(RATE_GAL_L_IMPERIAL);
                return (literNumObj.num * rateLiterObj.num) / (literNumObj.times * rateLiterObj.times);
            }
            if (toUnit === UNIT_TYPE_US_CUSTOMARY) {
                let literNumObj = toInter(literNum);
                let rateLiterObj = toInter(RATE_GAL_IMPERIAL_US);
                return (literNumObj.num * rateLiterObj.num) / (literNumObj.times * rateLiterObj.times);
            }
        } else { //if---fromUnit == UNIT_TYPE_US_CUSTOMARY
            if (toUnit === UNIT_TYPE_METRIC) {
                let literNumObj = toInter(literNum);
                let rateLiterObj = toInter(RATE_GAL_L_US);
                return (literNumObj.num * rateLiterObj.num) / (literNumObj.times * rateLiterObj.times);
            }
            if (toUnit === UNIT_TYPE_IMPERIAL) {
                let literNumObj = toInter(literNum);
                let rateLiterObj = toInter(RATE_GAL_US_IMPERIAL);
                return (literNumObj.num * rateLiterObj.num) / (literNumObj.times * rateLiterObj.times);
            }
        }

    },
    convertKmLUnit(kmHrNum) {
        if (typeof kmHrNum !== 'number' || isNaN(kmHrNum)) return kmHrNum;
        if (store.getters.unit === UNIT_TYPE_IMPERIAL) {
            let kmHrNumObj = toInter(kmHrNum);
            let rateKmlMpg = toInter(RATE_KML_MPG_IMPERIAL);
            return kmHrNumObj.num * rateKmlMpg.num / (kmHrNumObj.times * rateKmlMpg.times);
        }
        if (store.getters.unit === UNIT_TYPE_US_CUSTOMARY) {
            let kmHrNumObj = toInter(kmHrNum);
            let rateKmlMpg = toInter(RATE_KML_MPG_US);
            return kmHrNumObj.num * rateKmlMpg.num / (kmHrNumObj.times * rateKmlMpg.times);
        }
        return kmHrNum;
    },
    changeLiterUnitToMetric(num, unitType) {
        if (isNaN(num) || isNaN(parseFloat(num))) return num;
        if (unitType === UNIT_TYPE_IMPERIAL) {
            let numObj = toInter(num);
            let rateObj = toInter(RATE_GAL_L_IMPERIAL);
            return (numObj.num * rateObj.num) / (numObj.times * rateObj.times);
        }
        if (unitType === UNIT_TYPE_US_CUSTOMARY) {
            let numObj = toInter(num);
            let rateObj = toInter(RATE_GAL_L_US);
            return (numObj.num * rateObj.num) / (numObj.times * rateObj.times);
        }
        return num;
    },
    convertDisplacementByUnit(literNum, fromUnit, toUnit) { //default, fromUnit=null, toUnit=null,代表from metric to current unit
        if (isNaN(literNum) || isNaN(parseFloat(literNum))) return literNum;
        if (fromUnit == undefined) fromUnit = UNIT_TYPE_METRIC;
        if (toUnit == undefined)
            toUnit = store.getters.unit;
        if (fromUnit === UNIT_TYPE_METRIC) {
            if (toUnit === UNIT_TYPE_US_CUSTOMARY || toUnit === UNIT_TYPE_IMPERIAL) {
                return literNum * RATE_L_CID;
            }
            else
                return literNum;
        }
        if (fromUnit === UNIT_TYPE_US_CUSTOMARY || fromUnit === UNIT_TYPE_IMPERIAL) {
            if (toUnit == UNIT_TYPE_METRIC)
                return literNum * RATE_CID_L;
            else
                return literNum;
        }
    },
    convertCmByUnit(cmNum, fromUnit, toUnit) { //default, fromUnit=null, toUnit=null,代表from metric to current unit
        if (isNaN(cmNum) || isNaN(parseFloat(cmNum))) return cmNum;
        if (fromUnit == undefined) fromUnit = UNIT_TYPE_METRIC;
        if (toUnit == undefined)
            toUnit = store.getters.unit;
        if (fromUnit === UNIT_TYPE_METRIC) {
            if (toUnit === UNIT_TYPE_US_CUSTOMARY || toUnit === UNIT_TYPE_IMPERIAL) {
                return cmNum * RATE_CM_INCH;
            }
            else
                return cmNum;
        }
        if (fromUnit === UNIT_TYPE_US_CUSTOMARY || fromUnit === UNIT_TYPE_IMPERIAL) {
            if (toUnit === UNIT_TYPE_METRIC)
                return cmNum * RATE_INCH_CM;
            else
                return cmNum;
        }
    },
    convertCmUnitToMetric(num, unitType) {
        if (isNaN(num) || isNaN(parseFloat(num))) return num;
        if (unitType === UNIT_TYPE_IMPERIAL || unitType === UNIT_TYPE_US_CUSTOMARY) {
            return num * RATE_INCH_CM;
        }
        return num;
    },
    convertDisplacementToMetric(literNum, fromUnit) {
        if (isNaN(literNum) || isNaN(parseFloat(literNum))) return literNum;
        if (fromUnit == null)
            fromUnit = store.getters.unit;
        if (fromUnit === UNIT_TYPE_METRIC) {
            return literNum;
        }
        if (fromUnit === UNIT_TYPE_US_CUSTOMARY || fromUnit === UNIT_TYPE_IMPERIAL) {
            return literNum * RATE_CID_L;
        }
    },
    getTNum(TNum) {
        //Metric: 1 T = 1000 Kilograms
        // Imperial: 1 T = 1016.05 Kilograms
        // US Customary: 1 T = 907.2 Kilograms
        if (typeof TNum !== 'number' || isNaN(TNum)) return TNum;
        if (store.getters.unit === UNIT_TYPE_METRIC) {
            return TNum;
        }
        if (store.getters.unit === UNIT_TYPE_IMPERIAL) {
            return RATE_T_Kilograms_Metric * TNum / RATE_T_Kilograms_IMPERIAL;
        }
        if (store.getters.unit === UNIT_TYPE_US_CUSTOMARY) {
            return RATE_T_Kilograms_Metric * TNum / RATE_T_Kilograms_US;
        }
    }
}
